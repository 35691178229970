import { SetStateAction, useEffect, useState } from "react";
import TransactionDetails from "../../types/TransactionDetails";
import Table from "./Table";
import { faAngleUp, faFilter } from "@fortawesome/free-solid-svg-icons";
import TransactionsTable from "./TransactionsTable";
import ClientService from "../../services/ClientService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { loadClientTransactions } from "../../slices/clientSlice";
import DatePicker from "react-datepicker";
import Switch from "react-switch";
import InputRange, { Range } from "react-input-range";
import "react-input-range/lib/css/index.css";
import Select from "react-dropdown-select";
import TransactionData from "../../types/TransactionData";
import { StyledLabel } from "../../styled_components/StyledInput";
import TransactionModal from "./TransactionModal";
import RuleList from "../RuleList";
import TagService from "../../services/TagService";
import Rule from "../../types/tags/Rule";
import Property from "../../types/tags/Property";
import ProductCategory from "../../types/tags/ProductCategory";
import styled from "styled-components";
import MasterContainerHeader from "../../atoms/MasterContainerHeader";
import Button from "../../atoms/Button";
import DropdownModal from "../../atoms/DropdownModal";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Multiselect from "multiselect-react-dropdown";

const getCategories = (transactions: TransactionData[]) => {
  var categories: string[] = [];
  transactions.forEach((t) => {
    if (t.categories) {
      categories = [...categories, ...t.categories];
    }
  });
  var uniqueCategories = Array.from(new Set(categories));
  return uniqueCategories.map((c: string, index: number) => ({
    id: index,
    name: c,
  }));
};

const Transactions = ({ className }: { className?: string }) => {
  const columns = [
    { name: "organization_name", sortable: true, addCurrency: false },
    { name: "category", sortable: true, addCurrency: false },
    { name: "date", sortable: true, addCurrency: false },
    { name: "amount", sortable: true, addCurrency: true },
  ];

  const [transactionDetails, setTransactionDetails] = useState(
    {} as TransactionDetails
  );
  const [selectedTransaction, setSelectedTransaction] = useState(-1);
  const [display] = useState("by-month");
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const [groupByMonths, setGroupByMonths] = useState(true);
  const [groupByMonthsState, setGroupByMonthsState] = useState(true);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 50 } as {
    min: number;
    max: number;
  });
  const [rangeMin] = useState(0);
  const [rangeMax, setRangeMax] = useState(50);
  const [selectedCategories, setSelectedCategories] = useState(
    [] as { id: number; name: string }[]
  );
  const [toggleRules, setToggleRules] = useState(false);

  const pureData = useSelector(
    (state: RootState) => state.clients.selectedClientTransactions
  );
  const loading = useSelector(
    (state: RootState) => state.clients.transactionsLoading
  );
  const categories = useSelector((state: RootState) =>
    getCategories(state.clients.selectedClientTransactions)
  );
  const [data, setData] = useState(pureData);
  const selectedClient = useSelector(
    (state: RootState) => state.clients.selectedClient
  );
  const dispatch = useDispatch();

  const [userRules, setUserRules] = useState([] as Rule[]);
  const [userRulesLoading, setUserRulesLoading] = useState(false);
  const [properties, setProperties] = useState([] as Property[]);
  const [categoryList, setCategoryList] = useState([] as string[]);
  const [productCategories, setProductCategories] = useState(
    [] as ProductCategory[]
  );
  const {t} = useTranslation()

  const loadRules = () => {
    setUserRulesLoading(true);
    TagService.getUserRules(selectedClient.id)
      .then((rules) => {
        setUserRules(rules.userRules);
        setProperties(rules.properties);
        setCategoryList(rules.categories);
        setProductCategories(rules.productCategories);
        setUserRulesLoading(false);
      })
      .catch((e) => {
        setUserRulesLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    loadRules();
  }, []);

  useEffect(() => {
    if (pureData.length > 0) {
      var temp = [...pureData];
      var highest = temp.sort((a, b) =>
        Math.abs(a.amount) > Math.abs(b.amount) ? -1 : 1
      )[0].amount;
      setRangeMax(Math.abs(highest));
      setPriceRange({ min: 0, max: Math.abs(highest) });
    }
    setData(pureData);
  }, [pureData]);

  useEffect(() => {
    if (selectedClient.id > 0) {
      dispatch(loadClientTransactions(selectedClient.id));
    }
    return () => {
      setSelectedTransaction(-1);
    };
  }, [selectedClient, dispatch]);

  const applyFilters = () => {
    var filteredData = pureData.filter((datum) => {
      var date = new Date(datum.date.split(".").reverse().join("-"));
      var s = new Date(startDate);
      s.setDate(1);
      var e = new Date(endDate);
      e.setDate(0);
      e.setMonth(e.getMonth() + 1);

      if (date >= s && date <= e) {
        return true;
      } else {
        return false;
      }
    });
    filteredData = filteredData.filter(
      (datum) =>
        Math.abs(datum.amount) >= priceRange.min &&
        Math.abs(datum.amount) <= priceRange.max
    );
    if (selectedCategories.length > 0) {
      filteredData = filteredData.filter((datum) => {
        var containsCat = false;
        selectedCategories.forEach((sc) => {
          if (datum.categories.includes(sc.name)) {
            containsCat = true;
          }
        });
        return containsCat;
      });
    }
    setData(filteredData);
    setGroupByMonthsState(groupByMonths);
  };

  const handleCSVExport = () => {
    ClientService.getCSVTransactions(selectedClient.id).catch((e) =>
      console.log(e)
    );
  };

  const handleTransactionDetails = (id: number) => {
    setTransactionDetails({} as TransactionDetails);
    setSelectedTransaction(id);
    ClientService.getTransactionDetails(id)
      .then((res) => {
        setTransactionDetails(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className={
        "shadow-md rounded-xl bg-white w-full max-w-full min-h-screen md:min-h-full h-98 " +
        className
      }
    >
      <MasterContainerHeader
        header={t("transactions")}
        menuIcons={
          <>
            <Button
              menuIcon
              shadow={false}
              bgColor="bg-primary"
              textColor="text-white"
              hoverColor="bg-primary-dark"
              className="mr-2"
              value={toggleRules ? "Transactions" : "Rules"}
              onClick={() => setToggleRules(!toggleRules)}
            />
            {!toggleRules && (
              <>
                <Button
                  menuIcon
                  shadow={false}
                  bgColor="bg-primary"
                textColor="text-white"
                hoverColor="bg-primary-dark"
                  className="mr-2"
                  value={t("Export")}
                  onClick={() => handleCSVExport()}
                />

                <DropdownModal
                  icon={faFilter}
                  openIcon={faAngleUp}
                  title={t("filter")}
                >
                  <div className="w-80">
                    <div
                      className="row"
                      style={{
                        justifyContent: "space-between",
                        marginBottom: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <div className="w-full mb-2 mt-2">
                        <StyledLabel>{t("date_from")}</StyledLabel>
                        <DatePicker
                          selected={startDate}
                          className="w-full py-2 pl-2 border rounded-xl"
                          wrapperClassName="w-full"
                          onChange={(date) => setStartDate(date as Date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="yyyy, MM"
                          showMonthYearPicker
                        />
                      </div>
                      <div className="w-full mb-2">
                        <StyledLabel>{t("date_til")}</StyledLabel>
                        <DatePicker
                          selected={endDate}
                          className="w-full py-2 pl-2 border rounded-xl"
                          wrapperClassName="w-full"
                          onChange={(date) => setEndDate(date as Date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="yyyy, MM"
                          showMonthYearPicker
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "5px, 10px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full mb-6">
                      <StyledLabel style={{ marginBottom: "1rem" }}>
                        {t("price_range")}
                      </StyledLabel>
                      <div className="px-4">
                        <InputRange
                          formatLabel={(val) => val + " €"}
                          minValue={rangeMin}
                          maxValue={rangeMax}
                          value={priceRange}
                          onChange={(value: Range | number) =>
                            setPriceRange(value as Range)
                          }
                        />
                      </div>
                    </div>
                    <div className="w-full mb-6">
                      <div
                        className="flex flex-col w-full"
                      >
                        <StyledLabel>{t("product_categories")}</StyledLabel>
                        <Multiselect
                          className="border rounded-xl"
                          options={categories}
                          selectedValues={selectedCategories}
                          onSelect={(selectedList, selectedItem) => {
                            setSelectedCategories([...selectedCategories, selectedItem])
                          }}
                          onRemove={(selectedList, removedItem) => {
                            setSelectedCategories([...selectedCategories].filter(s => removedItem.id !== s.id))
                          }}
                          displayValue={"name"}
                        />
                      </div>
                    </div>
                    <label className="flex justify-between mb-4">
                      <StyledLabel>{t("group_by_month")}</StyledLabel>
                      <Switch
                        checked={groupByMonths}
                        onChange={() => setGroupByMonths(!groupByMonths)}
                        onColor="#BCE4FA"
                        onHandleColor="#00497B"
                        handleDiameter={25}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={40}
                      />
                    </label>
                    <div className="mb-4 w-full">
                      <Button
                        value={t("apply")}
                        bgColor="btn bg-primary"
                        hoverColor=" bg-primary-dark "
                        className="w-full"
                        onClick={() => applyFilters()}
                      />
                    </div>
                  </div>
                </DropdownModal>
              </>
            )}
          </>
        }
      />

      {display === "by-month" &&
        !toggleRules &&
        (!loading ? (
          <TransactionsTable
            groupByMonths={groupByMonthsState}
            transactions={data}
            setSelectedTransaction={handleTransactionDetails}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="Oval" color="#0F497B" height={50} width={50} />
          </div>
        ))}

      {display === "all" &&
        !toggleRules &&
        (!loading ? (
          <Table
            columns={columns}
            data={data}
            onRowSelect={handleTransactionDetails}
            selectedRow={selectedTransaction}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="Oval" color="#0F497B" height={50} width={50} />
          </div>
        ))}

      {toggleRules &&
        (!userRulesLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <RuleWrapper>
              <RuleList
                userId={selectedClient.id}
                userSpecific={true}
                ruleList={userRules}
                setRuleList={(v) => setUserRules(v)}
                properties={properties}
                categories={categoryList}
                productCategories={productCategories}
                onReload={() => loadRules()}
              />
            </RuleWrapper>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="Oval" color="#0F497B" height={50} width={50} />
          </div>
        ))}
      { (selectedTransaction >= 0 && transactionDetails.fsReceipt !== undefined) &&
        <TransactionModal
          isOpen={
            selectedTransaction >= 0 && transactionDetails.fsReceipt !== undefined
          }
          onClose={() => setSelectedTransaction(-1)}
          setSelectedTransaction={setSelectedTransaction}
          transactionId={selectedTransaction}
        />
      }
    </div>
  );
};

const RuleWrapper = styled.div`
  width: 70%;

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

export default Transactions;
