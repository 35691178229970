import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../store'
import DateTimeUtils from '../utils/DateTimeUtils'

export enum StatsType {
    weekly = 'WEEKLY',
    monthly = 'MONTHLY',
    yearly = 'YEARLY',
    customRange = 'CUSTOM_RANGE'
}

export enum CatStatsDataType {
    category = 'CATEGORY',
    organization = 'ORGANIZATION',
    unit = 'UNIT',
    productCat = 'PRODUCT_CATEGORY',
    tag = 'TAG'
}

export enum TempStatsDataType {
    balance = 'BALANCE',
    expense = 'EXPENSE',
    income = 'INCOME'
}

const initialState = {
    type: StatsType.monthly,
    monthStart: new Date(Date.now()).toISOString(),
    intervalStart: new Date(Date.now()).toISOString(),
    intervalEnd: new Date(Date.now()).toISOString(),
}

const catGraphSlice = createSlice({
    name: "statistics",
    initialState,
    reducers: {
        changeType (state, action: PayloadAction<StatsType>) {
            var intervalStart = state.intervalStart
            var intervalEnd = state.intervalEnd
            if (action.payload === StatsType.monthly) {
                intervalStart = state.monthStart
                intervalEnd = DateTimeUtils.getMonthEnd(new Date(state.monthStart)).toISOString()
            } else if (action.payload === StatsType.weekly) {
                var tempDate = new Date(state.intervalStart)
                tempDate.setDate(tempDate.getDate() - tempDate.getDay() + 1)
                intervalStart = tempDate.toISOString()
                intervalEnd = DateTimeUtils.getWeekEnd(new Date(tempDate)).toISOString()
            }
            return {...state, type: action.payload, intervalStart: intervalStart, intervalEnd: intervalEnd}
        },
        changeInterval (state, action: PayloadAction<{from: string, to: string}>) {
            return {...state, intervalStart: action.payload.from, intervalEnd: action.payload.to}
        },
        changeMonthInterval (state, action: PayloadAction<{monthFrom: string, intervalFrom: string, til: string}>) {
            return {...state, monthStart: action.payload.monthFrom, intervalStart: action.payload.intervalFrom, intervalEnd: action.payload.til}
        },
    }
})

export const changeType = (type: StatsType): AppThunk => (dispatch: AppDispatch) => {
    dispatch(catGraphSlice.actions.changeType(type))
}

export const changeInterval = (from: string, til: string): AppThunk => (dispatch: AppDispatch) => {
    dispatch(catGraphSlice.actions.changeInterval({from: from, to: til}))
}

export const changeMonthInterval = (from: string, til: string, setForWeeklies?: boolean): AppThunk => (dispatch: AppDispatch) => {
    var tempStart = from
    var tempEnd = til
    if (setForWeeklies) {
        var tempDate = new Date(tempStart)
        tempDate.setDate(tempDate.getDate() - tempDate.getDay() + 1)
        tempStart = tempDate.toISOString()
        tempEnd = DateTimeUtils.getWeekEnd(new Date(tempStart)).toISOString()
    }
    dispatch(catGraphSlice.actions.changeMonthInterval({monthFrom: from, intervalFrom: tempStart, til: tempEnd}))
}

export default catGraphSlice.reducer