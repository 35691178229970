import getAxios from './api'
import User from '../types/User'
import Settings from '../types/tags/Settings'
import { TCustomStatistics } from '../types/TCustomStatistics'

const AdvisorService = {
    getProfile: async () => {
        try {
            var res = await getAxios().get('advisor/profile')
            return res.data
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    getSettings: async () => {
        try {
            var res = await getAxios().get('advisor/settings')
            var settings = res.data as Settings
            settings.userRules = settings.userRules.map(r => ({
                ...r,
                conditions: r.conditions.map(c => ({
                    ...c,
                    timeIntervalFrom: c.timeIntervalFrom ? new Date(c.timeIntervalFrom) : undefined,
                    timeIntervalTo: c.timeIntervalTo ? new Date(c.timeIntervalTo) : undefined
                }))
            }))
            settings.generalRules = settings.generalRules.map(r => ({
                ...r,
                conditions: r.conditions.map(c => ({
                    ...c,
                    timeIntervalFrom: c.timeIntervalFrom ? new Date(c.timeIntervalFrom) : undefined,
                    timeIntervalTo: c.timeIntervalTo ? new Date(c.timeIntervalTo) : undefined
                }))
            }))
            return settings
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    getStatistics: async () => {
        try {
            var res = await getAxios().get('advisor/statistics')
            return res.data 
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    getCustomClientStatistics: async (client: number, startDate: Date, endDate: Date) => {
        try {
            var res = await getAxios().get('/advisor/clients/' + client + '/statistics/custom?startDate=' + startDate.toISOString().split("T")[0] + 
            '&endDate=' + endDate.toISOString().split("T")[0])
            return res.data as TCustomStatistics
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    setProfile: async (userData: User) => {
        await getAxios().put('advisor/profile', userData)
    },
    login: async (email: string, password: string) => {
       try {
            var res = await getAxios().post("advisor/login",{email: email, password: password})
            let data = res.data.web_user
            await localStorage.setItem('token', res.data.id_token)
            return {
                id: data.id,
                fullName: data.fullName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                profilePicture: data.profilePicture
            } as User
       } catch (e: any) {
           if (e.response) {
            if (e.response.status === 404) {
                throw Error("Communication error") 
            } else if (e.response.status === 401) {
                throw Error("Wrong email/password combination")
            } else if (e.response.status === 500) {
                throw Error("Server error")
            } else {
                throw Error("There was an error")
            }
        } else {
            throw Error("Connection error")
        }
       }
    },
    logout: async () => {
        await getAxios().get('advisor/logout')
        localStorage.removeItem('token')
        window.location.reload()
    }
}

export default AdvisorService