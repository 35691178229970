import React from "react";
import Navbar from "./atoms/Navbar";
import Clients from "./views/Clients";
import Dashboard from "./views/Dashboard";
import Login from "./views/Login";
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import Logout from "./views/Logout";
import Profile from "./views/Profile";
import Settings from "./views/Settings";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "./slices/advisorSlice";
import { RootState } from "./reducers/rootReducer";
import Documents from "./components/documents/Documents";
import Transactions from "./components/transactions/Transactions";
import Statistics from "./components/statistics/Statistics";
import Notes from "./components/notes/Notes";
import NotFound from "./views/NotFound";
import Overview from "./components/overview/Overview";

function App() {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.advisor.isLoading);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getProfile());
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      {(!loading || !localStorage.getItem("token")) && (
        <div className="md:max-h-screen h-screen w-screen flex flex-col overflow-y-scroll md:overflow-y-hidden  bg-background">
          <Routes>
            <Route path={`/login`} element={<Login />} />
            <Route element={<SecuredAppLayout />}>
              <Route path={`/clients`} element={<Clients />}>
              <Route
                  index
                  element={
                    <Overview className="sm:flex-grow full:flex-grow-0" />
                  }
                />
                <Route
                  path={`notes`}
                  element={
                    <Notes className="sm:flex-grow full:flex-grow-0" />
                  }
                />
                <Route
                  path={`documents`}
                  element={
                    <Documents className="sm:flex-grow full:flex-grow-0" />
                  }
                />
                <Route
                  path={`transactions`}
                  element={
                    <Transactions className="sm:flex-grow full:flex-grow-0" />
                  }
                />
                <Route
                  path={`statistics`}
                  element={
                    <Statistics className="sm:flex-grow full:flex-grow-0" />
                  }
                />
              </Route>
              <Route path={`/logout`} element={<Logout />} />
              <Route path={`/profile`} element={<Profile />} />
              <Route path={`/settings`} element={<Settings />} />
              <Route path={`/`} element={<Dashboard />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      ) }
    </BrowserRouter>
  );
}

const SecuredAppLayout = () => {
  const isAuth = useSelector((state: RootState) => state.advisor.isAuth);
  const loading = useSelector((state: RootState) => state.advisor.isLoading);

  return (
    <div className="bg-background w-full h-full">
      <div className="flex flex-col w-full h-screen">
        {(!loading || !localStorage.getItem("token")) && (
          <>
            {isAuth ? (
              <>
                <Navbar />
                <div className="w-full max-w-screen-full full:min-w-screen-full full:mx-auto h-full px-4 pt-8">
                  <Outlet />
                </div>
              </>
            ) : (
              <Navigate to="/login" />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default App;
