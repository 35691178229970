import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export enum ClientListSort {
    SortAlphaDown = "SORT_ALPHA_DOWN",
    SortAlphaUp = "SORT_ALPHA_UP",
    SortByAdvisor = "SORT_BY_TYPE",
    SortBySearchValue = "SORT_BY_SEARCH_VALUE"
}

const initialState = ClientListSort.SortByAdvisor

const clientSortSlice = createSlice({
    name: 'clientSort',
    initialState,
    reducers: {
        setClientSort(state, action: PayloadAction<ClientListSort>) {
            return action.payload
        }
    }
})

export const { setClientSort } = clientSortSlice.actions

export default clientSortSlice.reducer