import { motion } from "framer-motion";
import React from "react";

type CardProps = {
  className?: string;
  header?: React.ReactNode;
  isModalCard?: boolean
};

const Card = ({ children, className, header, isModalCard = false }: React.PropsWithChildren<CardProps>) => {
  return (
    <motion.div
    initial="hidden"
    animate="visible"
    variants={isModalCard ? {
      visible: { transition: { duration: 0.3, delayChildren: 0.4 }, scale: 1 },
      hidden: {  scale: 0.9  },
    }:{}}
    //transition={{ ease: "easeOut", duration: 2 }}
    
      onClick={(e) => e.stopPropagation()}
      className={
        "bg-white rounded-lg sm:pb-6 shadow-lg w-full h-full px-3 1.5lg:px-6 pt-4 " +
        className
      }
    >
      <div className="1.5lg:text-xl xl:text-2xl text-lg pb-4 flex justify-between w-full items-baseline">{header}</div>
      <div className="text-sm h-full">
      {children}
      </div>
    </motion.div>
  );
};
export default Card;
