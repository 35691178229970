import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadClientStatistics,
  loadCustomClientStatistics,
} from "../../slices/statisticsSlice";
import { RootState } from "../../reducers/rootReducer";
import StatsTransformer from "../../utils/StatsTransformer";
import GraphContainer from "./GraphContainer";
import Dropdown from "../utilities/Dropdown";
import {
  StatsType,
  changeType,
  changeInterval,
  changeMonthInterval,
} from "../../slices/graphSlice";
import DatePicker from "react-datepicker";
import MasterContainerHeader from "../../atoms/MasterContainerHeader";
import DateTimeUtils from "../../utils/DateTimeUtils";
import AdvisorService from "../../services/AdvisorService";
import { TCustomStatistics } from "../../types/TCustomStatistics";
import { useTranslation } from "react-i18next";

const useStatistics = () => {
  const dispatch = useDispatch();
  const { intervalStart, intervalEnd } = useSelector((state: RootState) => state.statistics.statsConfig);
  const client = useSelector((state: RootState) => state.clients.selectedClient.id);
  const [statistics, setStatistics] = useState<TCustomStatistics | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (intervalStart < intervalEnd && client > 0) {
      AdvisorService.getCustomClientStatistics(client, new Date(intervalStart), new Date(intervalEnd))
        .then((res) => {
          setStatistics(res);
          console.log(res);
          setLoading(false);
        })
        .catch(() => console.log("Error"))
        .finally(() => setLoading(false));
    }
  }, [intervalStart, intervalEnd, client, dispatch]);

  const loadStatistics = () => {

  }

  return {statistics: statistics, loading: loading};

}

const Statistics = ({className}: {className? : string}) => {
  const dispatch = useDispatch();
  const selectedClient = useSelector(
    (state: RootState) => state.clients.selectedClient
  );
  const statistics = useStatistics();
  const [expandedGraph, setExpandedGraph] = useState(-1);
  const config = useSelector(
    (state: RootState) => state.statistics.statsConfig
  );
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const {t} = useTranslation()

  const [isInit, setIsInit] = useState(false)

  const [monthlyIntervals, setMonthlyIntervals] = useState(
    [] as {
      index: number;
      selected: boolean;
      value: Date;
      title: string;
    }[]
  );

  const [weeklyIntervals, setWeeklyIntervals] = useState(
    [] as {
      index: number;
      selected: boolean;
      value: Date;
      title: string;
    }[]
  );

  useEffect(() => {
    //dispatch(loadClientStatistics(selectedClient.id));
  }, [dispatch, selectedClient.id]);

  useEffect(() => {
    initStatistics()
  }, [config]);

  useEffect(() => {
    initStatistics(true)
    setIsInit(true)
  }, []);

  useEffect(() => {
    initStatistics(true)
    setIsInit(true)
  }, [selectedClient.id]);

  const initStatistics = (initLast?: boolean) => {
    if (selectedClient?.id) {
      if (config.type == StatsType.weekly) {
        setMonthlyIntervals(
          DateTimeUtils.getMonthList(
            new Date(selectedClient.firstTransactionDate), 
            new Date(Date.now()), 
            DateTimeUtils.getMonthStart(new Date(config.monthStart)).toISOString()
          )
        )
        
        setWeeklyIntervals(
          DateTimeUtils.getWeekList(
            DateTimeUtils.getMonthStart(new Date(config.monthStart)), 
            DateTimeUtils.getMonthEnd(new Date(config.monthStart)),
            config.intervalStart
          )
        )
      } else {
        setMonthlyIntervals(DateTimeUtils.getMonthList(new Date(selectedClient.created_at), new Date(Date.now()), config.intervalStart))
      }
    }
  }

  useEffect(() => {
    if (monthlyIntervals.length > 0 && isInit) {
      console.log("test")
      dispatch(changeMonthInterval(
        monthlyIntervals[monthlyIntervals.length - 1].value.toISOString(),
        DateTimeUtils.getMonthEnd(monthlyIntervals[monthlyIntervals.length - 1].value).toISOString(),
        ));
      setIsInit(false)
    }
  }, [monthlyIntervals])

  const setTimeRangeType = (value: string) => {
    dispatch(changeType(value as StatsType))
  }

  const setMonth = (monthStart: Date) => {
    if (config.type == StatsType.weekly) {
      dispatch(changeMonthInterval(monthStart.toISOString(), DateTimeUtils.getMonthEnd(monthStart).toISOString(), true))
    } else {
      dispatch(changeMonthInterval(monthStart.toISOString(), DateTimeUtils.getMonthEnd(monthStart).toISOString()))
    }
  }

  const setWeek = (weekStart: Date) => {
    dispatch(changeInterval(weekStart.toISOString(), DateTimeUtils.getWeekEnd(weekStart).toISOString()))
    
  }
  

  return (
    <div className={"shadow-md rounded-xl bg-white w-full min-h-screen md:min-h-0 md:h-full " + className}>
      <MasterContainerHeader
        header={t("statistics")}
        menuIcons={
          <>
            {monthlyIntervals.length > 0 &&
              config.type !== StatsType.customRange && (
                <Dropdown
                  color="bg-white"
                  className="whitespace-nowrap"
                  onChange={(v) => setMonth(v)}
                  items={monthlyIntervals}
                />
              )}
            {config.type === "WEEKLY" && weeklyIntervals && (
              <Dropdown
                color="bg-white"
                className="ml-2 whitespace-nowrap"
                onChange={(v) => setWeek(v)}
                items={weeklyIntervals}
              />
            )}
            {config.type === "CUSTOM_RANGE" && (
              <div className="flex"
              >
                <div>
                  <DatePicker
                    selected={startDate}
                    className="py-1 rounded-lg pl-2"
                    wrapperClassName="mr-1"
                    onChange={(date) => {}}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd.MM.yyyy"
                  />
                </div>
                <div>
                <DatePicker
                  selected={endDate}
                  className="py-1 rounded-lg pl-2"
                  wrapperClassName="mr-1"
                  onChange={(date) => {}}
                  selectsEnd
                  startDate={endDate}
                  endDate={endDate}
                  dateFormat="dd.MM.yyyy"
                />
                </div>
              </div>
            )}
            <Dropdown
              color="bg-white"
              className="ml-2 whitespace-nowrap"
              onChange={(v) => setTimeRangeType(v)}
              items={[
                {
                  title: t("by_week"),
                  value: "WEEKLY",
                  selected: config.type === "WEEKLY",
                },
                {
                  title: t("by_month"),
                  value: "MONTHLY",
                  selected: config.type === "MONTHLY",
                },
                {
                  title: t("custom"),
                  value: "CUSTOM_RANGE",
                  selected: config.type === "CUSTOM_RANGE",
                },
              ]}
            />
          </>
        }
      />
      <div className="w-full h-full max-h-full">
        {!statistics.loading && statistics.statistics && (
          <div className="grid grid-rows-3 grid-cols-1 md:grid-cols-2 md:grid-rows-2 grid-flow-row w-full auto-cols-min auto-rows-fr gap-2 px-5 pb-20 h-full max-h-full">
            <GraphContainer
              id={0}
              key={0}
              data={statistics.statistics}
              className="col-span-1 md:col-span-2 row-span-1 h-full"
              graphType={"line"}
              loading={statistics.loading}
              expandedGraph={expandedGraph}
              setExpandedGraph={setExpandedGraph}
              transform={StatsTransformer.prototype.customLineTransform}
            />
            <GraphContainer
              id={1}
              key={1}
              data={statistics.statistics}
              className="col-span-1 row-span-1 "
              graphType={"pie"}
              loading={statistics.loading}
              expandedGraph={expandedGraph}
              setExpandedGraph={setExpandedGraph}
              transform={StatsTransformer.prototype.customPieTransform}
            />
            <GraphContainer
              id={2}
              key={2}
              data={statistics.statistics}
              className="col-span-1 row-span-1 "
              graphType={"pie"}
              loading={statistics.loading}
              expandedGraph={expandedGraph}
              setExpandedGraph={setExpandedGraph}
              transform={StatsTransformer.prototype.customPieTransform}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Statistics;
