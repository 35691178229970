import React from "react";

type MasterContainerHeaderProps = {
  header?: string;
  subHeaderContent?: React.ReactNode;
  menuIcons?: React.ReactNode;
  className?: string;
};

const MasterContainerHeader = ({
  header,
  subHeaderContent,
  menuIcons,
  className,
  children
}: React.PropsWithChildren<MasterContainerHeaderProps>) => {
  return (
    <div className={"rounded-t-xl px-4 md:h-16 " + className}>
      <div className="w-full pt-1 h-full">
        { children ? (
          <div className="pt-4">
            {children}
          </div>
        ) : <>
        <div className="flex flex-col md:flex-row justify-between items-center h-full">
          <div className="w-full md:w-min md:h-full mt-1 flex flex-col justify-center">
            <h1 className="text-xl font-semibold text-primary-dark pb-1">{header}</h1>
            <div className="flex items-center text-primary-dark text-sm">
                {subHeaderContent}
            </div>
          </div>
          <div className="flex w-full flex-col justify-end md:w-min md:h-full items-center mb-2 md:mb-7">
            <div className="flex text-sm text-primary-dark">{menuIcons}</div>
          </div>
        </div>
        </>
        }
      </div>
    </div>
  );
};

export default MasterContainerHeader;
