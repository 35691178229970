import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadClientDetails } from '../../slices/clientSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../reducers/rootReducer';

type ClientMenuItemProps = {
    item: string;
    selected: boolean;
    index: number,
    advisor: number | null,
    setClient: () => void
};

const ClientMenuItem = ({item, selected, index, advisor, setClient} : ClientMenuItemProps) => {

    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.advisor.currentUser!!)

    return (
        <div className={`flex justify-between cursor-pointer items-center font-medium mx-4 px-2 py-3 my-1 rounded-md ${selected ? `bg-secondary text-primary-focus` : `bg-white hover:bg-gray-100`}`} onClick={() => {dispatch(loadClientDetails(index)); setClient()}}>
            <div>{item}</div>
            { user.id === advisor && <FontAwesomeIcon icon={faUserTie} style={{color: "#a5a5a5"}}/> }
        </div>
    )
}

export default ClientMenuItem