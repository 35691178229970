import getAxios from "./api"
import RuleList from "../types/tags/RuleList"
import Tag from "../types/tags/Tag"
import Rule from "../types/tags/Rule"

const TagService = {
    createUserRule: async (userId: number, newRule: Rule) => {
        try {
            console.log(newRule)
            var res = await getAxios().post('tag/rule/' + userId, newRule)
            return res.data as Rule
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    }, 
    createRule: async (newRule: Rule) => {
        try {
            var res = await getAxios().post('tag/rule', newRule)
            return res.data as Rule
        } catch (e: any) {
            console.log(e)
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    deleteRule: async (ruleId: number) => {
        try {
            await getAxios().delete('tag/rule/' + ruleId)
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    modifyRule: async (modifiedRule: Rule) => {

        console.log(modifiedRule)
        try {
            var res = await getAxios().put('tag/rule', modifiedRule)
            return res.data as Rule
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    getUserRules: async (userId: number) => {
        try {
            var res = await getAxios().get('tag/rule/' + userId)
            var rules = res.data as RuleList
            rules.userRules = rules.userRules.map(r => ({
                ...r,
                conditions: r.conditions.map(c => ({
                    ...c,
                    timeIntervalFrom: c.timeIntervalFrom ? new Date(c.timeIntervalFrom) : undefined,
                    timeIntervalTo: c.timeIntervalTo ? new Date(c.timeIntervalTo) : undefined
                }))
            }))
            return rules
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    createTag: async (tagName: string, productId: number, userId: number) => {
        try {
            await getAxios().post('tag/' + productId, {
                name: tagName,
                userId: userId
            } as Tag)
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    deleteTag: async (tagId: number) => {
        try {
            await getAxios().delete('tag/' + tagId)
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    deleteCondition: async (conditionId: number) => {
        
        try {
            await getAxios().delete('tag/rule/condition/' + conditionId)
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    },
    removeProductTag: async (tagId: number, productId: number) => {
        try {
            await getAxios().delete('tag/' + tagId + '/product/' + productId)
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 404) {
                    throw Error("Communication error")
                } else if (e.response.status === 401) {
                    throw Error("User not logged in")
                } else if (e.response.status === 500) {
                    throw Error("Server error")
                } else {
                    throw Error("There was an error")
                }
            } else {
                throw Error("Connection error")
            }
        }
    }
}

export default TagService