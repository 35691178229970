import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createPopper } from "@popperjs/core";
import React, { useEffect, useState, useRef } from "react";
import cx from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type DropdownProps = {
  items: {
    title: string;
    selected: boolean;
    value: any;
  }[];
  className?: string;
  color?: string;
  textColor?: string;
  icon?: IconProp;
  onChange: (value: any) => void;
};

const Dropdown = ({ items, color, textColor = "text-black", icon, className, onChange }: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const buttonNode = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      var current = node.current as any;
      if (current) {
        if (current.contains(e.target)) {
          return;
        } else {
          setOpen(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [node]);

  const openDropdownPopover = () => {
    createPopper(buttonNode.current!!, node.current!!, {
      placement: "bottom-start",
    });
    setOpen(true);
  };
  const closeDropdownPopover = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={cx("flex flex-wrap", className)}>
        <div className="relative">
          <div className="relative inline-flex align-middle w-full">
            <button
              className={"rounded-lg px-2 py-1 outline-none focus:outline-none " + textColor + " " + color }
              type="button"
              ref={buttonNode}
              onClick={() => {
                open ? closeDropdownPopover() : openDropdownPopover();
              }}
            >
              <div className="flex items-center">
                { !icon ? (
                  <>
                  {items.find((item) => item.selected)?.title}
                  {open ? (
                    <FontAwesomeIcon icon={faChevronUp} className="ml-3" />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} className="ml-3" />
                  )}</>) : <FontAwesomeIcon icon={icon} className="ml-3" style={{ border: "0px", cursor: "pointer", color: "#7E8589" }} />
                }
              </div>
            </button>
            <div
              ref={node}
              className={
                (open ? "block " : "hidden ") +
                "z-50 float-left absolute"
              }
              style={{ minWidth: "10rem" }}
            >
              <div className="mt-1 bg-white text-base list-none text-left rounded-lg shadow-lg">
                {items.map((i, index) => (
                  <a
                    key={i.title + index + i.value.toString()}
                    href="/"
                    className={
                      cx(
                        "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap text-blueGray-700 cursor-pointer hover:bg-gray-100",
                        index === 0 ? "pt-4 rounded-t-lg" : "",
                        index === items.length - 1 ? "rounded-b-lg pb-4" : "border-b ring-gray-100",
                      )
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                      onChange(i.value);
                    }}
                  >
                    {i.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdown;
