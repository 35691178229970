import cx from "classnames";
import {
  faTrashAlt,
  faPen,
  faCheck,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Input from "../atoms/Input";

export interface FileRowProps {
  name: string;
  className?: string;
  isAdditionTag?: boolean;
  isInShared?: boolean;
  expandedContent?: React.ReactNode;
  onRemove?: () => void;
  onSubmit?: (value: string) => void;
  onClick?: () => void
}

export const FileRow = ({
  className,
  name,
  isAdditionTag = false,
  isInShared = false,
  expandedContent,
  onRemove,
  onSubmit,
  onClick
}: FileRowProps) => {
  const [editing, setEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [editName, setEditName] = useState(name);

  return (
    <div className="rounded-lg flex flex-col h-auto " onClick={onClick}>
      <div
        className={cx(
          "flex items-center rounded-lg bg-gray-300 justify-between w-full p-4 cursor-pointer",
          className
        )}
        onClick={
          !editing && expandedContent
            ? () => setIsExpanded(!isExpanded)
            : () => {}
        }
      >
        <div>
          {editing ? (
            <Input
              className="rounded-lg py-1 px-3"
              hasError={editName === "" ? true : false}
              onChange={(e) => {
                setEditName(e.target.value);
              }}
              type="text"
              defaultValue={editName}
              required={true}
              onClick={(e) => e.stopPropagation()}
              placeholder="Tag name..."
            ></Input>
          ) : (
            <p className="mt-1">{name}</p>
          )}
        </div>
        {editing || isAdditionTag ? (
          <FontAwesomeIcon
            icon={faCheck}
            className="cursor-pointer"
            onClick={
              onSubmit
                ? (e) => {
                    onSubmit(editName);
                    setEditing(false);
                    e.stopPropagation();
                  }
                : (e) => {
                    setEditing(false);
                    e.stopPropagation();
                  }
            }
          />
        ) : isExpanded ? (
          <FontAwesomeIcon
            className="cursor-pointer"
            onClick={() => setIsExpanded(false)}
            icon={faChevronUp}
          />
        ) : (
          <div>
            { !isInShared && <>
            <FontAwesomeIcon
              className="cursor-pointer"
              onClick={(e) => {
                if (editName !== "") setEditing(!editing);
                e.stopPropagation();
              }}
              icon={faPen}
            />
            <FontAwesomeIcon
              className="cursor-pointer ml-2"
              onClick={
                onRemove
                  ? (e) => {
                      onRemove();
                      e.stopPropagation();
                    }
                  : (e) => e.stopPropagation()
              }
              icon={faTrashAlt}
            /></>
            }
            {expandedContent && (
              <FontAwesomeIcon
                className="cursor-pointer ml-2"
                onClick={(e) => {
                  setIsExpanded(true);
                  e.stopPropagation();
                }}
                icon={faChevronDown}
              />
            )}
          </div>
        )}
      </div>
      {isExpanded && <div className="px-5 pt-5 pb-4">{expandedContent}</div>}
    </div>
  );
};

export default FileRow;
