import React from 'react';
import DocumentData from '../../types/DocumentData';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus, faShareAlt } from '@fortawesome/free-solid-svg-icons';

type FilePropsType = {
  id: number,
  fileName?: string | JSX.Element,
  document?: DocumentData,
  isNewFile?: boolean,
  isList?: boolean,
  isFirst?: boolean,
  onClick?: () => void
}

const File = ({fileName, document, onClick, isNewFile, isList, isFirst = false} : FilePropsType) => {

  return (
    <div style={{width: "100%"}}>
    { !isList ?
      <StyledFile onClick={onClick} >  
      {
        isNewFile ? 
          <FontAwesomeIcon icon={faFolderPlus} size={"7x"} color="#d0d3d4"/>
        : <img src={document?.folder ? (document?.name === "shared" ? '/shared_folder.png' : '/folder.png') : '/document.png'} alt={document?.folder ? 'folder' : 'document'} /> 
      }
        <div className="file-name">{fileName}</div>
      </StyledFile>
      :
      <div onClick={onClick} className={`w-full flex justify-between px-4 py-3 border-b cursor-pointer hover:bg-gray-100 ${isFirst ? "border-t" : ""}`}>  
        <div style={{display: "flex", alignItems: "center"}}>
          {
            isNewFile ? 
              <FontAwesomeIcon icon={faFolderPlus} color="#d0d3d4" style={{marginRight: "1rem", fontSize: "1.5em"}}/>
            : <img src={document?.folder ? '/folder.png' : '/document.png'} alt={document?.folder ? 'folder' : 'document'} className="w-6 h-6 mr-3"/> 
          }
          <div className="file-name">{fileName}</div>
        </div>
        {/*<div className="file-info">{document?.createdAt?.toString().split('T')[0]}</div>*/}
        <div style={{display: "flex", alignItems: "center"}}>
          {document?.visibleClient && <FontAwesomeIcon icon={faShareAlt} size={"1x"} color="#d0d3d4" style={{marginRight: "1rem"}}/>}
          <div className="file-info">{document?.modifiedAt?.toString().split('T')[0]}</div>
        </div>
      </div>
    }
    </div>

  )
}

const StyledFile = styled.div`
  width: 7rem;
  height: 9rem;
  border: 1px solid #d0d3d4;
  margin: 0 .8rem .8rem 0;
  padding: .5rem;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    border: 2px solid #d0d3d4;
    margin: -1px calc(.8rem - 2px) calc(.8rem - 2px) 0;
  }

  img {
    width: 100%
  }

  .file-name {
    text-align: center;
  }

  .file-size {
    text-align: center;
    font-size: .7em;
    color: #8f8d8d;
    margin-top: .3rem
  }
`

export default File;