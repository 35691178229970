import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import NotesService from '../services/NotesService'
import { AppDispatch, AppThunk } from '../store'
import Note from '../types/Note'

const initialState = {
    isLoading: false,
    error: {message: "An error occured"},
    selectedClientNotes: [] as Note[]
}

const noteSlice = createSlice({
    name: "note",
    initialState,
    reducers: {
        receiveClientNotes(state, action: PayloadAction<Note[]>) {
            var newState = {...state, selectedClientNotes: action.payload}
            return newState
        }
    }
})

export const loadClientNotes = (clientId: number): AppThunk => async (dispatch: AppDispatch) => {
    var clientNotes = await NotesService.getNotes(clientId)
    dispatch(noteSlice.actions.receiveClientNotes(clientNotes))
}

export const addClientNote = (note: string, appUserVisibility: boolean, createdById: number, createdForId: number, intervalFrom: Date, intervalTo: Date, type: string): AppThunk => async (dispatch: AppDispatch) => {
    await NotesService.createNote(note, appUserVisibility, createdById, createdForId, intervalFrom, intervalTo, type)
    dispatch(loadClientNotes(createdForId))
}

export const editClientNote = (note: Note): AppThunk => async (dispatch: AppDispatch) => {
    await NotesService.updateNote(note)
    dispatch(loadClientNotes(note.createdForId))
}

export default noteSlice.reducer