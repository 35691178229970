import { useEffect, useState } from "react";
import ClientListItem from "./ClientListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import BasicClient from "../../types/BasicClient";
import { ClientListSort, setClientSort } from "../../slices/clientSortSlice";
import MasterContainerHeader from "../../atoms/MasterContainerHeader";
import { loadClientDetails } from "../../slices/clientSlice";
import { useTranslation } from "react-i18next";

type ClientListProps = {
  setClient: () => void;
  setClientsHidden: (hidden: boolean) => void;
  className?: string;
};

const sortAlphaUp = (clients: BasicClient[]) => {
  return [...clients].sort((a, b) => {
    if (a.fullName === null || a.fullName === "") {
      return 1;
    }
    if (b.fullName === null || b.fullName === "") {
      return -1;
    }
    if (a.fullName > b.fullName) {
      return -1;
    } else {
      return 1;
    }
  });
};

const sortAlphaDown = (clients: BasicClient[]) => {
  return [...clients].sort((a, b) => {
    if (a.fullName === null || a.fullName === "") {
      return 1;
    }
    if (b.fullName === null || b.fullName === "") {
      return -1;
    }

    if (a.fullName < b.fullName) {
      return -1;
    } else {
      return 1;
    }
  });
};

const sortClients = (clients: BasicClient[], userId: number) => {
  let c = [...clients].sort((a, b) => {
    if (a.advisorId === userId) {
      return -1;
    } else if (b.advisorId === userId) {
      return 1;
    } else if (a.advisorId && !b.advisorId) {
      return 1;
    } else if (b.advisorId && !a.advisorId) {
      return -1;
    } else if (
      (a.fullName === null || a.fullName === "") &&
      b.fullName !== null
    ) {
      return 1;
    } else if (
      a.fullName !== null &&
      (b.fullName === null || b.fullName === "")
    ) {
      return -1;
    }

    return 1;
  });
  return c;
};

const useVisibleClients = (searchValue?: string) => {
  const dispatch = useDispatch();
  const clients = useSelector((state: RootState) => state.clients.clientList);
  const sort = useSelector((state: RootState) => state.clientSort);
  const userId = useSelector(
    (state: RootState) => state.advisor.currentUser?.id
  )!;
  const client = useSelector(
    (state: RootState) => state.clients.selectedClient
  )!;
  const [sortedClients, setSortedClients] = useState<BasicClient[]>([]);

  const sortThem = () => {
    switch (sort) {
      case ClientListSort.SortByAdvisor:
        setSortedClients(sortClients(clients, userId));
        break;
      case ClientListSort.SortAlphaDown:
        setSortedClients(sortAlphaDown(clients));
        break;
      case ClientListSort.SortAlphaUp:
        setSortedClients(sortAlphaUp(clients));
        break;
      case ClientListSort.SortBySearchValue:
        setSortedClients(
          clients.filter((client) =>
            client.fullName
              ? client.fullName.toLowerCase().includes(searchValue!!)
              : ("Anonymous (ID: " + client.id + ")").includes(searchValue!!)
          )
        );
        break;
      default:
        setSortedClients(sortClients(clients, userId));
    }
  };

  useEffect(() => {
    sortThem();
  }, [clients]);

  useEffect(() => {
    if ((!client || client.id <= 0) && sortedClients.length > 0)
      dispatch(loadClientDetails(sortedClients[0].id));
  }, [sortedClients]);

  useEffect(() => {
    sortThem();
  }, [sort, searchValue]);

  return sortedClients;
};

const ClientList = ({
  setClientsHidden,
  setClient,
  className,
}: ClientListProps) => {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");
  const clients = useVisibleClients(searchValue);
  const sort = useSelector((state: RootState) => state.clientSort);
  const selectedClient = useSelector(
    (state: RootState) => state.clients.selectedClient
  );
  const {t} = useTranslation()

  useEffect(() => {
    if (searchValue.length > 0) {
      dispatch(setClientSort(ClientListSort.SortBySearchValue));
    }
  }, [searchValue, dispatch]);

  const selectClient = () => {
    setClient();
    setClientsHidden(true);
  };

  return (
    <div className={"shadow-md max-h-full rounded-xl bg-white md:h-98 mb-6 md:mb-0 " + className}>
      <div className="flex items-center h-20 w-full justify-between px-4">
        <div className="relative mr-2 w-4/5">
          <input
            type="text"
            value={searchValue}
            placeholder={t('search')}
            className="pl-2 rounded-lg w-full text-base py-1 border-2 h-9 border-gray-300 focus:border-gray-400 focus:outline-none"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute right-2 top-1/4 text-grey-primary"
          />
        </div>
        <div className="flex justify-end items-center">
          {sort === ClientListSort.SortAlphaDown ? (
            <FontAwesomeIcon
              className="mr-2 text-xl"
              onClick={() =>
                dispatch(setClientSort(ClientListSort.SortAlphaUp))
              }
              icon={faSortAlphaDown}
              style={{ color: "#00497b" }}
            />
          ) : (
            <FontAwesomeIcon
              className="mr-2 text-xl"
              onClick={() =>
                dispatch(setClientSort(ClientListSort.SortAlphaDown))
              }
              icon={faSortAlphaUp}
              style={{ color: "#00497b" }}
            />
          )}
          <FontAwesomeIcon
            className="text-xl"
            onClick={() =>
              dispatch(setClientSort(ClientListSort.SortByAdvisor))
            }
            icon={faUserTie}
            style={{ color: "#00497b" }}
          />
        </div>
        {/*window.innerWidth > 768 ? <div>
                        {
                            clientsHidden ?
                            <FontAwesomeIcon className="header-button" onClick={() => setClientsHidden(false)} icon={faAngleRight} style={{color: "#00497b", marginLeft: "5px"}}/>
                            :
                            <FontAwesomeIcon className="header-button" onClick={() => setClientsHidden(true)} icon={faAngleLeft} style={{color: "#00497b", marginLeft: "5px"}}/>
                        }</div>:""*/}
      </div>
      <div className="relative h-full w-full">
      <div style={{height: "calc(100% - 5.02rem)"}} className="overflow-y-scroll absolute w-full hover:scrollbar-thumb-indigo-200 scrollbar scrollbar-thumb-gray-300 scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-transparent">
        {clients.map((c, index) => (
          <ClientListItem
            setClient={selectClient}
            index={c.id}
            key={index}
            item={c.fullName ? c.fullName : "Anonymous (ID: " + c.id + ")"}
            selected={selectedClient.id === clients[index].id}
            advisor={c.advisorId}
          />
        ))}
      </div>
      </div>
    </div>
  );
};

export default ClientList;
