import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Card from "../atoms/Card";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import Loader from "react-loader-spinner";

type ModalProps = {
  header: React.ReactChild;
  className?: string;
  isOpen: boolean;
  isLoading?: boolean;
  headerIcons?: React.ReactNode;
  buttons?: React.ReactChild | React.ReactChildren;
  headerInfoText?: string;
  headerMargin?:string;
  onClose?: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  submitButtonName?: string;
};

const Modal = ({
  children,
  header,
  className,
  isOpen,
  headerIcons,
  headerInfoText,
  buttons,
  headerMargin,
  isLoading = false,
  onClose,
  onSubmit,
  onCancel,
  submitButtonName,
}: React.PropsWithChildren<ModalProps>) => {
  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <AnimatePresence exitBeforeEnter>
    <motion.div
      className={cx(
        "flex justify-center items-center inset-0 bg-gray-600 bg-opacity-50 h-screen w-screen max-h-screen",
        isOpen ? "fixed" : "hidden"
      )}
      onClick={onClose}
    >
      { isLoading ?
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* @ts-ignore */}
        <Loader type="Oval" color="#0F497B" height={50} width={50} />
      </div>
      :
      <Card
        isModalCard
        className={cx(
          "",
          className
        )}
      >
        <div className="flex flex-col h-full">
          <div className={"pb-2 " + headerMargin}>
            <div className="flex justify-between w-full items-baseline">
              <h3
                className="text-2xl leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                {header}
              </h3>
              <div className="flex items-center">
                {headerIcons}
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={onClose}
                  size="lg"
                  className="cursor-pointer h-full"
                />
              </div>
            </div>
            <div className="text-gray-500 text-sm mb-2">{headerInfoText}</div>
          </div>
          <div className="flex flex-col h-full justify-between overflow-y-auto scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-thin">
            <div className="flex-grow">{children}</div>
            <div className="pt-5 sm:flex sm:flex-row-reverse pb-4">
              {buttons}
            </div>
          </div>
        </div>
      </Card>
      }
    </motion.div>
    </AnimatePresence>
  );
};

export default Modal;
