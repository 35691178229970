import React, { FormEvent } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../slices/advisorSlice';
import { RootState } from '../reducers/rootReducer';
import { StyledError, StyledLabel } from '../styled_components/StyledInput';
import MasterContainerHeader from '../atoms/MasterContainerHeader';
import Input from '../atoms/Input';
import { useTranslation } from 'react-i18next';

const Login = () => {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector((state: RootState) => state.advisor.isAuth)
  const err = useSelector((state: RootState) => state.advisor.error)
  const { t } = useTranslation();

  useEffect(() => {
    if (auth) {
      navigate('/')
    }
  }, [auth, navigate])

  const handleSubmit = () => {
    dispatch(login(email, password))
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
           {/*<img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />*/}
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                {t('email')}
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  {t('password')}
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={_ => handleSubmit()}
              >
                Sign in
              </button>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Don't have an account?{' '}
            <a href="#" className="font-semibold leading-6 text-primary hover:text-indigo-500">
              Contact us
            </a>
          </p>
        </div>
      </div>
    </>
);
  }
  {/*
      <div className="w-full h-full bg-background flex justify-center items-center">
        <div className="shadow-lg rounded-xl w-full h-full md:max-w-lg md:max-h-96 bg-white flex flex-col justify-center">
          <MasterContainerHeader header="Login" className="hidden md:block"/>
          <div className="flex flex-col md:justify-center items-center h-full mt-14 md:mt-0">
            <h1 className="md:hidden w-full pl-4 text-2xl mb-4">{t('login')}</h1> 
            <div className="md:rounded-b-xl w-full px-4 md:px-8">
              <form onSubmit={e => handleSubmit(e)} className="flex flex-col justify-start md:justify-between h-full">
                <div>
                  <StyledLabel>{t('email')}</StyledLabel>
                  <Input id="email" type="text" name="email" className="w-full mb-2 px-4 py-2 rounded-lg" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="john.doe@erstegroup.com"/>
                  <StyledLabel>{t('password')}</StyledLabel>
                  <Input hasError={err.message !== "User not logged in"} className="w-full px-4 py-2 rounded-lg" id="password" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"/>
                  <StyledError style={{marginBottom: "1rem"}}>{ err.message !== "User not logged in" ? err.message : ""}</StyledError>
                </div>
                <div className="flex justify-end">
                  <input id="submit" type="submit" name="submit" value="Login" className="rounded-lg px-8 py-3 bg-primary text-white w-full sm:w-1/2"/>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>*/
  }


export default Login;
