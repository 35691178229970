export type TimeInterval = {
  index: number;
  selected: boolean;
  value: Date;
  title: string;
};

const DateTimeUtils = {
  getDateString: (date: Date) => {
    var dateArr = new Date(date).toISOString().split('T')[0].split('-')

    return dateArr[2] + "." + dateArr[1] + "." + dateArr[0]
  },
  getMonthStart: (date: Date) => {
    var temp = date;
    temp.setDate(1);
    return temp;
  },
  getMonthEnd: (date: Date) => {
    var temp = date;
    temp.setMonth(temp.getMonth() + 1);
    temp.setDate(0);
    return temp;
  },
  getWeekEnd: (date: Date) => {
    var temp = date;
    temp.setDate(temp.getDate() + 7);
    return temp;
  },
  getMonthlieString: (startDate: Date, endDate: Date) => {
    const start =
      (startDate.getDate() < 10
        ? "0" + startDate.getDate()
        : startDate.getDate()) +
      "." +
      (startDate.getMonth() < 9
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1);

    const end =
      (endDate.getDate() < 10 ? "0" + endDate.getDate() : endDate.getDate()) +
      "." +
      (endDate.getMonth() < 9
        ? "0" + (endDate.getMonth() + 1)
        : endDate.getMonth() + 1);
    return start + " - " + end;
  },
  getMonthList: (startDate: Date, endDate: Date, selected: string) => {
    var tempDate = new Date(startDate);
    var index = 0;
    tempDate.setDate(1);
    var dates: TimeInterval[] = [];
    while (tempDate < endDate) {
      const end = DateTimeUtils.getMonthEnd(new Date(tempDate.getTime()));
      dates = [
        ...dates,
        {
          value: new Date(tempDate.toISOString()),
          index: index,
          selected: tempDate.toISOString() === selected,
          title: DateTimeUtils.getMonthlieString(tempDate, end),
        },
      ];
      tempDate.setMonth(tempDate.getMonth() + 1);
      index++;
    }
    if (dates.length > 0 && !dates.find(d => d.selected)) {
      dates[dates.length - 1].selected = true
    }
    return dates;
  },
  getWeekList: (startDate: Date, endDate: Date, selected: string) => {
    var tempDate = new Date(startDate);
    tempDate.setDate(startDate.getDate() - startDate.getDay() + 1)
    var index = 0;
    var dates: TimeInterval[] = [];
    while (tempDate < endDate) {
      const end = DateTimeUtils.getWeekEnd(new Date(tempDate.getTime()));
      end.setDate(end.getDate() - 1)
      dates = [
        ...dates,
        {
          value: new Date(tempDate),
          index: index,
          selected: tempDate.toISOString() === selected,
          title: DateTimeUtils.getMonthlieString(tempDate, end),
        },
      ];
      tempDate.setDate(tempDate.getDate() + 7);
      index++;
    }
    return dates;
  },
};

export default DateTimeUtils;
