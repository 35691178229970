import React, { ChangeEvent } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { StyledTable, TableBody, TableHeader, TableRow } from '../../styled_components/StyledTable'
import DocumentData from '../../types/DocumentData'
import TransactionData from '../../types/TransactionData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons'

type TableProps = {
    columns: {name: string; sortable: boolean; addCurrency?: boolean}[];
    data: DocumentData[] | TransactionData[];
    onRowSelect: (row: number) => void;
    selectedRow?: number;
    parseFirstCol?: boolean;
}

const Table = (props: TableProps) => {

    const [selected, setSelected] = useState([] as boolean[])
    const [headerSelected, setHeaderSelected] = useState(false)
    const [toggleSort, setToggleSort] = useState([] as number[])
    const [data, setData] = useState([] as DocumentData[] | TransactionData[])

    useEffect( () => {
        if(selected.length === 0){
            let tempSelected = new Array<boolean>(props.data.length)
            for (let i = 0; i < props.data.length; i++) {
                tempSelected[i] = false
            }
            setSelected(tempSelected)
        }

    }, [props.data, selected.length])

    useEffect( () => {
        let initSort = new Array<number>(props.columns.length)
        props.columns.forEach((col, index) => {
            if (col.sortable) {
                initSort[index] = 0
            }
        })
        setToggleSort(initSort)
    }, [props.columns])

    useEffect( () => {
        setData(props.data)
    }, [props.data, data])

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        e.stopPropagation()
        let tempSelected = selected;
        tempSelected[index] = !tempSelected[index]
        setSelected(tempSelected)
    }

    const handleHeaderCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation()
        setHeaderSelected(!headerSelected)
        let all = selected.map(s => !headerSelected)
        setSelected(all)
    }

    const handleSortChange = (index: number) => {
        //let propertyName = Object.keys(data[0])[index] as string
        /*let propertyType = typeof data[0][propertyName]
        let tempToggleSort = toggleSort


        if (tempToggleSort[index] === 0 || tempToggleSort[index] === 1) {
            tempToggleSort[index] = 2
        } else if (tempToggleSort[index] === 2) {
            tempToggleSort[index] = 1
        }

        setToggleSort([...tempToggleSort])

        let sortedData = data.sort((a : any, b:any) => {
            if (propertyType === 'number') {
                if (a[propertyName] > b[propertyName] && tempToggleSort[index] === 2) {
                    return -1
                } else if (a[propertyName] > b[propertyName] && tempToggleSort[index] === 1){
                    return 1;
                } else if (a[propertyName] < b[propertyName] && tempToggleSort[index] === 2) {
                    return 1
                } else if (a[propertyName] < b[propertyName] && tempToggleSort[index] === 1){
                    return -1;
                }
            } else if(propertyType === 'string') {
                console.log(a[propertyName] + " " + b[propertyName] +" " + a[propertyName].localeCompare(b[propertyName]))
                if (tempToggleSort[index] === 2) {
                    return -1*a[propertyName].localeCompare(b[propertyName])
                } else {
                    return a[propertyName].localeCompare(b[propertyName])
                }
            }
            return 1
        })

        setData(sortedData)*/
    }

    return (
        <table className="table-auto">
            <thead>
                <th className="checkbox"><input type="checkbox" checked={headerSelected} onChange={(e) => handleHeaderCheckboxChange(e)}/></th>
                {
                    props.columns.map((column, index) => (
                        <th key={index} className="col">{column.name.split('_').join('.')}{column.sortable ? <FontAwesomeIcon style={{marginLeft: ".5rem"}} icon={toggleSort[index+1] <= 1 ? faChevronDown: faChevronUp} onClick={(() => handleSortChange(index + 1))} /> : ""}</th>
                    ))
                }
            </thead>
            <TableBody>
            {
                (data as []).map((row, index) => (
                    <TableRow selected={row['id'] === props.selectedRow} key={index} colCount={props.columns.length} onClick={() => props.onRowSelect(row['id'])}>
                        <td className="checkbox"><input type="checkbox" checked={selected[index]} onChange={(e) => handleCheckboxChange(e, index)}/><span className="custom-checkbox" /></td>
                        {
                            props.columns.map((col, index) => (
                            <td key={index} className="col">{index === 0 && props.parseFirstCol ? (<div>{(row[col.name] as string | null)?.split('/')?.map((el, index) => (<><span className="dir">{el}</span></>))}</div>) : row[col.name] + (props.columns[index].addCurrency ? " €":"")}</td>
                            ))
                        }
                    </TableRow>
                ))
            }
            </TableBody>
        </table>

    )
}

export default Table