import React from 'react'

const NotFound = () => {
    return (
        <div className="bg-blue-100 w-screen h-screen flex flex-col justify-center items-center">
            <div className="w-full sm:w-4/6 md:w-1/2 flex flex-col items-center mt-32 px-4 md:px-0">
                <img src="/404-pic.png" alt="puppy" className="md:w-80 md:h-80 h-60 w-60"/>
                <div className="text-3xl mb-4">A Dog Ate this Page</div>
                <div className="text-center">
                    Your dog is cute but honestly a menace. Where are my shoes? Where is my graduation certificate? 
                    Where is the chocolate cake I baked for my Aunt’s birthday? And why did you take your dog to the 
                    vet on that same Thursday?!
                </div>
            </div>
        </div>
    )
}

export default NotFound