import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";
import Avatar from "react-avatar";
import Dropdown from "../components/utilities/Dropdown";
import { loadDashboardStatistics } from "../slices/statisticsSlice";
import ReactTooltip from "react-tooltip";
import Card from "../atoms/Card";
import BarWrapper from "../atoms/graphs/BarWrapper";
import LineWrapper from "../atoms/graphs/LineWrapper";
import PieWrapper from "../atoms/graphs/PieWrapper";
import cx from 'classnames'
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const user = useSelector((state: RootState) => state.advisor.currentUser);
  const statistics = useSelector(
    (state: RootState) => state.statistics.stats.dashboardStatistics
  );
  const [clientState, setClientState] = useState("SPENDING");
  const [transactionState, setTransacvtionState] =
    useState("PRODUCT_CATEGORIES");
  const [noteState, setNoteState] = useState("FULFILLED");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      user?.id &&
      user?.id > 0 &&
      localStorage.getItem("token")?.length &&
      localStorage.getItem("token")!!.length > 0
    ) {
      dispatch(loadDashboardStatistics());
    }
  }, [user?.id, dispatch]);

  const transformPie = () => {
    var stats;
    if (transactionState === "PRODUCT_CATEGORIES") {
      stats = statistics.globalTransactionStatistics.productCategories
        .map((datum: any) => ({
          id: datum.name,
          label: datum.name,
          value: Math.round(datum.amount * 100) / 100.0,
        }))
        .sort((a: any, b: any) => (a.value > b.value ? 1 : -1));
    } else if (transactionState === "CATEGORIES") {
      stats = statistics.globalTransactionStatistics.categories
        .map((datum: any) => ({
          id: datum.name,
          label: datum.name,
          value: Math.round(datum.amount * 100) / 100.0,
        }))
        .sort((a: any, b: any) => (a.value > b.value ? 1 : -1));
    }

    if (stats) {
      return stats;
    } else {
      return [];
    }
  };

  const getNoteStatistics = () => {
    var noteStatistics;
    if (noteState === "FULFILLED") {
      noteStatistics = statistics.globalNoteStatistics.fulfilled.points.map(
        (point: any) => ({ x: point.date.split("-")[2], y: point.count })
      );
    } else if (noteState === "UNFULFILLED") {
      noteStatistics = statistics.globalNoteStatistics.unfulfilled.points.map(
        (point: any) => ({ x: point.date.split("-")[2], y: point.count })
      );
    } else if (noteState === "PARTIALLY_UNFULFILLED") {
      noteStatistics =
        statistics.globalNoteStatistics.partiallyFulfilled.points.map(
          (point: any) => ({ x: point.date.split("-")[2], y: point.count })
        );
    }
    console.log(statistics);
    if (noteStatistics) {
      return noteStatistics;
    } else {
      return [];
    }
  };

  const getClientStatistics = () => {
    var stats;
    if (clientState === "SPENDING") {
      stats = statistics.advisorTransactionStatistics.expenses?.points!!.map(
        (p: any) => ({
          x: p.date.split("-").reverse().slice(0, 2).join("."),
          y: Math.round(p.value * 100) / 100.0,
        })
      );
    } else if (clientState === "INCOME") {
      stats = statistics.advisorTransactionStatistics.income?.points!!.map(
        (p: any) => ({
          x: p.date.split("-").reverse().slice(0, 2).join("."),
          y: Math.round(p.value * 100) / 100.0,
        })
      );
    }
    if (stats) {
      return stats;
    } else {
      return [];
    }
  };

  return (
    <div className="w-screen md:w-full pt-14 flex justify-center bg-background h-auto md:h-full md:overflow-y-hidden">
      <div className="w-full lg:w-11/12 1.5xl:max-w-screen-xl">
        <div
          className="md:grid md:grid-cols-8 md:grid-rows-6 md:grid-flow-col md:auto-cols-max md:auto-rows-min h-auto md:h-full gap-4 px-8 flex flex-col"
          style={{ maxHeight: "83vh", height: "90vh" }}
        >
          {statistics && (
            <>
              <div className="col-span-3 row-span-3 col grid grid-cols-3 grid-rows-3 grid-flow-row 1.5lg:grid-flow-col gap-4">
                <div className="1.5lg:col-span-2 1.5lg:row-span-3 col-span-3 row-span-2 grid-flow-row stats shadow">
                  <div className="stat rounded-t-xl pt-2">
                    <div className="stat-figure text-info">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-users"
                      >
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                    </div>
                    <div className="stat-title">{t('n_clients')}</div>
                    <div className="stat-value">
                      {statistics.numberOfClients === 0 ||
                      statistics.numberOfClients > 0
                        ? statistics.numberOfClients
                        : "N/A"}
                    </div>
                  </div>
                  <div className="stat pt-2">
                    <div className="stat-figure text-info">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-mail"
                      >
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                        <polyline points="22,6 12,13 2,6"></polyline>
                      </svg>
                    </div>
                    <div className="stat-title">{t('n_notes')}</div>
                    <div className="stat-value">
                      {statistics.numberOfNotes === 0 ||
                      statistics.numberOfNotes > 0
                        ? statistics.numberOfNotes
                        : "N/A"}
                    </div>
                  </div>
                  <div className="stat pt-2">
                    <div className="stat-figure text-yellow-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-alert-circle"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                      </svg>
                    </div>
                    <div className="stat-title">{t('approaching_notes')}</div>
                    <div className="stat-value">
                      {statistics.approachingNotes === 0 || statistics.approachingNotes > 0
                        ? statistics.approachingNotes
                        : "N/A"}
                    </div>
                  </div>
                  <div className="stat pt-2">
                    <div className="stat-figure text-red-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-alert-triangle"
                      >
                        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                        <line x1="12" y1="9" x2="12" y2="13"></line>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                      </svg>
                    </div>
                    <div className="stat-title">{t('close_notes')}</div>
                    <div className="stat-value">
                      {statistics.closeNotes === 0 ||
                      statistics.closeNotes > 0
                        ? statistics.closeNotes
                        : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="stat col-span-1 row-span-1 rounded-xl shadow pt-2">
                  <p
                    data-tip="Average income of your clients for this month"
                    className="stat-title"
                  >
                    {t('income')}
                  </p>
                  <ReactTooltip />
                  <div className={cx(
                    "stat-value",
                    { "text-3xl" : statistics.averageIncome > 1000 &&  statistics.averageIncome < 10000,
                      "text-2xl" : statistics.averageIncome > 10000 &&  statistics.averageIncome < 100000,
                      "text-xl" : statistics.averageIncome > 100000 ,
                     }
                  )}>
                    {statistics.averageIncome
                      ? Math.round(statistics.averageIncome) + " €"
                      : "0 €"}
                  </div>
                </div>
                <div className="stat col-span-1 row-span-1 rounded-xl shadow pt-2">
                  <p
                    className="stat-title"
                    data-tip="Average expenses of your clients for this month"
                  >
                    {t('expense')}
                  </p>

                  <ReactTooltip />

                  <div className={cx(
                    "stat-value",
                    { "text-3xl" : statistics.averageExpenses > 1000 &&  statistics.averageExpenses < 10000,
                      "text-2xl" : statistics.averageExpenses > 10000 &&  statistics.averageExpenses < 100000,
                      "text-xl" : statistics.averageExpenses > 100000 ,
                     }
                  )}>
                    {statistics.averageExpenses
                      ? Math.round(statistics.averageExpenses) + " €"
                      : "0 €"}
                  </div>
                </div>
                <div className="stat col-span-1 row-span-1 rounded-xl shadow pt-2">
                  <p
                    className="stat-title"
                    data-tip="Notes fulfilled by your clients during this month"
                  >
                    {t('fulfilled')}
                  </p>

                  <ReactTooltip />

                  <div className="stat-value">
                    {statistics.notesFulfilled === 0 ||
                    statistics.notesFulfilled > 0
                      ? statistics.notesFulfilled + " x"
                      : "N/A"}
                  </div>
                </div>
              </div>
              <Card
                className="col-span-3 row-span-3 md:h-full max-h-50-vh h-50-vh shadow"
                header={
                  <>
                    {t('transactions')}
                    <div className="text-sm">
                      <Dropdown
                        items={[
                          {
                            title: "Product categories",
                            selected: transactionState === "PRODUCT_CATEGORIES",
                            value: "PRODUCT_CATEGORIES",
                          },
                          {
                            title: "Categories",
                            selected: transactionState === "CATEGORIES",
                            value: "CATEGORIES",
                          },
                        ]}
                        onChange={(s) => {
                          setTransacvtionState(s);
                        }}
                      />
                    </div>
                  </>
                }
              >
                <div className="h-full w-full flex justify-center items-center pb-8">
                  {statistics?.globalTransactionStatistics?.categories &&
                  statistics?.globalTransactionStatistics?.categories
                    .length > 0 ? (
                    <PieWrapper data={transformPie()} color={true} />
                  ) : (
                    <div className="flex items-center justify-center h-40">
                      {t('no_data')}
                    </div>
                  )}
                </div>
              </Card>
              <Card
                className="row-span-3 col-span-3 md:h-full max-h-50-vh h-50-vh shadow"
                header={
                  <>
                    {t('my_clients')}
                    <div className="text-sm">
                      <Dropdown
                        items={[
                          {
                            title: "Client spending",
                            selected: clientState === "SPENDING",
                            value: "SPENDING",
                          },
                          {
                            title: "Client income",
                            selected: clientState === "INCOME",
                            value: "INCOME",
                          },
                        ]}
                        onChange={(s) => {
                          setClientState(s);
                        }}
                      />
                    </div>
                  </>
                }
              >
                <div className="h-full w-full flex justify-center items-center pb-8">
                  {statistics?.advisorTransactionStatistics?.expenses ? (
                    <LineWrapper
                      data={getClientStatistics()}
                      label={clientState === "SPENDING" ? "Expenses" : "Income"}
                    />
                  ) : (
                    <div className="flex items-center justify-center h-40">
                      {t('no_data')}
                    </div>
                  )}
                </div>
              </Card>

              <Card
                className="row-span-3 col-span-3 md:h-full max-h-50-vh h-50-vh shadow"
                header={
                  <>
                    {t('notes')}
                    <div className="text-sm">
                      <Dropdown
                        items={[
                          {
                            title: "Fulfilled",
                            selected: noteState === "FULFILLED",
                            value: "FULFILLED",
                          },
                          {
                            title: "Unfulfilled",
                            selected: noteState === "UNFULFILLED",
                            value: "UNFULFILLED",
                          },
                          {
                            title: "Partially fulfilled",
                            selected: noteState === "PARTIALLY_UNFULFILLED",
                            value: "PARTIALLY_UNFULFILLED",
                          },
                        ]}
                        onChange={(s: string) => {
                          setNoteState(s);
                        }}
                      />
                    </div>
                  </>
                }
              >
                <div className="h-full w-full flex justify-center items-center pb-8">
                  {statistics.globalNoteStatistics ? (
                    <BarWrapper data={getNoteStatistics()} label="Count" />
                  ) : (
                    <div className="flex items-center justify-center">
                      {t('no_data')}
                    </div>
                  )}
                </div>
              </Card>

              <Card
                className="col-span-2 row-span-6 shadow"
                header={"Reminders"}
              >
                <div className="h-full w-full flex flex-col min-h-24-rem overflow-y-auto">
                  {statistics.reminders &&
                    statistics.reminders.map((r) => (
                      <div className="flex mt-3">
                        <Avatar
                          name={
                            r.clientName ? r.clientName : "Anonymous Anonym"
                          }
                          round={true}
                          size="4rem"
                        />
                        <div className="info-col">
                          <p
                            style={{
                              fontSize: "20px",
                              marginLeft: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            {r.clientName}
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              marginLeft: "1rem",
                              color: "rgb(125, 133, 137)",
                              marginTop: ".15rem",
                            }}
                          >
                            {r.date}
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              marginLeft: "1rem",
                              marginTop: ".25rem",
                            }}
                          >
                            {r.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  {(!statistics.reminders ||
                    statistics.reminders.length === 0) && (
                    <div style={{ color: "#7D8589" }}>{t('no_remainders')}</div>
                  )}
                </div>
              </Card>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
