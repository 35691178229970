import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactChild, ReactChildren, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../../atoms/Modal";
import { RootState } from "../../reducers/rootReducer";
import getAxios from "../../services/api";
import {
  CatStatsDataType,
  StatsType,
  TempStatsDataType,
} from "../../slices/graphSlice"
import { v4 } from 'uuid'
import { useTranslation } from "react-i18next";

type StatisticsModalProps = {
  graph: ReactChild | ReactChildren;
  isOpen: boolean;
  title: string;
  dataType: CatStatsDataType | TempStatsDataType;
  onClose: () => void;
};

type ProductCategoryStat = {
  name: string;
  value: number;
  percentage: number;
};

type ProductStat = {
  id?: string;
  name: string;
  category: string;
  quantity: number;
  price: number;
};

const StatisticsModal = ({
  graph,
  isOpen,
  title,
  dataType,
  onClose,
}: StatisticsModalProps) => {
  const config = useSelector(
    (state: RootState) => state.statistics.statsConfig
  );

  const client = useSelector((state: RootState) => state.clients.selectedClient.id)
  const [categories, setCategories] = useState<ProductCategoryStat[]>([]);
  const [products, setProducts] = useState<ProductStat[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const {t} = useTranslation()

  useEffect(() => {
    if (isOpen && dataType === CatStatsDataType.productCat) {
      getCategories();
      getCategoryProducts("meat/meat")
    }
    const monthEnd = new Date(config.intervalStart)
    monthEnd.setDate(0)
    return () => {};
    
  }, [isOpen]);

  useEffect(() => {
    if (selectedCategory) {
      getCategoryProducts(selectedCategory)
    }
  }, [selectedCategory])

  const getDateString = (date: Date) => {
    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
    var month = date.getMonth()+1 < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)
    console.log(month)
    return date.getFullYear() + "-" + month + "-" + day
  }

  const getCategories = () => {
    if (config.type === StatsType.monthly) {
      const monthEnd = new Date(config.intervalStart)
      monthEnd.setMonth(monthEnd.getMonth()+1)
      monthEnd.setDate(0)
      getAxios().get("/advisor/clients/" + client + "/statistics/categories?startDate=" + config.intervalStart.split('T')[0] + "&endDate=" + getDateString(monthEnd))
        .then((res) => setCategories(res.data.categories))
    } else if (config.type === StatsType.weekly) {
      const weekEnd = new Date(config.intervalStart)
      weekEnd.setDate(weekEnd.getDate() + 7)
      getAxios().get("/advisor/clients/" + client + "/statistics/categories?startDate=" + config.intervalStart.split('T')[0]  + "&endDate=" + getDateString(weekEnd))
        .then((res) => setCategories(res.data.categories))
    }
  }

  const getCategoryProducts = (category: string) => {
    if (config.type === StatsType.monthly) {
      const monthEnd = new Date(config.intervalStart)
      monthEnd.setMonth(monthEnd.getMonth()+1)
      monthEnd.setDate(0)
      getAxios().get("/advisor/clients/" + client + "/statistics/products?startDate=" + config.intervalStart.split('T')[0]  + "&endDate=" + getDateString(monthEnd) + "&category=" + encodeURIComponent(category))
        .then((res) => setProducts(res.data))
    } else if (config.type === StatsType.weekly) {
      const weekEnd = new Date(config.intervalStart)
      weekEnd.setDate(weekEnd.getDate() + 7)
      getAxios().get("/advisor/clients/" + client + "/statistics/products?startDate=" + config.intervalStart.split('T')[0]  + "&endDate=" + getDateString(weekEnd) + "&category=" + encodeURIComponent(category))
        .then((res) => setProducts(res.data))
    }
  }


  return (
    <Modal
      header={
        <>
          <FontAwesomeIcon icon={faChevronLeft} onClick={() => setSelectedCategory(null)} className={`mr-2 ${selectedCategory ? "":"hidden"}`}/>
          {title}
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      className={`md:max-h-75-vh ${(dataType === CatStatsDataType.productCat 
        || dataType === TempStatsDataType.balance
        || dataType === TempStatsDataType.expense
        || dataType === TempStatsDataType.income
        ) ? "md:max-w-7xl" : "md:max-w-4xl"}`}
    >
      <div className="flex flex-col md:flex-row md:max-h-60-vh h-full">
        <div className={`border rounded-lg max-h-60-vh overflow-y-auto w-full md:w-5/12 mt-4 md:mt-0 h-full ${(dataType !== CatStatsDataType.productCat) ? " hidden" : ""}`}>
          { selectedCategory == null ? <>{categories?.map((cat, index) => (
            <div
              key={v4()}
              onClick={() => setSelectedCategory(cat.name)}
              className={`flex flex-row justify-between px-2 py-1 ${index !== products.length-1 ? "border-b":""}`}
            >
              <div>
                {cat.name
                  .split("/")
                  .map(
                    (n: any) => n[0].toUpperCase() + n.slice(1, n.length + 1)
                  )
                  .join(" - ")}
              </div>
              <div>
                {Math.round(cat.value*100)/100.0} € ({Math.round(cat.percentage*10000)/100.0} %)
              </div>
            </div>
          ))}</>: <>{products?.map((p, index) => <div
            key={v4()}
            className={`flex flex-row justify-between px-2 py-1 ${index !== products.length-1 ? "border-b":""}`}
          >
            <div>
              {p.name
                .split("/")
                .map(
                  (n: any) => n[0].toUpperCase() + n.slice(1, n.length + 1)
                )
                .join(" - ")}
            </div>
            <div>
              {Math.round(p.price*100)/100} €
            </div>
          </div>)}</>}
        </div>
        <div className={`${ dataType === CatStatsDataType.productCat ? "md:w-1/2" : "md:w-full"} max-h-60-vh h-full`}>{graph}</div>
      </div>
    </Modal>
  );
};

export default StatisticsModal;
