import styled from "styled-components";

const AnchorMenu = styled.ul`
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  width: 150px;
  height: auto;
  margin: 0;
  position: absolute;
  list-style: none;
  opacity: 1;
  transition: opacity 0.5s linear;
  border-radius: 5px;
  border: 2px solid #d0d3d4;

  li {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #d0d3d4;
    cursor: pointer;

    ul {
      width: 150px;
      border-radius: 5px;
      border: 2px solid #d0d3d4;
      li {
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #d0d3d4;
        cursor: pointer;
      }
    }
  }

  li:hover {
    background-color: #d0d3d4;
  }

  li:last-of-type {
    border-bottom: 0;
  }
`

export default AnchorMenu