import axios from 'axios'

const BASE_URL = "/api"

// const BASE_URL = process.env.NODE_ENV === "production" ? "https://localhost/advisor/api" : "/api"

const getAxios = () => {
    const api =  axios.create({
        baseURL: BASE_URL,
        headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
    })

    api.interceptors.request.use(
        (succ) => {return succ},
        (error) => {return Promise.reject(error)}
    )

    return api
}

export default getAxios