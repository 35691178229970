import { combineReducers } from '@reduxjs/toolkit'
import advisorSlice from '../slices/advisorSlice'
import clientReducer from '../slices/clientSlice'
import clientSortSlice from '../slices/clientSortSlice'
import documentSlice from '../slices/documentSlice'
import noteSlice from '../slices/noteSlice'
import statisticsSlice from '../slices/statisticsSlice'

const rootReducer = combineReducers({
    clients: clientReducer,
    clientSort: clientSortSlice,
    advisor: advisorSlice,
    note: noteSlice,
    statistics: statisticsSlice,
    documents: documentSlice
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer