import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import UserStatistics from "../../types/Statistics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompressArrowsAlt,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../utilities/Dropdown";
import {
  CatStatsDataType,
  TempStatsDataType,
  StatsType,
} from "../../slices/graphSlice";
import Loader from "react-loader-spinner";
import Card from "../../atoms/Card";
import LineWrapper from "../../atoms/graphs/LineWrapper";
import PieWrapper from "../../atoms/graphs/PieWrapper";
import BarWrapper from "../../atoms/graphs/BarWrapper";
import cx from "classnames";
import StatisticsModal from "./StatisticsModal";
import { TCustomStatistics } from "../../types/TCustomStatistics";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

type GraphContainerPropsType = {
  transform: (
    stats: TCustomStatistics,
    type: string,
    dataType: string,
    weekStart: string,
    monthStart: string
  ) => any;
  graphType: "pie" | "line";
  data: TCustomStatistics;
  setExpandedGraph: (id: number) => void;
  expandedGraph: number;
  loading: boolean;
  id: number;
  className?: string;
};

const GraphContainer = ({
  transform,
  graphType,
  id,
  setExpandedGraph,
  data,
  loading,
  expandedGraph,
  className,
}: GraphContainerPropsType) => {
  const dispatch = useDispatch();
  const stats = useSelector(
    (state: RootState) => state.statistics.stats.selectedClientStatistics
  );
  const config = useSelector(
    (state: RootState) => state.statistics.statsConfig
  );
  const [selectedStatistics, setSelectedStatistics] = useState<CatStatsDataType | TempStatsDataType>(
    graphType === "pie" ? id === 1 ? CatStatsDataType.organization : CatStatsDataType.productCat : TempStatsDataType.balance
  )
  const {t} = useTranslation()

  const [graphableStats, setGraphableStats] = useState<any>([]);

  useEffect(() => {
    setGraphableStats(
      transform(
        data,
        config.type,
        selectedStatistics,
        config.intervalStart,
        config.intervalStart
      )
    );
  }, [config, selectedStatistics, dispatch, id, stats, transform]);

  const expandGraph = (toggle: boolean) => {
    if (toggle) {
      setExpandedGraph(id);
    } else {
      setExpandedGraph(-1);
    }
  };

  const getGraph = () => {
    if (id === 0 && selectedStatistics === "BALANCE" && graphableStats.datasets) {
      return (
        <LineWrapper
          data={graphableStats.datasets[0].data}
          bottomMargin={25}
          topMargin={5}
          label="Balance"
        />)
    } else if ((id === 1 || id === 2) && graphableStats.length > 0) {
      return (<PieWrapper
        data={graphableStats}
      />)
    } else if (graphableStats.datasets){
      return <BarWrapper
        data={graphableStats.datasets[0].data}
        label={
          selectedStatistics[0] +
          selectedStatistics[0]
            .slice(0, selectedStatistics[0].length)
            .toLowerCase()
        }
      />
    } else {
      return <></>
    }
  }

  const getTitle = () => {
    return (selectedStatistics.toString()[0] + selectedStatistics.toString().toLowerCase().slice(1)).split("_").join(" ")
  }


  return (
    <>
      {expandedGraph === id || expandedGraph === -1 ? (
        <div className={cx("flex-grow  border-1 bg-gray-100 rounded-xl p-3 ", className)}>
          <div className="w-full h-full">
              <div className="text-sm w-full flex justify-between">
                {graphType === "pie" ? (
                  <Dropdown
                    onChange={(v) => setSelectedStatistics(v)}
                    items={
                      config.type !== StatsType.customRange
                        ? [
                            {
                              title: t("categories"),
                              value: "CATEGORY",
                              selected: selectedStatistics === "CATEGORY",
                            },
                            {
                              title: t("organizations"),
                              value: "ORGANIZATION",
                              selected: selectedStatistics === "ORGANIZATION",
                            },
                            {
                              title: t("units"),
                              value: "UNIT",
                              selected: selectedStatistics === "UNIT",
                            },
                            {
                              title: t("products"),
                              value: "PRODUCT_CATEGORY",
                              selected: selectedStatistics === "PRODUCT_CATEGORY",
                            },
                            {
                              title: t("tags"),
                              value: "TAG",
                              selected: selectedStatistics === "TAG",
                            },
                          ]
                        : [
                            {
                              title: t("categories"),
                              value: "CATEGORY",
                              selected: selectedStatistics === "CATEGORY",
                            },
                            {
                              title: t("organizations"),
                              value: "ORGANIZATION",
                              selected: selectedStatistics === "ORGANIZATION",
                            },
                            {
                              title: t("units"),
                              value: "UNIT",
                              selected: selectedStatistics === "UNIT",
                            },
                            {
                              title: t("tags"),
                              value: "TAG",
                              selected: selectedStatistics === "TAG",
                            },
                          ]
                    }
                  />
                ) : (
                  <Dropdown
                    onChange={(v) => setSelectedStatistics(v)}
                    items={
                      config.type !== StatsType.customRange
                        ? [
                            {
                              title: t("balance"),
                              value: "BALANCE",
                              selected: selectedStatistics === "BALANCE",
                            },
                            {
                              title: t("income"),
                              value: "INCOME",
                              selected: selectedStatistics === "INCOME",
                            },
                            {
                              title: t("Expense"),
                              value: "EXPENSE",
                              selected: selectedStatistics === "EXPENSE",
                            },
                          ]
                        : [
                            {
                              title: t("income"),
                              value: "INCOME",
                              selected: selectedStatistics === "INCOME",
                            },
                            {
                              title: t("expense"),
                              value: "EXPENSE",
                              selected: selectedStatistics === "EXPENSE",
                            },
                          ]
                    }
                  />
                )}
                <div>
                  {expandedGraph === id ? (
                    <FontAwesomeIcon
                      onClick={() => expandGraph(false)}
                      icon={faCompressArrowsAlt}
                      color={"#0F497B"}
                      style={{
                        marginLeft: "5px",
                        alignSelf: "center",
                        cursor: "pointer",
                        marginRight: ".5rem",
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={() => expandGraph(true)}
                      icon={faExpandArrowsAlt}
                      color={"#0F497B"}
                      style={{
                        marginLeft: "5px",
                        alignSelf: "center",
                        cursor: "pointer",
                        marginRight: ".5rem",
                      }}
                    />
                  )}
                </div>
              </div>
            
            {!loading ? (
              <div className="w-full h-full pb-10">
                {(graphableStats.datasets && graphableStats.datasets[0].data &&
                graphableStats.datasets[0].data.length > 0) ? (
                  <div
                    style={{ width: "100%", height: "100%", minWidth: 0 }}
                  >
                    {getGraph()}
                  </div>
                ) : graphableStats && graphableStats.length > 0 && graphableStats[0].id > 0? (
                  <div
                    style={{ width: "100%", height: "100%", minWidth: 0 }}
                  >
                    {getGraph()}
                  </div>
                  ):(
                  <p
                    className="w-full h-full flex justify-center items-center text-gray-500"
                  >
                    {" "}
                    {t("no_data")}
                  </p>
                )}
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "80%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader type="Oval" color="#0F497B" height={50} width={50} />
              </div>
            )}
          </div>
          <StatisticsModal
            isOpen={expandedGraph === id}
            title={getTitle()}
            onClose={() => setExpandedGraph(-1)}
            graph={
              getGraph()
            }
            dataType={selectedStatistics}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default GraphContainer;
