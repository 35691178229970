import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BasicClient from '../types/BasicClient'
import ClientDetails from '../types/ClientDetails'
import TransactionData from '../types/TransactionData'
import { AppThunk, AppDispatch } from '../store'
import ClientService from '../services/ClientService'

const initialState = {
    clientList: [] as BasicClient[],
    selectedClient: {
        id: -1,
        name: "",
        last_update: "",
        account_balance: 0,
        advisor_name: "",
        incomePastMonth: 0,
        expensesPastMonth: 0,
        warningMessage: null,
        created_at: ""
    } as ClientDetails,
    loading: false,
    transactionsLoading: false,
    selectedClientTransactions: [] as TransactionData[]
}

const clientSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        receiveClientList(state, action: PayloadAction<BasicClient[]>) {
            var newState = {...state}
            newState.clientList = action.payload
            return newState
        },
        receiveClientDetails(state, action: PayloadAction<ClientDetails>) {
            var newState = {...state, selectedClient: action.payload}
            return newState
        },
        setClientLoading(state, action: PayloadAction<boolean>) {
            var newState = {...state, loading: action.payload}
            return newState
        },
        setTransactionsLoading(state, action: PayloadAction<boolean>) {
            var newState = {...state, transactionsLoading: action.payload}
            return newState
        },
        receiveNewClientAdvisor(state, action: PayloadAction<{clientId: number, advisorId: number, advisorName: string}>) {
            var selectedClient = {...state.selectedClient}
            if (selectedClient.id === action.payload.clientId){
                selectedClient.advisor_name = action.payload.advisorName
            }
            var newState = {...state, selectedClient}
            return newState
        },
        removeClientAdvisor(state, action: PayloadAction<{clientId: number, advisorId: number, advisorName: string}>) {
            var selectedClient = {...state.selectedClient}
            if (selectedClient.id === action.payload.clientId){
                selectedClient.advisor_name = "-"
            }
            var newState = {...state, selectedClient}
            return newState
        },
        receiveClientTransactions(state, action: PayloadAction<TransactionData[]>) {
            var newState = {...state, selectedClientTransactions: action.payload}
            return newState
        }
    }
})

export const loadClientList = (): AppThunk => async (dispatch: AppDispatch) => {
    var clientList = await ClientService.getClientList()
    //clientList = clientList.filter(c => true)
    dispatch(clientSlice.actions.receiveClientList(clientList))
}

export const loadClientDetails = (id: number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(clientSlice.actions.setClientLoading(true))
    var clientDetails = await ClientService.getClientInfo(id)
    dispatch(clientSlice.actions.receiveClientDetails(clientDetails))
    dispatch(clientSlice.actions.setClientLoading(false))
}

export const loadClientTransactions = (clientId: number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(clientSlice.actions.setTransactionsLoading(true))
    var clientTransactions = await ClientService.getTransactions(clientId)
    dispatch(clientSlice.actions.receiveClientTransactions(clientTransactions))
    dispatch(clientSlice.actions.setTransactionsLoading(false))
}

export const addClientToAdvisor = (clientId: number, advisorId: number, advisorName: string): AppThunk => async (dispatch: AppDispatch) => {
    await ClientService.addClientToAdvisor(clientId)
    dispatch(loadClientList())
    dispatch(clientSlice.actions.receiveNewClientAdvisor({clientId, advisorId, advisorName}))
}

export const removeClientFromAdvisor = (clientId: number, advisorId: number, advisorName: string): AppThunk => async (dispatch: AppDispatch) => {
    await ClientService.removeClientFromAdvisor(clientId)
    dispatch(loadClientList())
    dispatch(clientSlice.actions.removeClientAdvisor({clientId, advisorId, advisorName}))
}


export default clientSlice.reducer