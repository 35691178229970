import { useState } from "react";
import ReactTooltip from "react-tooltip";
import { faCog, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import Loader from "react-loader-spinner";
import {
  addClientToAdvisor,
  removeClientFromAdvisor,
} from "../../slices/clientSlice";
import Dialog from "../utilities/Dialog";
import Dropdown from "../utilities/Dropdown";
import ClientNavButton from "../../atoms/ClientNavButton";
import InfoRow from "../../atoms/InfoRow";
import { useTranslation } from "react-i18next";

type ClientInfoProps = {
  className?: string;
  setClientInfoHidden: (hide: boolean) => void;
};

const ClientInfo = ({
  className,
  setClientInfoHidden,
}: ClientInfoProps) => {
  const [toggleWarning, setToggleWarning] = useState(false);
  const [clientDialog, setClientDialog] = useState(false);

  const clients = useSelector((state: RootState) => state.clients.clientList);
  const profile = useSelector(
    (state: RootState) => state.clients.selectedClient
  );
  const user = useSelector((state: RootState) => state.advisor.currentUser!!);
  const loading = useSelector((state: RootState) => state.clients.loading);
  const {t} = useTranslation()
  const dispatch = useDispatch();

  const getActionName = (): string => {
    var clientAdvisor = clients.find((c) => c.id === profile.id)?.advisorId;

    if (!clientAdvisor) {
      return t('add_client');
    } else if (clientAdvisor === user.id) {
      return t("remove_client");
    } else {
      return t("remove_advisor_client");
    }
  };

  const getClientDialogText = (): string => {
    var clientAdvisor = clients.find((c) => c.id === profile.id)?.advisorId;

    if (!clientAdvisor) {
      return t("add_client_question");
    } else if (clientAdvisor === user.id) {
      return t("remove_client_question");
    } else {
      return t("remove_advisor_client_question");
    }
  };

  const handleClient = () => {
    var clientAdvisor = clients.find((c) => c.id === profile.id)?.advisorId;

    if (!clientAdvisor) {
      dispatch(addClientToAdvisor(profile.id, user.id, user.fullName));
    } else {
      dispatch(removeClientFromAdvisor(profile.id, user.id, user.fullName));
    }
  };

  return (
    <div
      className={
        "border-t-2 bg-white shadow-md rounded-xl max-h-full border-green-600 md:h-98 mb-4 md:mb-0" + className
      }
    >
      {!loading ? (
        <div className="">
          <Dialog
            isOpen={clientDialog}
            color=""
            type={getActionName()}
            content={getClientDialogText()}
            onConfirm={() => {
              handleClient();
              setClientDialog(false);
            }}
            onCancel={() => setClientDialog(false)}
          />
          <div className="w-full flex justify-end pr-2 pt-1 items-center ">
            <Dropdown
              icon={faCog}
              items={[{ title: getActionName(), selected: false, value: "" }]}
              onChange={() => setClientDialog(true)}
            />

            <FontAwesomeIcon
              size="1x"
              icon={faTimesCircle}
              style={{ border: "0px", cursor: "pointer", color: "#7E8589" }}
              onClick={() => setClientInfoHidden(true)}
            />
          </div>
          <div className="px-3 pb-3">
            <div className="mb-0 px-1">
              <div className="w-full flex">
                <img
                  src={`/user.svg`}
                  alt="User profile"
                  className="w-20 h-20"
                />
                <div className="ml-4 flex flex-col justify-center">
                  <div className="text-xl">
                    <p>
                      {profile.name
                        ? profile.name
                        : "Anonymous (ID: " + profile.id + ")"}
                    </p>
                    {profile.warningMessage && toggleWarning && (
                      <div>
                        <img
                          id="warning"
                          data-tip={profile.warningMessage}
                          onMouseOver={() => {
                            setToggleWarning(true);
                          }}
                          src="error.svg"
                          alt="Warning"
                        />
                        <ReactTooltip
                          type="light"
                          className="tooltip"
                          effect="solid"
                          backgroundColor="white"
                        />
                      </div>
                    )}
                  </div>
                  <div className="text-sm text-grey-primary" id="advisor">
                    {t('advisor')}: {profile.advisor_name ? profile.advisor_name : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4 px-3 rounded-xl bg-gray-100">
              <div className="flex justify-between text-sm text-grey-primary">
                <div>{t('last_update')}</div>
                <div>{profile.last_update}</div>
              </div>
              <InfoRow
                description={t('account_balance')}
                value={
                  Math.round((profile.account_balance + Number.EPSILON) * 100) /
                    100 +
                  " €"
                }
              />
              <InfoRow
                description={t('income_month')}
                value={
                  Math.round((profile.incomePastMonth + Number.EPSILON) * 100) /
                    100 +
                  " €"
                }
              />
              <InfoRow
                description={t('expenses_month')}
                value={
                  Math.round((profile.expensesPastMonth + Number.EPSILON) * 100) /
                    100 +
                  " €"
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-60 flex justify-center items-center">
          <Loader type="Oval" color="#0F497B" height={50} width={50} />
        </div>
      )}
      <ul className="px-3">
        <ClientNavButton to="/clients" value={t('overview')} first end />
        <ClientNavButton to="/clients/notes" value={t('notes')} />
        <ClientNavButton to="/clients/documents" value={t('documents')} />
        <ClientNavButton to="/clients/transactions" value={t('transactions')} />
        <ClientNavButton to="/clients/statistics" value={t('statistics')}/>
      </ul>
    </div>
  );
};

export default ClientInfo;
