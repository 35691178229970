import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdvisorService from '../services/AdvisorService'
import { AppDispatch, AppThunk } from '../store'
import User from '../types/User'


export interface AuthError {
    message: string
}

export interface AuthState {
    isAuth: boolean,
    isLoading: boolean,
    error: AuthError,
    currentUser?: User 
}

const initialState: AuthState = {
    isAuth: false,
    isLoading: true,
    error: {message: "An error occured"}
}

const advisorSlice = createSlice({
    name: "advisor",
    initialState,
    reducers: {
        setAuthSuccess(state, action: PayloadAction<User>) {
            var newState = {...state}
            newState.isAuth = true
            newState.currentUser = action.payload
            return newState
        },
        setAuthFailed(state, action: PayloadAction<string>) {
            var newState = {...state}
            newState.isAuth = false
            newState.error = {message: action.payload}
            return newState
        },
        setLoading(state, action: PayloadAction<boolean>) {
            var newState = {...state}
            newState.isLoading = action.payload
            return newState
        },
        logout(state) {
            var newState = {...state}
            newState.isAuth = false
            newState.currentUser = undefined
            return newState
        }
    }
})

export const logout = (): AppThunk => async (dispatch: AppDispatch) => {
    try {
        await AdvisorService.logout()
        dispatch(advisorSlice.actions.logout())
    } catch (err) {
        advisorSlice.actions.setAuthFailed(err.message)
    } finally {
        window.location.reload()
    }
} 

export const login = (email: string, password: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(advisorSlice.actions.setLoading(true))
    try {
        var advisor = await AdvisorService.login(email, password)
        dispatch(advisorSlice.actions.setAuthSuccess(advisor!!))
    } catch(err) {
        dispatch(advisorSlice.actions.setAuthFailed(err.message))
    } finally {
        dispatch(advisorSlice.actions.setLoading(false))
    }
}

export const getProfile = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(advisorSlice.actions.setLoading(true))
    try {
        var advisor = await AdvisorService.getProfile()
        dispatch(advisorSlice.actions.setAuthSuccess(advisor))
    } catch (err) {
        dispatch(advisorSlice.actions.setAuthFailed(err.message))
    } finally {
        dispatch(advisorSlice.actions.setLoading(false))
    }
}

export const setProfile = (profile: User): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(advisorSlice.actions.setLoading(true))
    try {
        await AdvisorService.setProfile(profile)
        dispatch(advisorSlice.actions.setAuthSuccess(profile))
    } catch (err) {
        dispatch(advisorSlice.actions.setAuthFailed(err.message))
    } finally {
        dispatch(advisorSlice.actions.setLoading(false))
    }
}

export default advisorSlice.reducer
