import cx from 'classnames'

type TimePickerProps = {
    className?: string,
    value?: Date,
    onChange: (value: Date) => void
}

const TimePicker = ({ className, value, onChange}: TimePickerProps) => {


    const changeHours = (hours: string) => {
        
        var tempDate = value
        if (getDayPart() === "am") {
            tempDate?.setHours(parseInt(hours))
        } else {
            tempDate?.setHours(parseInt(hours) + 12)
        }
        onChange(tempDate!)
    }

    const changeMinutes = (minutes: string) => {
        var tempDate = value
        tempDate?.setMinutes(parseInt(minutes))
        onChange(tempDate!)
    
    }

    const changeDayPart = (daypart: string) => {
        var tempDate = value
        if (daypart === "am" && tempDate?.getHours()! >  12) {
            tempDate?.setHours(tempDate?.getHours() - 12)
        } else if (daypart === "pm" && tempDate?.getHours()! <  12) {
            tempDate?.setHours(tempDate?.getHours() + 12)
        } else if (daypart === "am" && tempDate?.getHours()! >=  12 && tempDate?.getHours()! <  13) {
            tempDate?.setHours(tempDate?.getHours() - 12)
        }
        onChange(tempDate!)
    }

    const newDate = () => {
        const newDate = new Date(Date.now())
        newDate.setHours(12)
        newDate.setMinutes(0)
        onChange(newDate)
    }

    const getHours = () => {
        if (!value) {
            newDate()
            return 
        }
        const hours = value.getHours()

        if ( hours <= 12 && hours >= 1) {
            return hours.toString()
        } else if ( hours >= 0 && hours <= 1) {
            return "12"
        } else {
            return (hours - 12).toString()
        }
    }

    const getMinutes = () => {
        if (!value) {
            newDate()
            return 
        }
        const minutes = value.getMinutes()

        if (minutes < 30) {
            return "0"
        } else {
            return "30"
        }

    }

    const getDayPart = () => {
        if (!value) {
            newDate()
            return 
        }
    
        const hours = value.getHours()

        if ( hours < 12 ) {
            return "am"
        } else {
            return "pm"
        }

    }

    return (
        <div className={cx(
            "py-1 w-full bg-white rounded-lg shadow-xl",
            className
        )}>
            <div className="flex px-1 md:px-4 justify-between">
                <div className="flex w-full justify-center">
                    <select name="hours" className="bg-transparent appearance-none outline-none text-right w-full md:pr-2" value={getHours()} onChange={(e) => changeHours(e.target.value)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select>
                    <span className="text-xl md:mr-3">:</span>
                    <select name="minutes" className="bg-transparent appearance-none outline-none md:mr-4 w-full text-left" value={getMinutes()} onChange={(e) => changeMinutes(e.target.value)}>
                        <option value="0">00</option>
                        <option value="30">30</option>
                    </select>
                </div>
                <select name="ampm" className="bg-transparent appearance-none outline-none text-left" value={getDayPart()} onChange={(e) => changeDayPart(e.target.value)}>
                    <option value="am">AM</option>
                    <option value="pm">PM</option>
                </select>
            </div>
        </div>
    )
}

export default TimePicker