import React from 'react';
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reducers/rootReducer';
import { setProfile } from '../slices/advisorSlice';
import { StyledError, StyledLabel, StyledSubmit } from '../styled_components/StyledInput';
import Input from '../atoms/Input';
import { useTranslation } from 'react-i18next';

const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Profile = () => {

    const user = useSelector((state: RootState) => state.advisor.currentUser!!)
    const dispatch = useDispatch()

    const [fullName, setFullName] = useState(user.fullName)
    const [email, setEmail] = useState(user.email)
    const [phoneNumber] = useState(user.phoneNumber)
    const [password, setPassword] = useState("")
    const [nameError, setNameError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const { t } = useTranslation();

    const submit = () => {
        if (password.length < 7) {
            setPasswordError("Password has to be at least 8 characters long")
            return
        } else if (!emailRe.test(String(email).toLowerCase())) {
            setEmailError("Please, provide valid mail address")
            return
        } else if (fullName.length < 0) {
            setEmailError("Your full name can not be empty")
            return
        } 

        const data = {
            id: user.id,
            fullName: fullName,
            email: email,
            phoneNumber: phoneNumber,
            password: password,
            profilePicture: ""
        }

        dispatch(setProfile(data))
    }

    return (
            <div className="bg-background w-full h-full">
                <div className="w-screen md:w-3/4 lg:w-2/5 mx-auto flex-grow pb-10 h-full md:h-auto md:mt-20 shadow rounded-xl bg-white pt-16">
                    <div className="flex flex-col items-center">
                        <div className="flex flex-col items-center md:w-3/4">
                            <img id="advisor-pic" alt="advisor" src="user.svg" className="w-32 h-32"/>
                            <StyledLabel>{t("full_name")}</StyledLabel>
                            <Input className="py-3 px-4 w-full rounded-lg" hasError={nameError.length > 0} onBlur={(e) => { fullName.length === 0 ? setNameError("Your full name can not be empty") : setNameError("")}} id="fullName" type="text" name="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="John Doe"/>
                            <StyledError style={{marginBottom: ".25rem"}}>{nameError}</StyledError>
                            <StyledLabel>{t("id_email")}</StyledLabel>
                            <Input  className="py-3 px-4 w-full rounded-lg" hasError={emailError.length > 0} onBlur={(e) => { !emailRe.test(String(email).toLowerCase()) ? setEmailError("Please, provide valid mail address") : setEmailError("")}} id="email" type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="john.doe@erstegroup.com"/>
                            <StyledError style={{marginBottom: ".25rem"}}>{emailError}</StyledError>
                            <StyledLabel>{t("password")}</StyledLabel>
                            <Input className="py-3 px-4 w-full rounded-lg" hasError={passwordError.length > 0} onBlur={(e) => { password.length <= 7 ? setPasswordError("Password has to be at least 8 characters long") : setPasswordError("")}} id="password" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="•••••••••••"/>
                            <StyledError style={{marginBottom: ".25rem"}}>{passwordError}</StyledError>
                            <StyledSubmit id="submit" type="submit" name="submit" value="Save" className="btn btn-primary mt-8" onClick={() => submit()}/>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Profile