import styled from 'styled-components'

const TransactionGroup = styled.div<{selected: boolean}>`
    display: flex;
    width: calc(100%);
    height: ${props => props.selected ? "calc(3rem - 1px)" : "3rem"};
    border-top: ${props => props.selected ? "" : ""};
    border-bottom: ${props => props.selected ? "" : "1px solid #ebe8e8"};
    background-color: ${props => props.selected ? "#EBF8FF":"white"};
    justify-content: space-between;

    a {
        margin-right: 1rem;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }

    .income {
        color: #288000; 
        width: 5rem; 
        text-align: right; 
        margin-right: 1rem
    }

    .expense {
        color: #c4565a; 
        width: 5rem;
        text-align: right; 
        margin-right: 1rem
    }

    .amount {
        width: 5rem; 
        text-align: right; 
        margin-right: 1rem
    }

    @media screen and (max-width: 768px) {
        .income {
            font-size: .85em;
            width: 17vw
        }

        .expense {
            font-size: .85em;
            width: 17vw
        }

        .amount {
            font-size: .85em;
            width: 17vw
        }
    }

`

const DateRange = styled.div`
    justify-self: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: .75rem;
`

const TransactionGroupDetails = styled.table<{align: boolean}>`
    border-collapse: collapse;

    th {
        background-color: #ebf8ff;
        color: #00497b;
        padding: 12px 15px;
    }

    th:first-of-type {
        text-align: left;
    }

    th:last-of-type {
        text-align: right;
    }

    .product-name {
        background-color: #f3f3f3; 
        width: 100%; 
        padding: .75rem 0 .75rem .75rem; 
        display: flex; 
        flex-direction: row; 
        align-items: center;
        flex-wrap: nowrap;

        @media screen and (max-width: 768px) {
            flex-direction: column !important;
            justify-content: space-between !important;
            align-items: center !important;
            width: 100%;
            padding-bottom: 1rem;

            p {
                margin-bottom: .5rem;
            }
        }
    }

    @media screen and (max-width: 768px) {

        th {
            display: none;
        }

        td {
            width: calc(100% - 1.5rem) !important;
            border-radius: 0 !important;
            text-align: ${props => props.align ? "left" : "center"} !important;

            .table-data {
                width: calc(100%) !important;
                border-radius: 6px !important;
                justify-content: center;
                
                .dots-icon {
                    margin-left: 1.5rem;
                }

            }
        }
    }
 

`

const TransactioDetailsRow = styled.tr<{selected: boolean, gray: boolean}>`

    cursor: pointer;


    :nth-of-type(even) {
        background-color: #f3f3f3;
    }


    td {
        //padding: 12px 15px;
        ${props => props.gray ? "border-bottom: 1px solid white":"border-bottom: 1px solid #f3f3f3"};
        overflow-wrap: break-word;
    }

    .date {
        min-width: 5rem;
        width: 10%;
    }

    .org {
        width: 40%;
        text-align: center;
    }

    .cat {
        width: 40%;
        text-align: center;
    }

    .amount {
        min-width: 6rem;
        width: 10%;
        text-align: right;
    }

    @media screen and (max-width: 768px) {

        font-size: .85em;

        td {
            padding: .3rem 0 .1rem 40vw;
            margin: 0;
            display: block;
            position: relative;
            overflow-wrap: break-word;
            hyphens: auto;
            line-break: anywhere;
        }

        td:before {
            width: 40vw;
            position: absolute;
            top: 6px;
		    left: 3.5vw;
        }

        .date {
            width: 60vw;
            min-width: 0;
        }

        .date:before {
            content: "Date: ";
        }

        .org {
            width: 60vw;
            text-align: left;
        }

        .org:before {
            content: "Organization: ";
        }

        .cat {
            text-align: left;
        }

        .cat:before {
            content: "Category: ";
        }

        .amount {
            width: 60vw;
            text-align: left;
        }

        .amount:before {
            content: "Amount: ";
        }
    }
`
const Tag = styled.div`
    height: 1.5rem; 
    background-color: #03497B; 
    border-radius: 6px; 
    display: flex; 
    width: fit-content; 
    padding-left: .5rem; 
    padding-right: .75rem; 
    align-items: center; 
    color: white;
    font-size: 12px;
    margin-right: .5rem;
    margin-top: .25rem;
`


export {TransactionGroup, DateRange, TransactionGroupDetails, TransactioDetailsRow, Tag}