import React from 'react'

type DetailsProp = {
    keys: string[],
    values: string[]
}

const DetailsCard = ({keys, values}: DetailsProp) => {

    const splitCamelCase = (val: string) => {
        if (val === "ico") {
            return "ICO"
        } else if (val === "icDph") {
            return "IC DPH"
        } else if (val === "dic") {
            return "DIC"
        }
        return val
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, function(str){ return str.toUpperCase(); })
    }

    return (
        <div className="bg-gray-300 rounded-lg p-4 shadow-md text-gray-600">
            {
                keys.map((k, index) => (
                    <div className="flex justify-between text-base py-1">
                        <div>{splitCamelCase(k)}</div>
                        <div>{values[index]}</div>
                    </div>
                ))
            }
        </div>
    )

}

export default DetailsCard