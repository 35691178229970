import styled from 'styled-components'

type TableProps = {
    colCount: number;
}

type TableRowProps = {
    colCount: number;
    selected: boolean;
}

const TableBody = styled.tbody`
    width: 100%;
    overflow-y: scroll;
    height: calc(100% - 1rem);
    scrollbar-width: thin;
    

`
const StyledTable = styled.table<TableProps>`
    height: calc(100% - 4rem);
    position: relative;
    top: -1.65em;
    tbody, thead tr { display: block; }

    .col {
        width: ${props => 100/props.colCount + "%"};
    }
`

const TableHeader = styled.div`
    width: 100%;
    z-index: 1;
    font-size: 12px;
    color: #005894;
    text-transform: capitalize;
    text-align: left;

    .col {
        padding-bottom: 1vw;
    }

    .checkbox {
        margin: 0;
        padding: 0;
    }

    input[type = 'checkbox'] {
        margin: .1vh .7vw 0 1vw;
    }

    .sort-button {
        margin-left: 1vw;
    }

    .reversed {
        transform: rotate(-180deg);
    }

    @media only screen and (max-width: 768px) {
        justify-content: space-between;
        width: 98vw;
    }
`

const TableRow = styled.tr<TableRowProps>`
    width: calc(100% - 6vw);
    font-size: 18px;
    margin-left: 5vw;
    word-wrap: break-word;
    text-align: left;
    background-color: ${props => props.selected ? "#bce4fa":"white"};

    input[type = 'checkbox'] {
        margin: .1vh .7vw .2vh 1vw;
    }

    .col {
        padding-bottom: 1vh;
        padding-top: 1vh;
    }

    .col:first-of-type {
        text-align: left;
    }




    .dir {
        font-size: 14px;
        margin-left: .15vw;
        margin-right: .15vw;
        border-radius: 6px;
        padding: .5vh .5vw .5vh .5vw;
        display: inline-block;
    }
    
    .dir:nth-of-type(1) {
        background-color: #bce4fa;
        color: #00497b;
    }
    .dir:nth-of-type(2) {
        background-color: #00497b;
        color: white;
    }
    .dir:nth-of-type(3) {
        background-color: #e30613;
        color: white;
    }

    @media only screen and (max-width: 768px) {
        justify-content: space-between;
        width: 98vw;
    }
`

export {TableRow, TableHeader, TableBody, StyledTable}