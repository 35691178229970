import React, { useEffect, useState } from "react";
import ClientList from "../components/client/ClientList";
import ClientInfo from "../components/client/ClientInfo";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadClientList } from "../slices/clientSlice";
import { useMediaQuery } from "react-responsive";

const Clients = () => {
  const [clientsHidden, setClientsHidden] = useState(false);
  const [clientInfoHidden, setClientInfoHidden] = useState(true);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadClientList());
  }, [dispatch]);

  useEffect(() => {
    if (isTabletOrMobile) {
      setClientInfoHidden(true);
    } else {
      setClientInfoHidden(false);
    }
  }, [isTabletOrMobile]);

  const handleClientChange = () => {
    setClientInfoHidden(false);
    if (window.innerWidth < 1000 && window.innerWidth > 768) {
      setClientsHidden(true);
    }
  };

  const hideClients = (hidden: boolean) => {
    setClientsHidden(true)
    setClientInfoHidden(false)
  }

  const hideClientInfo = (hidden: boolean) => {
    setClientInfoHidden(true)
    setClientsHidden(false)
  }

  return (
      <div className="flex sm:h-full sm:overflow-y-hidden w-full sm:max-h-full flex-col md:flex-row">
        <ClientList
          setClient={handleClientChange}
          setClientsHidden={hideClients}
          className={`sm:w-80 sm:mr-6 md:w-1/4 xl:w-1/5 full:w-1/6 flex-shrink h-screen ${clientsHidden ? "md:hidden xl:block" : ""}`}
        />
        <ClientInfo
          setClientInfoHidden={hideClientInfo}
          className={`sm:w-80 sm:mr-6 md:w-1/4 xl:w-1/5 full:w-1/6 flex-shrink  ${clientInfoHidden ? "md:hidden xl:block" : ""}`}
        />
        <div className="flex-grow h-screen md:h-98 sm:w-3/5">
          <Outlet />
        </div>
      </div>
  );
};

export default Clients;
