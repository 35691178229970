import styled from 'styled-components'

const StyledInput = styled.input<{error?: boolean}>`
    border-radius: 4px;
    border: 2px solid ${props => props.error ? "#cc000d" :"#b9bfc8"};
    width: calc(100% - 1.4rem);
    margin-bottom: 2vh;
    padding: .8rem 0.6rem;
    font-size: 16px;

    &:focus {
        outline: none;
        border: 2px solid black;
    }

    @media only screen and (max-width: 768px) {
            height: 2.5rem;
            width: 90vw;        
    }
`

const StyledLabel = styled.div`
    width: 100%;
    color: #636e85;
    font-size: .75rem;
    line-height: 1.4rem;
    font-weight: 600;
`

const StyledSubmit = styled.input`
    width: 50%;
    height: 2.5rem;
    max-width: 100%;
    border: none;
    margin-bottom: 2vh;
    border: 1px solid #00497b;
    border-radius: 6px;
    background-color: #00497b;
    color: white;
    font-size: 1.1rem;
    align-self: flex-end;

    &:active {
        background-color: #00365c
    }

    @media only screen and (max-width: 768px) {
        height: 2.5rem;
        width: 92.5vw;
        align-self: center;
        margin: 0;
    }
`

const StyledError = styled.div`
    width: 100%;
    color: #cc000d;
    font-size: 12px;
    line-height: 13px;
    height: 1rem;
`

export {StyledInput, StyledLabel, StyledSubmit, StyledError}