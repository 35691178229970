import { ResponsivePie } from "@nivo/pie";
import React from "react";
import { v4 as uuid } from "uuid";

export type PieDataPoint = {
  id: number;
  label: string;
  value: number;
  color?: string;
};

type PieWrapperProps = {
  data: PieDataPoint[];
  color?: boolean;
  bottomMargin?: number;
  topMargin?: number;
  rightMargin?: number;
  leftMargin?: number;
  showLegend?: boolean
};

const LineWrapper = ({ data, color = false, showLegend = false, topMargin = 20, rightMargin = 50, bottomMargin = 55, leftMargin = 50 }: PieWrapperProps) => {
  return (
    <div className="relative w-full h-full">
      <div className="absolute w-full h-full">
        <ResponsivePie
          key={uuid()}
          margin={{ top: topMargin, right: rightMargin, bottom: bottomMargin, left: leftMargin }}
          data={data}
          innerRadius={0.5}
          fit={true}
          enableArcLabels={!showLegend}
          enableArcLinkLabels={!showLegend}
          arcLabel={!showLegend ? "value" : undefined}
          tooltip={({ children, datum }) => (
            <tspan
              style={{
                backgroundColor: "white",
                border: "1px solid #C6C3BD",
                height: "2rem",
                borderRadius: 3,
                padding: 3,
                boxShadow: "box-shadow: -3px 5px 19px 0px rgba(0,0,0,0.2)",
              }}
            >
              {datum.label}: {Math.round(datum.value * 100) / 100 + " €"}
            </tspan>
          )}
          colors={color ? undefined : data.map((d) => d.color!)}
          cornerRadius={3}
          activeOuterRadiusOffset={2}
          onClick={(node, e) => console.log(node)}
          arcLinkLabel={!showLegend ? (d) => d.label + "" : undefined}
          arcLinkLabelsSkipAngle={!showLegend ? 10 : undefined}
          arcLinkLabelsTextColor={!showLegend ? "#333333" : undefined}
          arcLinkLabelsThickness={!showLegend ? 2 : undefined}
          theme={{
            labels: {
              text: { width: "1rem", wordWrap: "break-word" },
            },
          }}
          arcLinkLabelsColor={!showLegend ? { from: "color" } : undefined}
          arcLabelsSkipAngle={!showLegend ? 10 : undefined}
          arcLabelsTextColor={!showLegend ? { from: "color", modifiers: [["darker", 2]] } : undefined}
          legends={showLegend ? [
            {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 40,
                translateY: -1,
                itemWidth: 20,
                itemHeight: 30,
                itemsSpacing: 0,
                symbolSize: 20,
                itemDirection: 'left-to-right'
            }
        ]: []}
        />
      </div>
    </div>
  );
};

export default LineWrapper;
