import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import { v4 as uuid } from "uuid";

export type BarGraphDataPoint = {
  x: string;
  y: number;
};

type BarWrapperProps = {
  data: BarGraphDataPoint[];
  label: string;
  bottomMargin?: number;
  topMargin?: number;
  rightMargin?: number;
  leftMargin?: number;
};

const BarWrapper = ({ data, label, bottomMargin = 45, rightMargin = 15, leftMargin = 45, topMargin = 15 }: BarWrapperProps) => {
  const getTickValues = () => {
    if (!data) return 0;

    const n = 15.0;
    const frequency = Math.ceil(data?.length / n);

    return data.map((m) => m.x).filter((m, index) => index % frequency === 0);
  };

  return (
    <div className="relative w-full h-full">
      <div className="absolute w-full h-full">
        <ResponsiveBar
          key={uuid()}
          margin={{ top: topMargin, right: rightMargin, bottom: bottomMargin, left: leftMargin }}
          colors={"#0F497B"}
          data={data}
          indexBy={(d: any) => d.x}
          enableLabel={false}
          axisBottom={{
            tickValues: getTickValues(),
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 25,
          }}
          axisLeft={{ tickPadding: 0 }}
          keys={["y"]}
          tooltip={({ id, value, color }) => (
            <div className="bg-white rounded-lg px-5 py-3 flex flex-col">
            <div className="text-gray-700 text-sm">
              {}
            </div>
            <div>
              {label}:{" "}
              {value + " €"}
            </div>
          </div>
          )}
        />
      </div>
    </div>
  );
};

export default BarWrapper;
