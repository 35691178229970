import {
  faInfinity,
  faHistory,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import {
  ModalBody,
  ModalTable,
} from "../../styled_components/StyledModal";
import Modal from "../../atoms/Modal";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Note from "../../types/Note";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { editClientNote, loadClientNotes } from "../../slices/noteSlice";
import { Editor } from "@tinymce/tinymce-react";

import NotesService from "../../services/NotesService";
import Button from "../../atoms/Button";
import { useTranslation } from "react-i18next";

type NoteProps = {
  setSelectedNote: (i: number) => void;
  isOpen: boolean;
  note?: Note;
};

const NotesModal = ({ note, setSelectedNote, isOpen }: NoteProps) => {
  const [newNoteText, setNewNoteText] = useState<string>("");
  const [newNoteVisibility, setNewNoteVisibility] = useState(
    note ? note.appUserVisibility!! : false
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [inifinityToggled, setInfinityToggled] = useState(false);
  const [recurring, setRecurring] = useState("ONCE");
  const [toggleHistory, setToggleHistory] = useState(false);
  const [state, setState] = useState("");
  const {t} = useTranslation()

  const client = useSelector(
    (state: RootState) => state.clients.selectedClient.id
  );
  const user = useSelector(
    (state: RootState) => state.advisor.currentUser!!.id
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (note) {
      var state = note!!.notePeriods!![note!!.notePeriods!!.length - 1].state;
      setState(state);
      setNewNoteText(note!!.text!!);
      setStartDate(new Date(note!!.intervalFrom!!));
      setEndDate(new Date(note!!.intervalTo!!));
      setRecurring(note.type!!);

      if (note.intervalTo === null) {
        setInfinityToggled(true);
      }
    }
  }, [note]);

  const updateNote = () => {
    var newNote = JSON.parse(JSON.stringify({ ...note }));
    newNote.notePeriods!![newNote!!.notePeriods!.length - 1].state = state!!;
    var text = newNoteText.split('<p data-f-id="pbf"');

    if (text.length > 1) {
      text.pop();
    }
    newNote.text = text.join();
    newNote.appUserVisibility = newNoteVisibility;

    dispatch(editClientNote(newNote));
    setSelectedNote(-2);
  };

  const createNote = () => {
    NotesService.createNote(
      newNoteText,
      newNoteVisibility,
      user,
      client,
      startDate,
      inifinityToggled ? null : endDate,
      recurring
    )
      .then((res) => {
        dispatch(loadClientNotes(client));
        setSelectedNote(-2);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      className="max-h-50-rem px-4 md:px-12 md:max-w-3xl"
      buttons={
        <>
          <Button
            className="w-full sm:w-32 btn btn-primary mb-3 md:mb-0"
            bgColor=" "
            value={t('save')}
            onClick={
              note === undefined ? () => createNote() : () => updateNote()
            }
          />
          {note !== undefined && (
            <div
              onClick={() => setToggleHistory(!toggleHistory)}
              className="w-full sm:w-32 border rounded-lg ring-primary-dark flex items-center pl-2 cursor-pointer py-3 justify-center sm:justify-start sm:mr-4"
            >
              {toggleHistory ? <>
              <div className="w-8 ml-2">
                <FontAwesomeIcon
                  size="lg"
                  icon={faAngleLeft}
                  className="text-primary cursor-pointer"
                />
              </div>
              <div >{t('back')}</div></>
              :<>
               <div className="w-8 ml-2">
              <FontAwesomeIcon
                size="lg"
                icon={faHistory}
                className="text-primary w-8 cursor-pointer"
              />
              </div>
              <div >{t('history')}</div></>
              }
            </div>
          )}
        </>
      }
      onClose={() => setSelectedNote(-2)}
      header={note === undefined ? "New Note" : "Edit note"}
    >
      {!toggleHistory ? (
        <div className="w-full">
          <div className="w-full">
            <div className="w-full flex mt-4 justify-between">
              <div className="w-11/12 mr-2">
                <label htmlFor="start-date" style={{ margin: "auto 0 auto 0" }}>
                  {t('start_date')}
                </label>
                <div className="w-full">
                  <DatePicker
                    wrapperClassName="w-full"
                    className="w-full mb-4 h-12 border-2 rounded-lg pl-4"
                    disabled={note !== undefined}
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                  />
                </div>
              </div>
              <div className="w-11/12">
                <label htmlFor="end-date" style={{ margin: "auto 0 auto 0" }}>
                  {t('end_date')}
                </label>
                <div className="w-full relative">
                  <DatePicker
                    wrapperClassName="w-full"
                    className="w-full mb-4 h-12 border-2 rounded-lg pl-4"
                    disabled={note !== undefined || inifinityToggled}
                    id="end-date"
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                  />
                  <FontAwesomeIcon
                    size="lg"
                    icon={faInfinity}
                    className={`absolute right-4 top-3 ${
                      note === undefined ? "pointer" : ""
                    }`}
                    color={inifinityToggled ? "#00497b" : "grey"}
                    onClick={(e) =>
                      note === undefined
                        ? setInfinityToggled(!inifinityToggled)
                        : {}
                    }
                  />
                </div>
              </div>
            </div>
            {note !== undefined && (
              <div className="w-full flex flex-col">
                <label htmlFor="state">{t('state')}</label>
                <select
                  name="state"
                  id="state"
                  className="rounded-md border-2 h-12 px-3"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value="ONGOING">{t('ongoing')}</option>
                  <option value="PARTIALLYFULFILLED">
                  {t('partially_fulfilled')}
                  </option>
                  <option value="FULFILLED">{t('fulfilled')}</option>
                  <option value="UNFULFILLED">{t('not_fulfilled')}</option>
                </select>
              </div>
            )}
            <div className={`w-full flex flex-col md:flex-row items-start md:justify-between md:h-12 md:items-center mb-4 ${note !== undefined ? "mt-4" : "mt-0"}`}>
              <div>
                <label
                  htmlFor="type-select"
                  style={{ margin: "auto 1.4rem auto 0" }}
                >
                  {t('recurrence')}
                </label>
              </div>
              <div className="btn-group w-full md:w-max">
                <button
                  disabled={note === undefined ? false : true}
                  onClick={() => setRecurring("ONCE")}
                  className={`btn btn-outline btn-md w-1/3 md:w-auto ${recurring === "ONCE" ? "btn-active " : ""}`}
                >
                  {t('once')}
                </button>
                <button
                  disabled={note === undefined ? false : true}
                  onClick={() => setRecurring("WEEKLY")}
                  className={`btn btn-outline btn-md w-1/3 md:w-auto  ${recurring === "WEEKLY" ? "btn-active " : ""}`}
                >
                  {t('weekly')}
                </button>
                <button
                  disabled={note === undefined ? false : true}
                  onClick={() => setRecurring("MONTHLY")}
                  className={`btn btn-outline btn-md w-1/3 md:w-auto  ${recurring === "MONTHLY" ? "btn-active " : ""}`}
                >
                  {t('monthly')}
                </button>
              </div>
              
            </div>
            <div className="notes-row">
              <div style={{ width: "100%" }}>
                <Editor
                  value={newNoteText}
                  apiKey="iybyrbq61s8mgl7u68hgszm2sy9pu2vayv5ph08p4n7oncpk"
                  init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link",
                      "charmap print preview anchor help",
                      "searchreplace visualblocks code",
                      "insertdatetime table paste wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic | \
                                        alignleft aligncenter alignright | \
                                        bullist numlist outdent indent | help",
                  }}
                  onEditorChange={(newValue, editor) =>
                    setNewNoteText(newValue)
                  }
                />
              </div>
            </div>
            <div className="flex justify-between mt-2">
              <div>{t("allow_clients_note_msg")}</div>
              <input
                className="w-4 h-4"
                type="checkbox"
                checked={newNoteVisibility}
                onChange={(e) => setNewNoteVisibility(e.target.checked)}
              />
            </div>
          </div>
        </div>
      ) : (
        <ModalBody style={{ height: "calc(100% - 4rem)", overflow: "auto" }}>
          <div
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginTop: "1rem",
              display: "flex",
              height: "100%",
            }}
          >
            <ModalTable>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('start')}</th>
                  <th>{t('e')}</th>
                  <th>{t('state')}</th>
                </tr>
              </thead>
              <tbody>
                {[...note!!.notePeriods].reverse().map((d, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{d.start}</td>
                    <td>{d.end}</td>
                    <td>
                      {d.state[0].toUpperCase() +
                        d.state.toLowerCase().slice(1)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </ModalTable>
          </div>
        </ModalBody>
      )}
    </Modal>
  );
};

export default NotesModal;
