import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../slices/advisorSlice';

const Logout = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logout())
    }, [dispatch])

    return (
        <></>
    );
}

export default Logout;
