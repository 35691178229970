import React, { useEffect, useRef, useState } from "react";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import Modal from "../../atoms/Modal";
import { faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import DocumentData, { Attachment } from "../../types/DocumentData";
import DocumentService from "../../services/DocumentService";
import {
  createNewFolder,
  deleteAttachment,
  editFile,
} from "../../slices/documentSlice";
import { Editor } from "@tinymce/tinymce-react";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import FileRow from "../../atoms/FileRow";
import { useTranslation } from "react-i18next";

type DocumentModalProps = {
  parentId?: number;
  document?: DocumentData;
  isOpen: boolean;
  isInShared?: boolean;
  onClose: () => void;
  setRef: (ref: React.RefObject<HTMLDivElement>) => void;
  documentReload: () => void;
};

const DocumentModal = ({
  parentId,
  document,
  onClose,
  isOpen,
  isInShared = false,
  setRef,
  documentReload,
}: DocumentModalProps) => {
  const [newDocumentName, setNewDocumentName] = useState("");
  const [newNoteText, setNewNoteText] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState([] as File[]);
  const [fileNameList, setFileNameList] = useState<(Attachment)[]>([]);
  const ref = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const {t} = useTranslation()

  const profile = useSelector(
    (state: RootState) => state.clients.selectedClient
  );

  const currFolder = useSelector(
    (state: RootState) => state.documents.currentNode
  );
  const loading = useSelector(
    (state: RootState) => state.documents.isUploading
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (document) {
      setNewDocumentName(document.name);
      setNewNoteText(document.note);
      if (document.attachments) {
        setFileNameList(
          document.attachments.map((a) => ({
            name: a.name,
            id: a.id,
            delete: false,
          }))
        );
      }
      setIsChecked(document.visibleClient);
    }

    return () => {
      setNewDocumentName("");
      setNewNoteText("");
      setSelectedFiles([]);
      setFileNameList([]);
      setIsChecked(false);
    };
  }, [document]);

  useEffect(() => {
    if (loading === false && isUploading) {
      onClose();
      setIsUploading(false)
    }
  }, [loading])

  useEffect(() => {
    setRef(ref);
  }, [ref, setRef]);

  const download = (id: number, filename: string) => {
    DocumentService.downloadFile(profile.id, id, filename);
  };

  const addToDeleted = (id: number) => {
    var temp = [...fileNameList];
    var edited = temp.find((f) => f.id === id)!!;
    edited.delete = true;
    setFileNameList(temp);
  };

  const removeFromDeleted = (id: number) => {
    var temp = [...fileNameList];
    var edited = temp.find((f) => f.id === id)!!;
    edited.delete = false;
    setFileNameList(temp);
  };

  const modifyDocument = () => {
    if (document !== undefined) {
      if (fileNameList.length > 0) {
        fileNameList
          .filter((f) => f.delete)
          .forEach((f) =>
            dispatch(deleteAttachment(profile.id, f.id, currFolder))
          );
      }
      setIsUploading(true)
      dispatch(
        editFile(
          profile.id,
          currFolder,
          {
            ...document!!,
            name: newDocumentName,
            note: newNoteText,
            visibleAdvisor: true,
            visibleClient: isChecked,
          },
          selectedFiles
        )
      );
    }
  };

  const createDocument = () => {
    setIsUploading(true)
    dispatch(
      createNewFolder(
        profile.id,
        currFolder,
        {
          parentId: parentId!!,
          name: newDocumentName,
          note: newNoteText,
          visibleAdvisor: true,
          visibleClient: isChecked,
          folder: false,
        },
        selectedFiles
      )
    );
  };



  const submitAttachmentChanges = (f: any, val: string) => {
    const attachment: Attachment = {
      id: f.id,
      name: val,
    }

    DocumentService.editAttachment(attachment)
      .then(a => {
        var temp = fileNameList.filter(file => file.id !== f.id)
        a.delete = false
        temp = [...temp, a]
        setFileNameList(temp)
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      isLoading={loading}
      className="min-h-40-rem max-h-50-rem px:6 md:px-12 md:max-w-3xl"
      buttons={
        <Button
          className="w-full sm:w-1/2 md:w-40 mr-3"
          bgColor="bg-primary"
          value="Save"
          onClick={
            document === undefined
              ? () => createDocument()
              : () => modifyDocument()
          }
        />
      }
      onClose={() => onClose()}
      header={
        document === undefined ? t("new_document") + "" : t("document") + " " + document.name
      }
    >
      <div className="w-full overflow-y-auto">
        <div className="w-full px-3">
          <Input
            type="text"
            disabled={isInShared}
            className="w-full mt-8 mb-4 border-gray-300 py-4 pl-4 rounded-lg"
            placeholder={t("document_name")}
            value={newDocumentName}
            onChange={(e) => setNewDocumentName(e.target.value)}
          />
          <Editor
            value={newNoteText}
            disabled={isInShared}
            apiKey="iybyrbq61s8mgl7u68hgszm2sy9pu2vayv5ph08p4n7oncpk"
            init={{
              height: 300,
              resize: false,
              menubar: false,
              plugins: [
                "advlist autolink lists link",
                "charmap print preview anchor help",
                "searchreplace visualblocks code",
                "insertdatetime table paste wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic | \
                                        alignleft aligncenter alignright | \
                                        bullist numlist outdent indent | help",
            }}
            onEditorChange={(newValue, editor) => setNewNoteText(newValue)}
          />
          <div className="w-full flex justify-between items-center mt-4">
            {!isInShared &&<> <div className="text-sm">{t("allow_clients_msg")}</div>
            <input
              type="checkbox"
              checked={isChecked}
              onClick={() => setIsChecked(!isChecked)}
              className="w-5 h-5 bg-primary "
            /></>}
          </div>
          <div
            className="mt-4"
            style={{
              height:
                fileNameList.length + selectedFiles.length <= 2
                  ? "10.5rem"
                  : (fileNameList.length + selectedFiles.length + 1) * 2.5 +
                    (fileNameList.length + selectedFiles.length) +
                    "rem",
            }}
          >
            {
              <>
                <div>
                  {fileNameList.map((f, index) => (
                    <FileRow
                      className="mb-2"
                      isInShared={isInShared}
                      name={f.name}
                      onSubmit={(val) => {submitAttachmentChanges(f, val)}}
                      onRemove={() =>
                        f.delete ? removeFromDeleted(f.id) : addToDeleted(f.id)
                      }
                      onClick={() => download(f.id, f.name)}
                    />
                  ))}
                </div>
                <div>
                  {selectedFiles.map((f, index) => (
                    <div
                      className={`w-full text-sm bg-grey-primary mt-4 h-10 rounded-lg flex px-4 justify-between items-center cursor-pointer`}
                    >
                      <div>{f.name}</div>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={(e) => {
                          e.stopPropagation();
                          var sf = [...selectedFiles];
                          sf.splice(index, 1);
                          setSelectedFiles(sf);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            }
            {(selectedFiles.length <= 5 && !isInShared) && (
              <div
                className={`w-full text-sm bg-gray-300 mt-4 h-10 rounded-lg flex px-4 justify-between items-center cursor-pointer`}
              >
                <FontAwesomeIcon color={"#575757"} icon={faUpload} />
                <label
                  className="w-full h-full cursor-pointer p-0 ml-4 text-sm flex items-center "
                  htmlFor="file-upload"
                >
                  {t("add_attachment")}
                </label>
                <input
                  style={{
                    visibility: "hidden",
                    margin: 0,
                    padding: 0,
                    cursor: "pointer",
                  }}
                  id="file-upload"
                  type="file"
                  value=""
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      var sf = [...selectedFiles];
                      sf.push(e.target.files[0]);
                      setSelectedFiles(sf);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentModal;
