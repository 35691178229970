import React from "react";

const InfoRow = ({
  description,
  value,
}: {
  description: string;
  value: string;
}) => {
  return (
    <div className="flex justify-between text-sm mt-2">
      <div>{description}</div>
      <div>{value}</div>
    </div>
  );
};

export default InfoRow;
