import cx from "classnames";
import { TableRow } from "./TableRow";

export interface TableProps {
  className?: string;
  columns?: { field: string; header: string }[];
  rows?: any[];
}

export const Table = ({
  className,
  rows = [],
  columns = [],
}: TableProps) => {
  return (
    <table
      className={cx("w-full border-separate max-w-full overflow-x-scroll ", className)}
      style={{ borderSpacing: "0 16px" }}
    >
      <thead>
        <tr>
          {columns.map((col, index) => (
            <th key={index} className="text-left text-font pl-3 md:pl-5">
              {col.header}
            </th>
          ))}
          <th></th>
        </tr>
      </thead>

      <tbody>
        {rows?.map((row, index) => (
          <TableRow
            className="bg-white w-max"
            key={index}
            tableCells={columns.map((col) => ({
              content: row[col.field],
            }))}
          />
        ))}
      </tbody>
    </table>
  );
};

export default Table;