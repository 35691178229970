import React, { ReactChild, ReactChildren } from "react";
import Button from "../../atoms/Button";
import Modal from "../../atoms/Modal";

type DialogProps = {
  type: string;
  content: string | ReactChild | ReactChildren;
  color: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const Dialog = ({
  type,
  content,
  color,
  onConfirm,
  onCancel,
  isOpen,
}: DialogProps) => {
  const getHeader = () => {
    var slicedWord = type.slice(1);
    return type[0].toLocaleUpperCase() + slicedWord;
  };

  return (
    <Modal
      header={getHeader()}
      isOpen={isOpen}
      className="max-w-2xl max-h-20-rem md:max-h-60 p-0"
      onClose={onCancel}
      buttons={
        <div className="flex flex-col md:flex-row-reverse mb-10 md:mb-0">
          <Button
            type={"submit"}
            onClick={() => onConfirm()}
            value={"Confirm"}
            bgColor={color === "red" ? "bg-red-500" : "bg-green-500"}
          />
          <Button
            className="mt-3 md:mt-0 md:mr-3"
            type={"submit"}
            onClick={() => onCancel()}
            value={"Cancel"}
          />
        </div>
      }
    >
      <div className="h-full felx justify-between">
        {content}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            alignItems: "flex-end",
            marginBottom: ".75rem",
            marginTop: ".75rem",
          }}
        ></div>
      </div>
    </Modal>
  );
};

export default Dialog;
