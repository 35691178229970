type Dict = {[key: string]: any}
const colors : Dict = {
    "Bývanie a energie": "#333598",
    "Stravovanie": "#ffcc00",
    "Zdravie a osobná starostlivosť": "#dff2fd",
    "Oblečenie": "#60CAD0",
    "Elektronika, komunikácia a média" : "#ffffff",
    "Zábava a oddych": "#744574",
    "Vzdelanie": "#475E19",
    "Auto-moto": "#C80621",
    "Doprava": "#E9F83C",
    "Cestovanie": "#E24586",
    "Výber hotovosti": "#FEC82E",
    "Nákupy online": "#0E64C3",
    "Sporenie": "#5D9500",
    "Vreckové a výživné": "#2589B5",
    "Dane": "#E97007",
    "Poplatky": "#205372",
    "Ostatné": "#205372",
    "Nezaradené": "#9A9A9A",
    "Nezatriedené výdavky": "#9A9A9A",
} 

export default colors