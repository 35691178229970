import { TCategory } from './../types/TransactionDetails';
import { ProductCategory } from './../types/Statistics';
import BasicClient from '../types/BasicClient';
import ClientDetails from '../types/ClientDetails';
import UserStatistics from '../types/Statistics';
import TOverview from '../types/TOverview';
import TransactionData from '../types/TransactionData';
import getAxios from './api'
import TTransactionImage from '../types/TTransactionImage';

const FileDownload = require('js-file-download');

const ClientService =  {
    removeClientFromAdvisor: async (clientId: number) => {
        console.log(clientId)
        var res = await getAxios().delete('advisor/clients/' + clientId)
        if (res.status === 200) {
            return true
        } else if (res.status === 401) {
            throw Error("User not authorized")
        } else {
            throw Error("An error occured")
        }
    },
    addClientToAdvisor: async (clientId: number) => {
        var res = await getAxios().post('advisor/clients/' + clientId)
        if (res.status === 200) {
            return true
        } else if (res.status === 401) {
            throw Error("User not authorized")
        } else {
            throw Error("An error occured")
        }
    },
    getClientList: async () => {
        var clients = await getAxios().get('advisor/clients')
        return clients.data as BasicClient[]
    },
    getClientInfo: async (clientId: number) => {
        var res = await getAxios().get('advisor/clients/' + clientId)
        if (res.status === 200) {
            let stats = res.data.stats
            let profile = res.data.profile
            
            let retrievedProfile: ClientDetails = {
                id: profile.id,
                name: profile.fullName,
                advisorId: profile.advisorId,
                last_update: profile.modifiedAt.slice(0,10).split('-').reverse().join('/'),
                created_at: profile.createdAt,
                advisor_name: profile.advisor_name,
                account_balance: stats.totalIncome - stats.totalExpense,
                incomePastMonth: stats.pastMonthIncome,
                expensesPastMonth: stats.pastMonthExpenses,
                warningMessage: stats.warningMessage,
                firstTransactionDate: stats.firstTransactionDate
            }
            return retrievedProfile
        } else if (res.status === 401) {
            throw Error("User not authorized")
        } else {
            throw Error("An error occured")
        }
    },
    getTransactions: async (clientId: number) => {
        var data
        if (clientId >= 0) {
            var res = await getAxios().get('advisor/clients/' + clientId + '/transactions')
            data = res.data
        } else {
            data = []
        }
 
        return data.map((d: TransactionData) => ({
            id: d.id,
            organization_name: d.organizationName,
            category: d.category,
            date: d.date ? d.date.split(' ').length > 1 ? d.date.split(' ')[0] : d.date.split('-').reverse().join('.'): null,
            amount: d.price,
            splitCreatorName: d.splitCreatorName,
            splitPeopleCount: d.splitPeopleCount,
            splitTotalPrice: d.splitTotalPrice,
            categories: d.productCategories
        }))
    },
    getTransactionDetails: async (transactionId: number) => {
        var res = await getAxios().get('transactions/' + transactionId)

        var sortedItems = res.data.fsReceipt.productItems.sort((a: any,b: any) => a.quantity*a.product.price > b.quantity*b.product.price ? -1 : 1)
        res.data.fsReceipt.productItems = sortedItems

        console.log(res.data)

        return res.data

    },
    getClientOverview: async (clientId: number) => {
        var res = await getAxios().get('advisor/clients/' + clientId + '/overview',)
        console.log(res.data)
        return res.data as TOverview
    },
    getCSVTransactions: async (clientId: number) => {
        var res = await getAxios()({
            url: 'advisor/clients/' + clientId + '/transactions-csv',
            method: 'GET',
            responseType: 'blob', 
        })
        await FileDownload(res.data, 'tranactions-user-' + clientId + '.csv');
    },
    getClientStatistics: async (clientId: number) => {
        if (clientId >= 0) {
            var res = await getAxios().get('advisor/clients/' + clientId + '/statistics')

            if (res.status === 401) {
                throw Error("Unauthorized")
            } else if (res.status === 500) {
                throw Error("Server error")
            }
            var stats = res.data as UserStatistics
            return stats
        } else {
            return {} as UserStatistics
        }
    },
    getProductCategories: async () => {
        var res = await getAxios().get('/product-categories')

        return res.data as TCategory[]
    },
    getTransactionImages: async (transactionId: number) => {
        var res = await getAxios().get('/transaction-images?transactionId=' + transactionId)

        return res.data as TTransactionImage[]
    },
    deleteImage: async (imageId: number) => {
        var res = await getAxios().delete('/transaction-images/' + imageId)
    },
    updateProductCategory: async (productId: number, category: string) => {
        return await getAxios().put('/products/category', {
            id: productId,
            category: category
        })
    },
    reloadCategories: async (fsReceiptId: number) => {
        return await getAxios().put('/advisor/transactions/' + fsReceiptId + '/recategorize') 
    }
}

export default ClientService