import Note from '../types/Note'
import getAxios from './api'

const NotesService =  {
    getNotes: async (clientId: number) => {
        var notes = await getAxios().get('notes/' + clientId)
        return notes.data as Note[]
    },
    updateNote: async (note: Note) => {
        await getAxios().put('notes', note)
    },
    createNote: async (text: string,
                       appUserVisibility: boolean,
                       createdById: number,
                       createdForId: number,
                       intervalFrom: Date,
                       intervalTo: Date | null,
                       type: string) => {

        var formatedIntervalFrom = intervalFrom.toISOString().split('T')[0]
        var formatedIntervalTo = intervalTo?.toISOString().split('T')[0]
        
        await getAxios().post('notes', { text, appUserVisibility, intervalFrom: formatedIntervalFrom, intervalTo: formatedIntervalTo, createdById, createdForId, type })
    },
    deleteNote: async (noteId: number) => {
        var res = await getAxios().delete('notes/' + noteId)
        return res
    }
}

export default NotesService