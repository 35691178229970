import { faMinusCircle, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import Select from "react-dropdown-select"
import { StyledError } from "../styled_components/StyledInput"
import Condition from "../types/tags/Condition"
import ProductCategory from "../types/tags/ProductCategory"
import Rule from "../types/tags/Rule"
import Property from "../types/tags/Property"
import TagService from "../services/TagService"
import TimePicker from "../atoms/TimePicker"
import Input from "../atoms/Input"
import Button from "../atoms/Button"
import { useTranslation } from "react-i18next"


const RuleList = ({ ruleList, setRuleList, properties, categories, productCategories, userSpecific, userId, onReload }: { ruleList: Rule[], setRuleList: (rules: Rule[]) => void, properties: Property[], categories: string[], productCategories: ProductCategory[], userSpecific: boolean, userId?: number, onReload: () => void }) => {
    const [expanded, setExpanded] = useState(-1)
    const [newTagName, setNewTagName] = useState("")
    const [newConditions, setNewConditions] = useState([] as Condition[])
    const { t } = useTranslation();

    const saveNewRule = () => {
        const newRule: Rule = {
            tag: {
                name: newTagName
            },
            conditions: newConditions.map(c => ({
                ...c,
                property: {
                    name: c.property.name,
                    type: c.property.type.toUpperCase()
                }
            })),
            userSpecific: userSpecific
        }

        if (userId) {
            TagService.createUserRule(userId, newRule)
                .then(() => { onReload(); setNewConditions([]) })
                .catch(e => console.log(e))
        } else {
            TagService.createRule(newRule)
                .then(() => { onReload(); setNewConditions([]) })
                .catch(e => console.log(e))
        }
    }

    const changeRule = (ruleId: number) => {
        var changedRule = { ...ruleList.find(r => r.id === ruleId)!! }
        var conditions = [...changedRule.conditions]

        conditions.push(...newConditions)
        changedRule.conditions = conditions
        changedRule.tag = { ...changedRule.tag }

        TagService.modifyRule(changedRule)
            .then(() => { onReload(); setNewConditions([]) })
            .catch((e) => console.log(e))
    }

    const deleteCondition = (conditionId: number) => {
        TagService.deleteCondition(conditionId)
            .then(() => { onReload(); setNewConditions([]) })
            .catch((e) => console.log(e))
    }

    const deleteRule = (ruleId: number) => {
        TagService.deleteRule(ruleId)
            .then(() => { onReload(); setNewConditions([]) })
            .catch(e => console.log(e))
    }

    const changeNewConditionValues = (values: Condition, index: number) => {
        var conditions = [...newConditions]
        conditions[index] = values
        setNewConditions(conditions)
    }

    const changeConditionValues = (ruleId: number, values: Condition, index: number) => {
        var tempRuleList = [...ruleList]
        var rule = tempRuleList.find(r => r.id === ruleId)!!
        var conditions = [...rule.conditions]
        conditions[index] = values
        rule.conditions = conditions
        setRuleList(tempRuleList)
    }

    const changeNewConditionCategories = (rule: Condition, index: number, categories: ProductCategory[]) => {
        var conditions = [...newConditions]
        conditions[index].productCategories = categories
        setNewConditions(conditions)
    }

    const changeConditionCategories = (ruleId: number, conditionId: number, categories: ProductCategory[]) => {
        var tempRuleList = [...ruleList]
        var rule = tempRuleList.find(r => r.id === ruleId)!!
        var conditions = [...rule.conditions]
        var condition = conditions.find(c => c.id === conditionId)!!


        condition.productCategories = categories
        rule.conditions = conditions
        setRuleList(tempRuleList)
    }

    const removeCondition = (index: number) => {
        var conditions = [...newConditions]
        conditions.splice(index, 1)
        setNewConditions(conditions)
    }

    const setNumRangeError = (c: Condition, index: number) => {
        var error = undefined
        if (c.numIntervalFrom!! > c.numIntervalTo!!) {
            error = "Second value must be greater or equal to first value"
        } else if (c.numIntervalFrom!! < 0 || c.numIntervalTo!! < 0) {
            error = "Both values must be non-negative"
        }

        if (error) {
            c.error = error
            const conditions = [...newConditions]
            conditions[index] = c
            setNewConditions(conditions)
        } else {
            c.error = undefined
            const conditions = [...newConditions]
            conditions[index] = c
            setNewConditions(conditions)
        }

    }

    const getNewConditionFields = (c: Condition, index: number) => {

        var type = c.property.type

        if (type === "STRING") {
            if (c.property.name === "Bill Category") {
                return <>
                    <div className="flex w-full flex-col mb-4">
                        <div className="text-sm">{c.property.name}</div>
                        <Select
                            className="w-full rounded-xl bg-white"
                            wrapperClassName="w-full rounded-lg py-2"
                            options={categories.map(c => ({ value: c, label: c }))}
                            values={[{ value: c.stringValue!!, label: c.stringValue!! }]}
                            onChange={(w) => changeNewConditionValues({ ...c, stringValue: w[0].value } as Condition, index)}
                        />
                    </div>
                </>
            }
            return <>
                <div className="w-full flex flex-col">
                    <div className="text-sm">{c.property.name}</div>
                    <Input hasError={c.error !== undefined} className="py-1 px-2 rounded-lg" id="new-condition-text" type="text" value={c.stringValue} onChange={(e) => {
                        changeNewConditionValues({ ...c, stringValue: e.target.value } as Condition, index)
                    }} />
                    <StyledError className="h-4">{c.error}</StyledError>
                </div>
            </>
        } else if (type === "NUMERIC") {
            return <div className="flex w-full flex-col">
                <div className="flex w-full" onBlur={() => setNumRangeError(c, index)}>
                    <div className="w-full mr-2">
                        <div className="text-sm">{t('range_from')}</div>
                        <Input className="rounded-lg py-1 pl-2" hasError={c.error !== undefined} style={{ width: "100%" }} id="new-condition-value-from" type="number" step="any" value={c.numIntervalFrom} onChange={(e) => {
                            changeNewConditionValues({ ...c, numIntervalFrom: parseFloat(e.target.value) } as Condition, index)
                        }} />
                    </div>
                    <div className="w-full">
                        <div className="text-sm">{t('range_to')}</div>
                        <Input className="rounded-lg py-1 pl-2" hasError={c.error !== undefined} style={{ width: "100%" }} id="new-condition-value-to" type="number" step="any" value={c.numIntervalTo} onChange={(e) => {
                            changeNewConditionValues({ ...c, numIntervalTo: parseFloat(e.target.value) } as Condition, index)
                        }} />
                    </div>
                </div>
                <StyledError className="h-4">{c.error}</StyledError>
            </div>
        } else if (type === "TIME") {
            return <div className="w-full flex flex-col">
                <div className="flex w-full">
                    <div className="w-full mr-2">
                        <div className="text-sm">{t('From')}</div>
                        <TimePicker value={c.timeIntervalFrom} onChange={(e) => {
                            changeNewConditionValues({ ...c, timeIntervalFrom: e } as Condition, index)
                        }} />
                    </div>
                    <div className="w-full">
                        <div className="text-sm">{t('til')}</div>
                        <TimePicker value={c.timeIntervalTo} onChange={(e) => {
                            changeNewConditionValues({ ...c, timeIntervalTo: e } as Condition, index)
                        }} />
                    </div>
                </div>
                <StyledError className="h-4">{c.error}</StyledError>
            </div>
        } else if (type === "PRODUCT_CATEGORY") {
            return <>
                <div className="w-full flex-col mb-4">
                    <div className="text-sm">{c.property.name}</div>
                    <Select
                        className="bg-white rounded-lg outline-none py-1"
                        options={productCategories}
                        values={newConditions[index].productCategories!!}
                        multi
                        onChange={(cat) => { changeNewConditionCategories(c, index, cat) }}
                        labelField={"category"}
                        valueField={"category"}
                    />
                </div>
            </>
        }

    }

    const addDefaultCondition = () => {

        var conditions = [...newConditions, {
            property: { name: "Price", type: "NUMERIC" },
            numIntervalFrom: 0.0,
            numIntervalTo: 1000.0
        }]

        setNewConditions(conditions)
    }

    const expandNewRule = () => {
        var conditions = [{
            property: { name: "Price", type: "NUMERIC" },
            numIntervalFrom: 0.0,
            numIntervalTo: 1000.0
        }]
        setNewConditions(conditions)
    }

    const changeConditionType = (c: any, type: string, index: number) => {
        const newType = properties.find(p => p.name === type)
        const conditions = [...newConditions]
        c.property = newType
        conditions[index] = c
        setNewConditions(conditions)
    }

    const getCondition = (condition: Condition, index: number, ruleId: number) => {
        if (condition.property.type === "TIME") {
            return <>
                <div className="text-sm w-36">{condition.property.name}</div>
                <div className="w-full items-center flex">
                    <div className="w-full mr-2">
                        <div className="text-sm">{t('from')}</div>
                        <TimePicker value={condition.timeIntervalFrom} onChange={(e) => {
                            changeConditionValues(ruleId, { ...condition, timeIntervalFrom: new Date(e) } as Condition, index)
                        }} />
                    </div>
                    <div className="w-full">
                        <div className="text-sm">{t('til')}</div>
                        <TimePicker value={condition.timeIntervalTo} onChange={(e) => {
                            changeConditionValues(ruleId, { ...condition, timeIntervalTo: new Date(e) } as Condition, index)
                        }} />
                    </div>
                </div>
            </>
        } else if (condition.property.type === "NUMERIC") {
            return <>
                <div className="flex w-full flex-col" >
                    <div className="text-sm">{condition.property.name + " Range"}</div>
                    <div className="w-full flex">
                        <Input className="rounded-lg py-1 pl-2 w-full mr-2" style={{ width: "100%" }} id="new-condition-value-from" type="number" step="any" value={condition.numIntervalFrom} onChange={(e) => {
                            changeConditionValues(ruleId, { ...condition, numIntervalFrom: parseFloat(e.target.value) } as Condition, index)
                        }} />
                        <Input className="rounded-lg py-1 pl-2 w-full" style={{ width: "100%" }} id="new-condition-value-to" type="number" step="any" value={condition.numIntervalTo} onChange={(e) => {
                            changeConditionValues(ruleId, { ...condition, numIntervalTo: parseFloat(e.target.value) } as Condition, index)
                        }} />
                    </div>
                </div>

            </>
        } else if (condition.property.type === "STRING") {
            if (condition.property.name === "Bill Category") {
                return <>
                    <div className="flex flex-col w-full">
                        <div className="text-sm w-36">{condition.property.name}</div>
                        <Select
                            className="bg-white rounded-lg outline-none py-1"
                            options={categories.map(c => ({ value: c, label: c }))}
                            values={[{ value: condition.stringValue!!, label: condition.stringValue!! }]}
                            onChange={c => changeConditionValues(ruleId, { ...condition, stringValue: c[0].value } as Condition, index)}
                        />
                    </div>
                </>
            }
            return <>
                <div className="w-full">
                    <div className="text-sm w-36">{condition.property.name}</div>
                    <Input className="py-1 px-2 rounded-lg w-full ring-gray-100" id={"text" + condition.id} type="text" value={condition.stringValue} onChange={(e) => {
                        changeConditionValues(ruleId, { ...condition, stringValue: e.target.value } as Condition, index)
                    }} />
                </div>
            </>
        } else if (condition.property.type === "PRODUCT_CATEGORY") {
            return <>
                <div className="flex flex-col w-full">
                <div className="text-sm w-36">{condition.property.name}</div>
                    <Select
                    className="bg-white rounded-lg outline-none py-1"
                    options={productCategories}
                    values={condition.productCategories!!}
                    multi
                    onChange={(cat) => { changeConditionCategories(ruleId, condition.id!!, cat) }}
                    labelField={"category"}
                    valueField={"category"} />
                </div>
            </>
        } else {
            return "Unknown"
        }
    }

    const expand = (ruleId: number) => {
        if (expanded === -2) {
            setNewConditions([])
        }
        setExpanded(ruleId)
    }

    return (
        <div className="w-full md:px-4 mt-4">
            {
                ruleList.map(rule => rule?.tag?.name && rule.tag.name.length > 0 ? (
                    <div className="bg-white rounded-lg shadow-md mb-4">
                        <div className="bg-secondary cursor-pointer flex justify-between items-center h-14 rounded-lg px-6 shadow-xs  " onClick={() => { expanded === rule.id ? setExpanded(-1) : expand(rule.id!!) }}>
                            <div className="text-primary">{rule?.tag?.name}</div>
                            <FontAwesomeIcon className="text-primary" icon={faTrash} onClick={(e) => {e.stopPropagation();deleteRule(rule.id!!)}} />
                        </div>
                        {
                            expanded === rule.id && (
                                <div className="px-6 py-4">
                                    {
                                        rule.conditions.map((cond, index) => (
                                            <div key={"cond-" + index}  className="w-full flex items-center mb-3">
                                                <div className="flex items-center justify-between w-full">
                                                    {
                                                        getCondition(cond, index, rule.id!!)
                                                    }
                                                </div>
                                                <div className="ml-2 h-2">
                                                    <FontAwesomeIcon icon={faMinusCircle} onClick={() => deleteCondition(cond.id!!)} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {newConditions.map((c, index) =>
                                        <div key={"new-cond-" + index} className="w-full flex items-center" >
                                            <div className="w-36 mr-2 mb-4">
                                                <div className="text-sm">{t('property')}</div>
                                                <select className="pt-1 pb-2 rounded-lg pl-2 w-full  border-2 border-gray-200" id="new-condition" value={c.property.name} onChange={(e) => changeConditionType(c, e.target.value, index)}>
                                                    {
                                                        properties.map(p => (
                                                            <option value={p.name}>{p.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                                {
                                                    getNewConditionFields(c, index)
                                                }
                                            <div className="cursor-pointer">
                                                <FontAwesomeIcon icon={faMinusCircle} className="cursor-pointer ml-2" onClick={() => removeCondition(index)} />
                                            </div>
                                        </div>)
                                    }
                                    <div className="flex justify-between mt-6 flex-col md:flex-row">
                                        <Button className="w-full mb-3 md:mb-0 md:mr-6" onClick={() => addDefaultCondition()} color="#B1B1B1" bgColor="bg-gray-300" textColor="" value="Add condition"/>
                                        <Button className="w-full" onClick={() => { setExpanded(-1); changeRule(rule.id!!) }} bgColor="bg-primary" hoverColor="bg-primary-dark" textColor="text-white" value="Save"/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                ):"")
            }
            <div className="bg-white rounded-lg shadow-md">
                <div className="bg-secondary cursor-pointer flex justify-between items-center h-14 rounded-lg px-6 shadow-xs " onClick={() => { expandNewRule(); setExpanded(-2) }}>
                    <div className="flex items-center text-white">
                        <div className="rounded-full bg-white h-8 w-8 flex justify-center items-center ">
                            <FontAwesomeIcon icon={faPlus} className="text-primary" />
                        </div>
                        <div style={{ marginLeft: "1rem" }} className="text-primary">
                            {t('new_rule')}
                        </div>
                    </div>
                </div>
                {
                    expanded === -2 && (
                        <div className="px-6 py-4">
                            <div className="flex items-center mb-4 ">
                                <div className="text-sm w-36 mr-2">{t('tag_name')}</div>
                                <div className="flex flex-col w-full">
                                    <div className="text-sm">{t('name')}</div>
                                    <Input className="rounded-lg py-1 px-2 w-full" value={newTagName} onChange={(e) => setNewTagName(e.target.value)} />
                                </div>
                            </div>
                            {newConditions.map((c, index) => (
                                <div key={"new-cond-" + index} className="flex justify-between items-center flex-col md:flex-row mb-4">
                                    <div className="w-full md:w-36 md:mr-2 md:mb-4">
                                        <div className="text-sm">{t('property')}</div>
                                        <select className="rounded-lg pt-1 pb-2 pl-2 w-full border-2 border-gray-200 " value={c.property.name} onChange={(e) => changeConditionType(c, e.target.value, index)}>
                                            {
                                                properties.map(p => (
                                                    <option value={p.name}>{p.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="flex mt-1 md:mt-0 w-full">
                                        <div className="flex w-full">
                                            {getNewConditionFields(c, index)}
                                        </div>
                                        {index !== 0 &&
                                            <div className="my-auto ml-2">
                                                <FontAwesomeIcon icon={faMinusCircle} onClick={() => removeCondition(index)} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))}
                            <div className="flex justify-between mt-4 flex-col md:flex-row">
                                <Button className="w-full mb-3 md:mb-0 md:mr-6" onClick={() => addDefaultCondition()} color="#B1B1B1" bgColor="bg-gray-300" textColor="" value="Add condition"/>
                                <Button className="w-full" onClick={() => { setExpanded(-1); saveNewRule() }} bgColor="bg-primary" hoverColor="bg-primary-dark" textColor="text-white" value="Save"/>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>)
}

export default RuleList