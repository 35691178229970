import {
  CatStatsDataType,
  TempStatsDataType,
  StatsType,
} from "../slices/graphSlice";
import UserStatistics from "../types/Statistics";
import colors from "./colors";
import { ProductCategory, Tag } from "../types/Statistics";
import { TCustomStatistics } from "../types/TCustomStatistics";

class StatsTransformer {
  getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  customPieTransform(stats: TCustomStatistics, type: string, dataType: string) {
    var amounts: number[] = [];
    var labels: string[] = [];
    var all: {
      id: number;
      label: string;
      value: number;
      color: string;
    }[] = [];

    const generateColor = (name: string) => {
      if (colors[name]) {
        return colors[name];
      } else {
        var hash = 0;
        var i;
        for (i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = "#";
        for (i = 0; i < 3; i++) {
          var value = (hash >> (i * 8)) & 0xff;
          colour += ("00" + value.toString(16)).substr(-2);
        }
        return colour;
      }
    };

    if (dataType === CatStatsDataType.category) {
      amounts = stats!.categories.map(
        (cat) => Math.round(cat.amount * 100) / 100
      );
      labels = stats!.categories.map((cat) =>
        cat.name ? cat.name : "Nezatriedené výdavky"
      );
      all = stats!.categories.map((cat) => {
        return {
          id: Math.random(),
          label: cat.name ? cat.name : "Nezatriedené výdavky",
          value: Math.round(cat.amount * 100) / 100,
          color: generateColor(cat.name ? cat.name : "Nezatriedené výdavky"),
        };
      });
    } else if (dataType === CatStatsDataType.organization) {
      amounts = stats!.organizations.map(
        (org) => Math.round(org.amount * 100) / 100
      );
      labels = stats!.organizations.map((org) =>
        org.name ? org.name : "Neznáme"
      );
      all = stats!.organizations.map((org) => {
        return {
          id: Math.random(),
          label: org.name ? org.name : "Neznáme",
          value: Math.round(org.amount * 100) / 100,
          color: generateColor(org.name ? org.name : "Neznáme"),
        };
      });
    } else if (dataType === CatStatsDataType.productCat) {
      var sortedCategories = [...stats.productCategories];
      sortedCategories.sort((a: any, b: any) => (a.amount > b.amount ? -1 : 1));

      if (sortedCategories.length > 10) {
        var sumOther = sortedCategories
          .slice(6)
          .reduce((total: number, cat: any) => total + cat.amount, 0);
        amounts = sortedCategories
          .slice(0, 10)
          .map((cat) => Math.round(cat.amount * 100) / 100);
        labels = sortedCategories
          .slice(0, 10)
          .map((cat) => (cat.name ? cat.name : "Neznáme"));

        amounts.push(sumOther);
        labels.push("Other");
        sortedCategories = sortedCategories.slice(0, 10);
        sortedCategories.push({
          name: "Other",
          amount: sumOther,
        });

        all = sortedCategories.map((cat) => {
          return {
            id: Math.random(),
            label: cat.name ? cat.name : "Neznáme",
            value: Math.round(cat.amount * 100) / 100,
            color: generateColor(cat.name ? cat.name : "Neznáme"),
          };
        });

        all[all.length - 1].color = "#dedede";
      } else {
        amounts = sortedCategories.map(
          (cat) => Math.round(cat.amount * 100) / 100
        );
        labels = sortedCategories.map((cat) =>
          cat.name ? cat.name : "Neznáme"
        );
        all = sortedCategories.map((cat) => {
          return {
            id: Math.random(),
            label: cat.name ? cat.name : "Neznáme",
            value: Math.round(cat.amount * 100) / 100,
            color: generateColor(cat.name ? cat.name : "Neznáme"),
          };
        });
      }

      // Tag transformer
    } else if (dataType === CatStatsDataType.tag) {
      if (stats.tags) {
        var sortedTags = [...stats.tags];
        sortedTags.sort((a: any, b: any) => (a.amount > b.amount ? -1 : 1));

        if (sortedTags.length > 10) {
          sumOther = sortedTags
            .slice(6)
            .reduce((total: number, cat: any) => total + cat.amount, 0);
          amounts = sortedTags
            .slice(0, 10)
            .map((cat) => Math.round(cat.amount * 100) / 100);
          labels = sortedTags
            .slice(0, 10)
            .map((cat) => (cat.name ? cat.name : "Neznáme"));

          amounts.push(sumOther);
          labels.push("Other");
          sortedTags = sortedTags.slice(0, 10);
          sortedTags.push({
            name: "Other",
            amount: sumOther,
          });

          all = sortedTags.map((cat) => {
            return {
              id: Math.random(),
              label: cat.name ? cat.name : "Neznáme",
              value: Math.round(cat.amount * 100) / 100,
              color: generateColor(cat.name ? cat.name : "Neznáme"),
            };
          });

          all[all.length - 1].color = "#dedede";
        } else {
          amounts = sortedTags.map((cat) => Math.round(cat.amount * 100) / 100);
          labels = sortedTags.map((cat) => (cat.name ? cat.name : "Neznáme"));
          all = sortedTags.map((cat) => {
            return {
              id: Math.random(),
              label: cat.name ? cat.name : "Neznáme",
              value: Math.round(cat.amount * 100) / 100,
              color: generateColor(cat.name ? cat.name : "Neznáme"),
            };
          });
        }
      } else {
        all = [];
      }
    } else {
      amounts = stats!.orgUnits.map(
        (unit) => Math.round(unit.amount * 100) / 100
      );
      labels = stats!.orgUnits.map((unit) =>
        unit.name ? unit.name : unit.organizationName + " - " + unit.address
      );
      all = stats!.orgUnits.map((unit) => {
        return {
          id: Math.random(),
          label: unit.name
            ? unit.name
            : unit.organizationName?.split(",")[0] +
              ", " +
              unit.address?.split(",")[1],
          value: Math.round(unit.amount * 100) / 100,
          color: generateColor(unit.name ? unit.name : (unit.address + "" + unit.organizationName)),
        };
      });
    }
    return all;
  }

  customLineTransform(
    stats: TCustomStatistics,
    type: string,
    dataType: string
  ) {
    var points: {x: string, y: string}[];
    var monthlyIntervals;
    var weeklyIntervals;

    if (dataType === TempStatsDataType.balance) {
      //points = stats!.chart!.points.map(point => ({
      //    x: point.date!!.split('-')[2],
      //    y: point..toFixed(2)
      //}))
      var balance = 0.0
      points = []
        stats!.chart!.points.forEach(p => {
            balance += (p.income - p.expense)
            points.push({
                x: p.date!!.split('-')[2],
                y: balance.toFixed(2)
            })
        })

    
    } else if (dataType === TempStatsDataType.expense) {
      points = stats!.chart!.points.map((point) => ({
        x:
          type === StatsType.customRange
            ? point.date!!.split("-")[2] +
              "." +
              point.date!!.split("-")[1] +
              "." +
              point.date!!.split("-")[0]
            : point.date!!.split("-")[2],
        y: point.expense.toFixed(2),
      }));
    } else {
      points = stats!.chart!.points.map((point) => ({
        x:
          type === StatsType.customRange
            ? point.date!!.split("-")[2] +
              "." +
              point.date!!.split("-")[1] +
              "." +
              point.date!!.split("-")[0]
            : point.date!!.split("-")[2],
        y: point.income.toFixed(2),
      }));
    }

    var newLineData = {
      datasets: [
        {
          data: points,
          color: "hsl(212, 70%, 50%)",
          id: dataType[0] + dataType.toLowerCase().slice(1) + ": ",
        },
      ],
      monthlyIntervals,
      weeklyIntervals,
    };

    return newLineData;
  }

  pieTransform(
    stats: UserStatistics,
    type: string,
    dataType: string,
    weekStart: string,
    monthStart: string
  ) {
    var amounts: number[] = [];
    var labels: string[] = [];
    var all: {
      id: number;
      label: string;
      value: number;
      color: string;
    }[] = [];
    var monthlyIntervals;
    var weeklyIntervals;

    var beginning =
      type === StatsType.monthly
        ? monthStart
        : type === StatsType.weekly
        ? weekStart
        : type === StatsType.customRange
        ? "custom"
        : "";
    const generateColor = (name: string) => {
      if (colors[name]) {
        return colors[name];
      } else {
        var hash = 0;
        var i;
        for (i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = "#";
        for (i = 0; i < 3; i++) {
          var value = (hash >> (i * 8)) & 0xff;
          colour += ("00" + value.toString(16)).substr(-2);
        }
        return colour;
      }
    };

    if (stats.monthly && beginning !== "") {
      var selectedStats;
      if (type === StatsType.monthly) {
        selectedStats = stats.monthly.find((m) => m.startDate === beginning);
      } else if (type === StatsType.weekly) {
        selectedStats = stats.weekly.find((m) => m.startDate === beginning);
      } else {
        selectedStats = stats.monthly[0];
      }

      if (selectedStats) {
        if (dataType === CatStatsDataType.category) {
          amounts = selectedStats!.categories.map(
            (cat) => Math.round(cat.amount * 100) / 100
          );
          labels = selectedStats!.categories.map((cat) =>
            cat.name ? cat.name : "Nezatriedené výdavky"
          );
          all = selectedStats!.categories.map((cat) => {
            return {
              id: Math.random(),
              label: cat.name ? cat.name : "Nezatriedené výdavky",
              value: Math.round(cat.amount * 100) / 100,
              color: generateColor(
                cat.name ? cat.name : "Nezatriedené výdavky"
              ),
            };
          });
        } else if (dataType === CatStatsDataType.organization) {
          amounts = selectedStats!.organizations.map(
            (org) => Math.round(org.amount * 100) / 100
          );
          labels = selectedStats!.organizations.map((org) =>
            org.name ? org.name : "Neznáme"
          );
          all = selectedStats!.organizations.map((org) => {
            return {
              id: Math.random(),
              label: org.name ? org.name : "Neznáme",
              value: Math.round(org.amount * 100) / 100,
              color: generateColor(org.name ? org.name : "Neznáme"),
            };
          });
        } else if (dataType === CatStatsDataType.productCat) {
          var sortedCategories = [...selectedStats.productCategories];
          sortedCategories.sort((a: ProductCategory, b: ProductCategory) =>
            a.amount > b.amount ? -1 : 1
          );

          if (sortedCategories.length > 10) {
            var sumOther = sortedCategories
              .slice(6)
              .reduce(
                (total: number, cat: ProductCategory) => total + cat.amount,
                0
              );
            amounts = sortedCategories
              .slice(0, 10)
              .map((cat) => Math.round(cat.amount * 100) / 100);
            labels = sortedCategories
              .slice(0, 10)
              .map((cat) => (cat.name ? cat.name : "Neznáme"));

            amounts.push(sumOther);
            labels.push("Other");
            sortedCategories = sortedCategories.slice(0, 10);
            sortedCategories.push({
              name: "Other",
              amount: sumOther,
            });

            all = sortedCategories.map((cat) => {
              return {
                id: Math.random(),
                label: cat.name ? cat.name : "Neznáme",
                value: Math.round(cat.amount * 100) / 100,
                color: generateColor(cat.name ? cat.name : "Neznáme"),
              };
            });

            all[all.length - 1].color = "#dedede";
          } else {
            amounts = sortedCategories.map(
              (cat) => Math.round(cat.amount * 100) / 100
            );
            labels = sortedCategories.map((cat) =>
              cat.name ? cat.name : "Neznáme"
            );
            all = sortedCategories.map((cat) => {
              return {
                id: Math.random(),
                label: cat.name ? cat.name : "Neznáme",
                value: Math.round(cat.amount * 100) / 100,
                color: generateColor(cat.name ? cat.name : "Neznáme"),
              };
            });
          }

          // Tag transformer
        } else if (dataType === CatStatsDataType.tag) {
          if (selectedStats.tags) {
            var sortedTags = [...selectedStats.tags];
            sortedTags.sort((a: Tag, b: Tag) => (a.amount > b.amount ? -1 : 1));

            if (sortedTags.length > 10) {
              sumOther = sortedTags
                .slice(6)
                .reduce((total: number, cat: Tag) => total + cat.amount, 0);
              amounts = sortedTags
                .slice(0, 10)
                .map((cat) => Math.round(cat.amount * 100) / 100);
              labels = sortedTags
                .slice(0, 10)
                .map((cat) => (cat.name ? cat.name : "Neznáme"));

              amounts.push(sumOther);
              labels.push("Other");
              sortedTags = sortedTags.slice(0, 10);
              sortedTags.push({
                name: "Other",
                amount: sumOther,
              });

              all = sortedTags.map((cat) => {
                return {
                  id: Math.random(),
                  label: cat.name ? cat.name : "Neznáme",
                  value: Math.round(cat.amount * 100) / 100,
                  color: generateColor(cat.name ? cat.name : "Neznáme"),
                };
              });

              all[all.length - 1].color = "#dedede";
            } else {
              amounts = sortedTags.map(
                (cat) => Math.round(cat.amount * 100) / 100
              );
              labels = sortedTags.map((cat) =>
                cat.name ? cat.name : "Neznáme"
              );
              all = sortedTags.map((cat) => {
                return {
                  id: Math.random(),
                  label: cat.name ? cat.name : "Neznáme",
                  value: Math.round(cat.amount * 100) / 100,
                  color: generateColor(cat.name ? cat.name : "Neznáme"),
                };
              });
            }
          } else {
            all = [];
          }
        } else {
          amounts = selectedStats!.orgUnits.map(
            (unit) => Math.round(unit.amount * 100) / 100
          );
          labels = selectedStats!.orgUnits.map((unit) =>
            unit.name ? unit.name : unit.organizationName + " - " + unit.address
          );
          all = selectedStats!.orgUnits.map((unit) => {
            return {
              id: Math.random(),
              label: unit.name
                ? unit.name
                : unit.organizationName?.split(",")[0] +
                  ", " +
                  unit.address?.split(",")[1],
              value: Math.round(unit.amount * 100) / 100,
              color: generateColor(unit.name ? unit.name : "Neznáme"),
            };
          });
        }

        monthlyIntervals = stats.monthly
          .map((m, index) => ({
            index,
            selected: monthStart === m.startDate,
            value: m.startDate,
            title: m.startDate?.split("-").slice(0, 2).reverse().join("."),
          }))
          .reverse();
        weeklyIntervals = stats.weekly
          ?.filter(
            (w) =>
              w.startDate?.split("-").slice(0, 2).join("") ===
                monthStart?.split("-").slice(0, 2).join("") ||
              w.endDate?.split("-").slice(0, 2).join("") ===
                monthStart?.split("-").slice(0, 2).join("")
          )
          .map((w, i) => ({
            i,
            value: w.startDate,
            title:
              w.startDate?.split("-").slice(1, 3).reverse().join(".") +
              " - " +
              w.endDate?.split("-").slice(1, 3).reverse().join("."),
            selected: weekStart === w.startDate,
          }));
        if (weeklyIntervals.length > 0) {
          if (type !== StatsType.customRange) {
            weeklyIntervals[0].selected = true;
          } else {
            monthlyIntervals[0].selected = true;
          }
        }
      }
    }

    var gColors = labels.map((name) => {
      if (colors[name]) {
        return colors[name];
      } else {
        var hash = 0;
        for (var i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }

        var colour = "hsl(" + hash + ", 100%, 75%)";
        /*var colour = '#';
                for (var i = 0; i < 3; i++) {
                    var value = (hash >> (i * 8)) & 0xFF;
                    colour += ('00' + value.toString(16)).substr(-2);
                }*/

        if (name !== "Other") {
          return colour;
        } else {
          return "#dedede";
        }
      }
    });

    var pieData = {
      datasets: [
        {
          data: amounts,
          backgroundColor: gColors,
        },
      ],
      labels: labels,
      data: all,
      monthlyIntervals,
      weeklyIntervals,
    };

    return pieData;
  }

  lineTransform(
    stats: UserStatistics,
    type: string,
    dataType: string,
    weekStart: string,
    monthStart: string
  ) {
    var points;
    var monthlyIntervals;
    var weeklyIntervals;
    var beginning =
      type === StatsType.monthly
        ? monthStart
        : type === StatsType.weekly
        ? weekStart
        : type === StatsType.customRange
        ? "custom"
        : "";

    if (stats.monthly && beginning !== "") {
      var selectedStats;
      if (type === StatsType.monthly) {
        selectedStats = stats.monthly.find((m) => m.startDate === beginning);
      } else if (type === StatsType.weekly) {
        selectedStats = stats.weekly.find((m) => m.startDate === beginning);
      } else {
        selectedStats = stats.monthly[0];
      }

      if (selectedStats) {
        if (
          dataType === TempStatsDataType.balance &&
          type !== StatsType.customRange
        ) {
          points = selectedStats!.chart!.points.map((point) => ({
            x: point.date!!.split("-")[2],
            y: point.balance.toFixed(2),
          }));
        } else if (dataType === TempStatsDataType.expense) {
          points = selectedStats!.chart!.points.map((point) => ({
            x:
              type === StatsType.customRange
                ? point.date!!.split("-")[2] +
                  "." +
                  point.date!!.split("-")[1] +
                  "." +
                  point.date!!.split("-")[0]
                : point.date!!.split("-")[2],
            y: point.expense.toFixed(2),
          }));
        } else {
          points = selectedStats!.chart!.points.map((point) => ({
            x:
              type === StatsType.customRange
                ? point.date!!.split("-")[2] +
                  "." +
                  point.date!!.split("-")[1] +
                  "." +
                  point.date!!.split("-")[0]
                : point.date!!.split("-")[2],
            y: point.income.toFixed(2),
          }));
        }

        monthlyIntervals = stats.monthly
          .map((m, index) => ({
            index,
            selected: m.startDate === monthStart,
            value: m.startDate,
            title: m.startDate?.split("-").slice(0, 2).reverse().join("."),
          }))
          .reverse();
        weeklyIntervals = stats.weekly
          ?.filter(
            (w) =>
              w.startDate?.split("-").slice(0, 2).join("") ===
                monthStart?.split("-").slice(0, 2).join("") ||
              w.endDate?.split("-").slice(0, 2).join("") ===
                monthStart?.split("-").slice(0, 2).join("")
          )
          .map((w, i) => ({
            i,
            value: w.startDate,
            title:
              w.startDate?.split("-").slice(1, 3).reverse().join(".") +
              " - " +
              w.endDate?.split("-").slice(1, 3).reverse().join("."),
            selected: w.startDate === weekStart,
          }));
        if (weeklyIntervals.length > 0) {
          if (type !== StatsType.customRange) {
            weeklyIntervals[0].selected = true;
          } else {
            monthlyIntervals[0].selected = true;
          }
        }
      }
    }

    var newLineData = {
      datasets: [
        {
          data: points,
          color: "hsl(212, 70%, 50%)",
          id: dataType[0] + dataType.toLowerCase().slice(1) + ": ",
        },
      ],
      monthlyIntervals,
      weeklyIntervals,
    };

    return newLineData;
  }
}

export default StatsTransformer;
