import {
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronUp,
  faRandom,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TransactionGroup,
  DateRange,
  TransactionGroupDetails,
  TransactioDetailsRow,
} from "../../styled_components/transactions/TransactionsTable";
import TransactionData from "../../types/TransactionData";

const TransactionsTable = ({
  transactions,
  setSelectedTransaction,
  groupByMonths,
}: {
  transactions: TransactionData[];
  setSelectedTransaction: (i: number) => void;
  groupByMonths: boolean;
}) => {
  const [groupedTransactions, setGroupedTransactions] = useState(
    [] as {
      startDate: Date;
      endDate: Date;
      amount: number;
      expenses: number;
      income: number;
      transactions: any[];
    }[]
  );
  const [shownTransactions, setShownTransactions] = useState([] as any[]);

  const [selectedGroup, setSelectedGroup] = useState(0);
  const [toggleSort, setToggleSort] = useState([0, 0, 0, 0]);
  const {t} = useTranslation()

  const getMonths = (startDate: Date, endDate: Date) => {
    var dateArray = [];
    var currentDate = startDate;
    currentDate.setDate(1);

    while (currentDate < endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    if (currentDate === endDate) {
      dateArray.push(new Date(currentDate));
    }

    var dates = dateArray
      .map((date) => ({
        startDate: new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
        amount: 0,
        expenses: 0,
        income: 0,
        transactions: [],
      }))
      .reverse();

    return dates;
  };

  useEffect(() => {
    if (groupedTransactions[selectedGroup] && groupByMonths) {
      setShownTransactions(
        groupedTransactions[selectedGroup].transactions.reverse()
      );
    } else if (!groupByMonths) {
      var temp = [] as any[];
      for (const a of groupedTransactions) {
        temp = [...temp, ...a.transactions];
      }
      setShownTransactions(temp.reverse());
    }
  }, [selectedGroup, groupedTransactions, groupByMonths]);

  useEffect(() => {
    if (transactions.length === 0) {
      return;
    }
    var withDate = transactions.filter((t) => t.date != null);
    var sortedTransactions = withDate
      .sort((a, b) =>
        a.date
          .split(".")
          .reverse()
          .join()
          .localeCompare(b.date.split(".").reverse().join())
      )
      .map((t) => ({
        ...t,
        date: new Date(t.date.split(".").reverse().join("-")),
      }));

    var firstDate = sortedTransactions[0].date;
    var lastDate = sortedTransactions[sortedTransactions.length - 1].date;

    var groupedTransactions2 = getMonths(firstDate, lastDate);

    groupedTransactions2.forEach((g) => {
      let t = sortedTransactions.filter(
        (t) => t.date >= g.startDate && t.date <= g.endDate
      );
      g.expenses = t.reduce(
        (acc, val) => (val.amount < 0 ? acc + val.amount : acc),
        0
      );
      g.income = t.reduce(
        (acc, val) => (val.amount > 0 ? acc + val.amount : acc),
        0
      );
      g.amount = g.expenses + g.income;

      if (t) {
        g.transactions = t as [];
      }
    });

    setGroupedTransactions(groupedTransactions2);
  }, [transactions]);

  const handleSortChange = (col: number) => {
    var tc = toggleSort;
    if (toggleSort[col] <= 1) {
      tc[col] = 2;
      setToggleSort([...tc]);
    } else {
      tc[col] = 1;
      setToggleSort([...tc]);
    }

    var transactions1 = groupByMonths
      ? groupedTransactions[selectedGroup].transactions
      : transactions.slice();

    if (col === 0) {
      setShownTransactions(
        transactions1.sort((a: any, b: any) => {
          let dateA = a["date"];
          let dateB = b["date"];

          if (toggleSort[0] === 2) {
            return dateA.getTime() - dateB.getTime();
          } else {
            return dateB.getTime() - dateA.getTime();
          }
        })
      );
    } else if (col === 1 || col === 2) {
      setShownTransactions(
        transactions1.sort((a: any, b: any) => {
          if (
            a[Object.keys(a)[col]] !== null &&
            a[Object.keys(a)[col]] !== null
          ) {
            if (toggleSort[col] === 2) {
              return (
                -1 *
                a[Object.keys(a)[col]].localeCompare(b[Object.keys(b)[col]])
              );
            } else {
              return a[Object.keys(a)[col]].localeCompare(
                b[Object.keys(b)[col]]
              );
            }
          } else {
            return 1;
          }
        })
      );
    } else {
      setShownTransactions(
        transactions1.sort((a: any, b: any) => {
          if (
            a[Object.keys(a)[col + 1]] > b[Object.keys(b)[col + 1]] &&
            toggleSort[col] === 2
          ) {
            return -1;
          } else if (
            a[Object.keys(a)[col + 1]] > b[Object.keys(b)[col + 1]] &&
            toggleSort[col] === 1
          ) {
            return 1;
          } else if (
            a[Object.keys(a)[col + 1]] < b[Object.keys(b)[col + 1]] &&
            toggleSort[col] === 2
          ) {
            return 1;
          } else {
            return -1;
          }
        })
      );
    }
  };

  return (
    <div
      className="pl-4 pr-6 scrollbar scrollbar-track-transparent scrollbar-thumb-gray-300 scrollbar-thin scrollbar-thumb-rounded-lg overflow-y-scroll overflow-x-hidden max-w-full h-full"
    >
      {groupByMonths ? (
        groupedTransactions.map((t, i) => (
          <div
            key={i}
            className={selectedGroup === i ? "rounded-xl px-2 border-gray-200 bg-gray-100" : ""}
          >
            <div
              //selected={selectedGroup === i}
              className={
                "w-full max-w-full flex justify-between py-3 cursor-pointer rounded-t-xl " +
                (selectedGroup === i ? "" : " border-gray-200 pr-2")
              }
              onClick={
                selectedGroup !== i
                  ? () => setSelectedGroup(i)
                  : () => setSelectedGroup(-1)
              }
            >
              <div className="pl-2 text-sm">
                {t.startDate.getDate() +
                  "." +
                  (t.startDate.getMonth() + 1) +
                  "." +
                  t.startDate.getFullYear()}{" "}
                -{" "}
                {t.endDate.getDate() +
                  "." +
                  (t.endDate.getMonth() + 1) +
                  "." +
                  t.endDate.getFullYear()}
              </div>
              <div className="flex items-center">
                <div className="text-green-600 w-16 md:w-24 text-right text-sm">
                  {t.income.toFixed(2)} €
                </div>
                <div className="text-red-600 w-20 md:w-24 text-right text-sm">
                  {t.expenses.toFixed(2)} €
                </div>
                <div className="md:w-24 w-20 text-right mr-2 text-sm">
                  {t.amount.toFixed(2)} €
                </div>
                <div className="w-4">
                  {t.transactions.length > 0 && (
                    <a
                      onClick={
                        selectedGroup !== i
                          ? () => setSelectedGroup(i)
                          : () => setSelectedGroup(-1)
                      }
                    >
                      <FontAwesomeIcon
                        color={"#0F497B"}
                        height={"30px"}
                        icon={
                          selectedGroup !== i
                            ? faChevronCircleDown
                            : faChevronCircleUp
                        }
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
            {shownTransactions.length > 0 && (
              <>
                {selectedGroup === i ? (
                  <div className="flex rounded-t-md">
                  <table className="w-full max-w-full table-auto rounded-t-md mb-3">
                    <thead className="w-full rounded-t-md">
                      <tr className="w-full border-gray-200 bg-gray-100 rounded-t-md">
                        <th>
                          <div className="flex w-min items-center pl-2 md:pl-4 py-2 font-semibold text-sm">
                            Date
                            <FontAwesomeIcon
                              className="cursor-pointer ml-1 text-xs md:text-sm"
                              icon={
                                toggleSort[0] <= 1 ? faChevronDown : faChevronUp
                              }
                              onClick={() => handleSortChange(0)}
                            />
                          </div>
                        </th>
                        <th>
                          <div className="flex w-min items-center pl-1 py-2 font-semibold text-sm">
                            Organization
                            <FontAwesomeIcon
                              className="cursor-pointer ml-1 text-xs md:text-sm"
                              icon={
                                toggleSort[1] <= 1 ? faChevronDown : faChevronUp
                              }
                              onClick={() => handleSortChange(1)}
                            />
                          </div>
                        </th>
                        <th>
                          <div className="flex w-min items-center py-2 font-semibold text-sm">
                            Category
                            <FontAwesomeIcon
                              className="cursor-pointer ml-1 text-xs md:text-sm"
                              icon={
                                toggleSort[2] <= 1 ? faChevronDown : faChevronUp
                              }
                              onClick={() => handleSortChange(2)}
                            />
                          </div>
                        </th>
                        <th>
                          <div className="flex w-full items-center md:pr-4 justify-end py-2 font-semibold text-sm">
                            Value
                            <FontAwesomeIcon
                              className="cursor-pointer ml-1 text-xs md:text-sm"
                              icon={
                                toggleSort[3] <= 1 ? faChevronDown : faChevronUp
                              }
                              onClick={() => handleSortChange(3)}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="max-w-full">
                      {shownTransactions.map((tr, j) => (
                        <tr
                          key={j}
                          className={`max-w-full cursor-pointer rounded-lg border-gray-200 hover:bg-white bg-gray-100 relative`}
                          onClick={() => setSelectedTransaction(tr["id"])}
                        >
                          <td className="py-2 pl-2 md:pl-4 rounded-l-lg">
                            <div className="flex w-full items-center text-sm">
                              {tr["date"].getDate() +
                                "." +
                                (tr["date"].getMonth() + 1) +
                                "." +
                                tr["date"].getFullYear()}
                              {tr["splitCreatorName"] ? (
                                <FontAwesomeIcon
                                  style={{ marginLeft: "1rem" }}
                                  icon={faRandom}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                          <td className="org py-2 pl-1 md:pl-3">
                            <div className="flex w-full items-center text-sm">
                              {tr["organization_name"]
                                ? tr["organization_name"]
                                : "-"}
                            </div>
                          </td>
                          <td className="cat py-2">
                            <div className="flex w-full items-center justify-start text-sm">
                              {tr["category"] ? tr["category"] : "-"}
                            </div>
                          </td>
                          <td className="py-2 pr-2 md:pr-3 rounded-r-lg">
                            <div className="flex w-full items-center justify-end text-sm">
                              {Math.round(tr["amount"] * 100) / 100} €
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        ))
      ) : (
        <div>
          <TransactionGroupDetails align={true}>
            <thead>
              <tr>
                <th>
                  Date
                  <FontAwesomeIcon
                    style={{ marginLeft: ".5rem", cursor: "pointer" }}
                    icon={toggleSort[0] <= 1 ? faChevronDown : faChevronUp}
                    onClick={() => handleSortChange(0)}
                  />
                </th>
                <th>
                  Organization
                  <FontAwesomeIcon
                    style={{ marginLeft: ".5rem", cursor: "pointer" }}
                    icon={toggleSort[1] <= 1 ? faChevronDown : faChevronUp}
                    onClick={() => handleSortChange(1)}
                  />
                </th>
                <th>
                  Category
                  <FontAwesomeIcon
                    style={{ marginLeft: ".5rem", cursor: "pointer" }}
                    icon={toggleSort[2] <= 1 ? faChevronDown : faChevronUp}
                    onClick={() => handleSortChange(2)}
                  />
                </th>
                <th>
                  Amount
                  <FontAwesomeIcon
                    style={{ marginLeft: ".5rem", cursor: "pointer" }}
                    icon={toggleSort[3] <= 1 ? faChevronDown : faChevronUp}
                    onClick={() => handleSortChange(3)}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {shownTransactions.map((tr, j) => (
                <tr
                  className="border-b-0 hover:bg-gray-200"
                  key={j}
                  onClick={() => setSelectedTransaction(tr["id"])}
                >
                  <td className="date">
                    {tr["date"].getDate() +
                      "." +
                      (tr["date"].getMonth() + 1) +
                      "." +
                      tr["date"].getFullYear()}
                  </td>
                  <td className="org">
                    {tr["organization_name"] ? tr["organization_name"] : "-"}
                  </td>
                  <td className="cat">
                    {tr["category"] ? tr["category"] : "-"}
                  </td>
                  <td className="amount">{tr["amount"]} €</td>
                </tr>
              ))}
            </tbody>
          </TransactionGroupDetails>
        </div>
      )}
    </div>
  );
};

export default TransactionsTable;
