import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const TagWrapper = ({name, className, onClick, onRemove}:{ name?: string, className?: string, onClick?: () => void,  onRemove?: () => void}) => {
    return (<div className={`px-2 py-1 bg-secondary items-center flex justify-center rounded-full cursor-pointer ${onClick ? " items-center text-sm h-7 " : ""} ` + className} onClick={onClick}>
        {
            name
        }
        {
            onClick && <FontAwesomeIcon icon={faPlus} className="text-primary cursor-pointer" size="sm"/>
        }
        {
            onRemove && <FontAwesomeIcon icon={faTimes} className="text-primary cursor-pointer ml-2" size="sm" onClick={onRemove}/>
        }
    </div>)
}

export default TagWrapper