import { ResponsiveLine } from "@nivo/line";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

export type LineGraphDataPoint = {
  x: string;
  y: number;
};

type LineWrapperProps = {
  data: LineGraphDataPoint[];
  label: string;
  bottomMargin?: number;
  topMargin?: number;
};

const LineWrapper = ({
  data,
  label,
  bottomMargin = 18,
  topMargin = 0,
}: LineWrapperProps) => {
  const [id, setid] = useState("");

  useEffect(() => {
    setid(uuid());
  }, []);

  const getTickValues = () => {
    if (!data) return 0;

    const n = 15.0;
    const frequency = Math.ceil(data?.length / n);

    return data.map((m) => m.x).filter((m, index) => index % frequency === 0);
  };

  return (
    <div className="relative w-full h-full">
      <div className="absolute w-full h-full">
        {/* @ts-ignore */}
        <ResponsiveLine
          key={uuid()}
          data={[
            {
              data: data,
              color: "white",
              id: id,
            },
          ]}
          sliceTooltip={({ slice }) => {
            return (
              <div className="bg-white rounded-lg px-5 py-3 flex flex-col">
                <div className="text-gray-700 text-sm">
                  {slice.points[0].data.xFormatted}
                </div>
                <div>
                  {label}: {slice.points[0].data.y + " €"}
                </div>
              </div>
            );
          }}
          margin={{ top: topMargin, right: 15, bottom: bottomMargin, left: 55 }}
          curve="linear"
          enablePoints={false}
          colors={"#0F497B"}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            reverse: false,
            stacked: false,
          }}
          axisBottom={{
            tickValues: getTickValues(),
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 25,
          }}
          axisTop={null}
          axisRight={null}
          lineWidth={5}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          areaOpacity={0}
          enableSlices="x"
          useMesh={true}
        />
      </div>
    </div>
  );
};

export default LineWrapper;
