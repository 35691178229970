import React, { useEffect, useState } from "react";
import cx from "classnames";
import Card from "../../atoms/Card";
import File from "../documents/File";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faChevronRight,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../utilities/Dropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import ClientService from "../../services/ClientService";
import AdvisorService from "../../services/AdvisorService";
import TOverview from "../../types/TOverview";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { htmlToPlain } from "../notes/Notes";
import ClassUtils from "../../utils/ClassUtils";
import { TCustomStatistics } from "../../types/TCustomStatistics";
import PieWrapper from "../../atoms/graphs/PieWrapper";
import StatsTransformer from "../../utils/StatsTransformer";
import BarWrapper from "../../atoms/graphs/BarWrapper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Overview = ({ className }: { className?: string }) => {
  const [pieState, setPieState] = useState("PRODUCT_CATEGORY");
  const [tempState, setTempState] = useState("EXPENSE");
  const [expenseWeekly, setExpenseWeekly] = useState(true);
  const [incomeWeekly, setIncomeWeekly] = useState(true);
  const [overView, setOverview] = useState<TOverview | null>(null);
  const [statistics, setStatistics] = useState<TCustomStatistics | null>(null);
  const navigate = useNavigate();
  const profile = useSelector(
    (state: RootState) => state.clients.selectedClient
  );
  const {t} = useTranslation()

  useEffect(() => {
    if (profile.id > 0) {
      ClientService.getClientOverview(profile.id)
        .then((res) => {
          setOverview(res);
          const monthAgo = new Date(Date.now());
          monthAgo.setMonth(monthAgo.getMonth() - 1);
          return AdvisorService.getCustomClientStatistics(
            profile.id,
            monthAgo,
            new Date(Date.now())
          );
        })
        .then((res) => setStatistics(res))
        .catch((e) => console.log(e));
    }
  }, [profile]);

  return (
    <div
      className={cx(
        "md:grid grid-cols-1 grid-rows-7 md:grid-cols-12 md:grid-rows-11 w-full h-full md:max-h-full gap-3 auto-rows-fr",
        className
      )}
    >
      <div className="md:grid grid-cols-1 md:grid-cols-5 grid-rows-3 col-span-6 row-span-7 md:row-span-5 md:gap-3 min-h-0">
        <div className="row-span-3 col-span-3 bg-white rounded-xl shadow-md stats flex flex-col pt-2 mb-3 md:mb-0">
          <div className="stat rounded-t-xl pt-2">
            <div className="stat-figure text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-users"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
            <div className="stat-title">{t('last_activity')}</div>
            <div className="stat-value text-2xl">
              {overView?.lastActivity
                ? DateTimeUtils.getDateString(overView.lastActivity).split('-')
                : "No activity"}
            </div>
          </div>
          <div className="stat pt-1">
            <div className="stat-figure text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-mail"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
            </div>
            <div className="stat-title">{t("n_notes")}</div>
            <div className="stat-value">{overView?.noteCount}</div>
          </div>
          <div className="stat pt-1">
            <div className="stat-figure text-yellow-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-alert-circle"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="12"></line>
                <line x1="12" y1="16" x2="12.01" y2="16"></line>
              </svg>
            </div>
            <div className="stat-title">{t("approaching_notes")}</div>
            <div className="stat-value">{overView?.approachingNoteCount}</div>
          </div>
          <div className="stat pt-1">
            <div className="stat-figure text-info">
              <FontAwesomeIcon
                icon={faCheckCircle}
                size={"2x"}
                className="text-green-400"
              />
            </div>
            <div className="stat-title">{t("fulfilled_notes")}</div>
            <div className="stat-value">{overView?.fulfilledNoteCount}</div>
          </div>
        </div>
        <div className="col-span-2 row-span-1 bg-white rounded-xl shadow-md stat mb-3 md:mb-0">
          <div className="w-full flex justify-between items-center max-w-full">
            <p className="stat-title">
              {t('expense')}
            </p>
            <div
              className="h-5 w-5 rounded-full flex justify-center items-center cursor-pointer text-xs border-gray-400 text-gray-400 border"
              onClick={() => setExpenseWeekly(!expenseWeekly)}
            >
              {expenseWeekly ? 7 : 30}
            </div>
          </div>
          <div
            className={cx(
              "stat-value max-w-full",
              ClassUtils.getPriceSizeClass(
                expenseWeekly ? overView?.weekExpense : overView?.monthExpense
              )
            )}
          >
            {overView?.monthExpense && (
              <>
                {expenseWeekly
                  ? Math.round(overView?.weekExpense!)  + " "
                  : Math.round(overView?.monthExpense!) + " "}
              </>
            )}
            {" €"}
          </div>
        </div>
        <div className="col-span-2 row-span-1 bg-white rounded-xl shadow-md stat mb-3 md:mb-0">
          <p className="stat-title flex justify-between items-center">
            {t('income')}
            <div
              className="h-5 w-5 rounded-full flex justify-center items-center cursor-pointer text-xs border-gray-400 border"
              onClick={() => setIncomeWeekly(!incomeWeekly)}
            >
              {incomeWeekly ? 7 : 30}
            </div>
          </p>
          <div
            className={cx(
              "stat-value",
              ClassUtils.getPriceSizeClass(
                incomeWeekly ? overView?.weekIncome : overView?.monthIncome
              )
            )}
          >
            {overView?.monthIncome && (
              <>
                {incomeWeekly
                  ? Math.round(overView?.weekIncome!)  + " "
                  : Math.round(overView?.monthIncome! )  + " "}
              </>
            )}
            {" €"}
          </div>
        </div>
        <div className="col-span-2 row-span-1 bg-white rounded-xl shadow-md stat mb-3 md:mb-0">
          <p className="stat-title">{t('balance')}</p>
          <div
            className={cx(
              "stat-value",
              ClassUtils.getPriceSizeClass(overView?.balance)
            )}
          >
            {overView?.balance && Math.round(overView?.balance! ) } €
          </div>
        </div>
      </div>
      <div className="col-span-6 row-span-6 md:grid grid-cols-1 grid-rows-2 gap-3 min-h-0 mb-3 md:mb-0">
        <div className="bg-white rounded-xl flex flex-col px-4 min-h-0 shadow-md h-50-vh md:h-auto ">
          <div className="mt-3 pb-2 flex justify-between items-center">
            <div className="text-xl">{t("close_notes")}</div>
            <div className="text-xs pr-1">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="ml-2 cursor-pointer"
                onClick={() => {
                  navigate("/clients/notes");
                }}
              />
            </div>
          </div>
          <div className="w-full max-h-full flex flex-col overflow-y-auto pb-2 pr-1 scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-transparent hover:scrollbar-thumb-gray-700">
            <>
              {overView?.notes.length == 0 ? (
                <div className="w-full h-full text-gray-400 flex justify-center items-center">
                  {t("no_close_notes")}
                </div>
              ) : (
                <>
                  {overView?.notes.map((n) => (
                    <div className="w-full bg-red-400 py-1 px-2 mb-1 rounded-md pb-1 text-white pl-3 flex justify-between">
                      <div>{htmlToPlain(n.text!!.slice(0, 30)) + "..."}</div>
                      <div>{n.notePeriods[n.notePeriods.length - 1].end}</div>
                    </div>
                  ))}
                </>
              )}
            </>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-md flex flex-col px-4 min-h-0 mt-3 md:mt-0 h-50-vh md:h-auto ">
          <div className="mt-3 mb-2 flex justify-between items-center">
            <div className="text-xl">{t('documents')}</div>
            <div className="text-xs pr-1">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="ml-2 cursor-pointer"
                onClick={() => {
                  navigate("/clients/documents");
                }}
              />
            </div>
          </div>
          <div className="w-full h-full max-h-full flex flex-col overflow-y-auto mb-2 pr-1 scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-transparent hover:scrollbar-thumb-gray-700">
            {overView?.documents && overView?.documents.length > 0 ? (
              overView?.documents.map((d) => (
                <div className="w-full bg-gray-100 py-1 px-1 rounded-md mb-1 text-gray-700 flex justify-between">
                  <div className="flex">
                    <FontAwesomeIcon
                      icon={faFile}
                      className="text-gray-400 ml-2 mr-2"
                    />
                    {d.name}
                  </div>
                  {DateTimeUtils.getDateString(d.modifiedAt!)}
                </div>
              ))
            ) : (
              <div className="w-full h-full flex justify-center items-center text-gray-400">
                {t("no_documents")}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-6 row-span-6 bg-white rounded-xl shadow-md min-h-0 h-50-vh md:h-auto mb-3 md:mb-0">
        <div className="w-full flex justify-between items-center px-4 py-2">
          <div></div>
          <div className="text-sm">
            <Dropdown
              items={[
                {
                  title: t('categories'),
                  value: "CATEGORY",
                  selected: pieState === "CATEGORY",
                },
                {
                  title:  t('organizations'),
                  value: "ORGANIZATION",
                  selected: pieState === "ORGANIZATION",
                },
                {
                  title:  t('units'),
                  value: "UNIT",
                  selected: pieState === "UNIT",
                },
                {
                  title:  t('products'),
                  value: "PRODUCT_CATEGORY",
                  selected: pieState === "PRODUCT_CATEGORY",
                },
                {
                  title:  t('tags'),
                  value: "TAG",
                  selected: pieState === "TAG",
                },
              ]}
              onChange={(s) => {
                setPieState(s);
              }}
            />
          </div>
        </div>
        <div className="w-full h-full">
          {statistics != null && (
            <>
              {statistics.categories.length == 0 ? (
                <div className="w-full h-full flex justify-center items-center text-gray-400 text-sm">
                  {t("no_transactions")}
                </div>
              ) : (
                <PieWrapper
                  data={StatsTransformer.prototype.customPieTransform(
                    statistics,
                    "",
                    pieState
                  )}
                  leftMargin={20}
                  rightMargin={200}
                  showLegend
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="col-span-6 row-span-5 bg-white rounded-xl shadow-md min-h-0 h-50-vh md:h-auto ">
        <div className="w-full flex justify-between items-center px-4 py-2">
          <div></div>
          <div className="text-sm">
            <Dropdown
              items={[
                {
                  title: t("client_spending"),
                  selected: tempState === "EXPENSE",
                  value: "EXPENSE",
                },
                {
                  title: t("client_income"),
                  selected: tempState === "INCOME",
                  value: "INCOME",
                },
              ]}
              onChange={(s) => {
                setTempState(s);
              }}
            />
          </div>
        </div>
        {statistics && (
          <>
            {statistics.chart.points.length == 0 ? (
              <div className="w-full h-full flex justify-center items-center text-gray-400 text-sm">
                {t("no_transactions")}
              </div>
            ) : (
              <BarWrapper
                data={StatsTransformer.prototype
                  .customLineTransform(statistics, "", tempState)
                  .datasets[0].data.map((d) => ({
                    x: d.x,
                    y: parseFloat(d.y),
                  }))}
                label={tempState}
                bottomMargin={80}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Overview;
