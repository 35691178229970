import React from 'react'
import { NavLink } from 'react-router-dom'

type ClientNavButtonProps = {
    value: string,
    to: string,
    first?: boolean,
    end?: boolean
}

const ClientNavButton = ({value, to, first, end}: ClientNavButtonProps) => {
    return (
        <li className={"w-full "}>
          <NavLink
            end={end}
            className={({isActive}) => `pl-4 my-1 py-3 w-full h-full font-medium rounded-md flex items-center  ${ isActive ? "bg-secondary text-primary-focus" : "hover:bg-gray-100"}`}
            to={to}
          >
            {value}
          </NavLink>
        </li>
    )
}

export default ClientNavButton