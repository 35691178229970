
  
  const ClassUtils = {
    getPriceSizeClass: (price: number | undefined) => {
        if (price == undefined) {
            return ""
        }
        if ((price > 1000 &&  price < 10000) || (price < -100 &&  price > -1000)) {
            return "text-3xl"
        } else if ((price > 10000 &&  price < 100000) || (price < -1000 &&  price > -10000)) {
            return "text-2xl" 
        } else if (price > 100000 || (price < -10000) ){
            return "text-xl"
        } else {
            return ""
        }
    },
    
  };
  
  export default ClassUtils;
  