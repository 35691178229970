import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import DocumentService from '../services/DocumentService'
import { AppDispatch, AppThunk } from '../store'
import DocumentData from '../types/DocumentData'


const defaultNode: DocumentData = {
    id: 0,
    note: "",
    name: '/', 
    parentId: 0, 
    children: [], 
    attributes: [],
    attachments: [],
    parent: undefined, 
    visibleAdvisor: true,
    visibleClient: false,
    folder: true,
    createdAt: undefined,
    modifiedAt: undefined
}

const initialState = {
    isUploading: false,
    isUploaded: false,
    error: {message: ""},
    rootNode: defaultNode,
    currentNode: defaultNode
}

const documentSlice = createSlice({
    name: "document",
    initialState,
    reducers: {
        receiveRootNode(state, action: PayloadAction<DocumentData>) {
            var newState = {...state, rootNode: action.payload}
            return newState
        },
        receiveCurrentNode(state, action: PayloadAction<DocumentData>) {
            var newState = {...state, currentNode: action.payload}
            return newState
        },
        receiveError(state, action: PayloadAction<string>) {
            var newState = {...state, error: {message: action.payload}}
            return newState
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            var newState = {...state, isUploading: action.payload}
            return newState
        },
        setIsLoaded(state, action: PayloadAction<boolean>) {
            var newState = {...state, isUploaded: action.payload}
            return newState
        }
    }
})

export const findCurrFolder = (cf: DocumentData, id: number): DocumentData | undefined => {
    if (cf.id === id) {
      return cf
    } else {
      for (const c of cf.children!!) {
        var temp = findCurrFolder(c, id)
        if (temp) {
          return temp
        } 
      }
    }
}

export const addFolderParents = (currFolder: DocumentData, prevFolder: DocumentData | undefined): DocumentData => {
    var cf = {...currFolder}
    cf.parentId = prevFolder?.id
    cf.children = cf.children!!.map(f => addFolderParents(f, cf))
    return cf
  }

export const loadRootNode = (clientId: number): AppThunk => async (dispatch: AppDispatch) => {
    try {
        var documentRoot = await DocumentService.retrieveFiles(clientId)
        documentRoot = addFolderParents(documentRoot!!, undefined)
        dispatch(documentSlice.actions.receiveRootNode(documentRoot))
        dispatch(setCurrentNode(documentRoot))
        dispatch(documentSlice.actions.receiveError(""))
    } catch (e: any) {
        dispatch(documentSlice.actions.receiveError(e))
    }
}

export const setCurrentNode = (currNode: DocumentData): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(documentSlice.actions.receiveCurrentNode(currNode))
}

export const createNewFolder = (clientId: number, currNode: DocumentData, newFolder: DocumentData, attachments?: any[]): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(documentSlice.actions.setIsLoading(true))
    await DocumentService.newFolder(clientId, newFolder, attachments)
    dispatch(reload(clientId, currNode))
    dispatch(documentSlice.actions.setIsLoading(false))
    dispatch(documentSlice.actions.setIsLoaded(true))
}

export const deleteFile = (clientId: number, docId: number, currNode: DocumentData): AppThunk => async (dispatch: AppDispatch) =>  {
    await DocumentService.deleteFile(docId)
    dispatch(reload(clientId, currNode))
}

export const deleteAttachment = (clientId: number, attId: number, currNode: DocumentData): AppThunk => async (dispatch: AppDispatch) =>  {
    await DocumentService.deleteAttachment(attId)
    dispatch(reload(clientId, currNode))
}

export const editFile = (clientId: number, currNode: DocumentData, file: DocumentData, attachments?: any): AppThunk => async (dispatch: AppDispatch) =>  {
    dispatch(documentSlice.actions.setIsLoading(true))
    await DocumentService.editFile(file, attachments)
    dispatch(reload(clientId, currNode))
    dispatch(documentSlice.actions.setIsLoading(false))
    dispatch(documentSlice.actions.setIsLoaded(true))
}

export const reload = (clientId: number, currNode: DocumentData): AppThunk => async (dispatch: AppDispatch) =>  {
    var newRootFolder = await DocumentService.retrieveFiles(clientId)
    newRootFolder = addFolderParents(newRootFolder, undefined)
    var newCurrFolder = findCurrFolder(newRootFolder, currNode.id!!)
    dispatch(documentSlice.actions.receiveRootNode(newRootFolder))
    if (newCurrFolder) {
        dispatch(documentSlice.actions.receiveCurrentNode(newCurrFolder))
    } else {
        dispatch(documentSlice.actions.receiveCurrentNode(newRootFolder))
    }
}

export default documentSlice.reducer