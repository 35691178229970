import DocumentData, { Attachment } from '../types/DocumentData'
import getAxios from './api'

const FileDownload = require('js-file-download');

const DocumentService = {
    newFolder: async (userId: number, folder: DocumentData, attachments?: any[]) => {
        const formData = new FormData();
        formData.append('documentProperties', new Blob([JSON.stringify(folder)], {type: 'application/json'}))

        if (attachments && attachments.length > 0) {
            attachments.forEach((f) => {
                formData.append('files',f)
            })
        }

        await getAxios().post(
            'files/?userId=' + userId, 
            formData,
            {headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                "Content-type": `multipart/form-data`
            }}
        )

    },
    retrieveFiles: async (userId: number) => {
        try {
            const res = await getAxios().get('files/?userId=' + userId, {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})

            return {
                ...res.data
            } as DocumentData
        } catch (e: any) {
            throw Error("Error while retrieving data")
        }
    },
    deleteFile: async (docId: number) => {
        await getAxios().delete('files/document/?documentId=' + docId, {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
    },
    retrieveZip: async (userId: number, docId: number) => {
        const res = await getAxios().get('files/' + userId + "/attachments/?id=" + docId, {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
        FileDownload(res.data, docId + ".zip");
    },
    editFile: async (file: DocumentData, attachments?: any[]) => {
        const formData = new FormData();

        if (attachments && attachments.length > 0) {
            attachments.forEach((f) => {
              formData.append('files',f)
            })
          }

        formData.append('documentProperties', new Blob([JSON.stringify({
            id: file.id,
            name: file.name,
            note: file.note,
            visibleAdvisor: true,
            visibleClient: file.visibleClient
        })], {type: 'application/json'}))
        
        await getAxios().put('files/', formData ,{headers: {
            "Content-type": `multipart/form-data`,
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
          }})
    },
    editAttachment: async (attachment: Attachment) => {
        var newAttachment = await getAxios().put('files/attachment', attachment, {headers: {
            "Content-type": `multipart/form-data`,
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
          }})

        return newAttachment.data as Attachment
    },
    downloadFile: async (userId: number, attachmentId: number, filename: string) => {
        var res = await getAxios().get('files/attachment/?attachmentId=' + attachmentId, {responseType: 'blob',headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        
        FileDownload(res.data, filename);
    },
    deleteAttachment: async (attachmentId: number) => {
        await getAxios().delete('files/attachment/?attachmentId=' + attachmentId, {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
    }
}

export default DocumentService