import cx from "classnames";
import * as React from "react";

interface IProps {
  hasError?: boolean;
}

export const Input = ({
  className,
  hasError,
  ...props
}: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  IProps) => (
  <input
    className={cx(
      "base-input  border-2 focus:border-primary-dark hover:border-primary-dark cursor-text",
      className,
      {
        "border-error": hasError,
        "bg-gray-100": props.disabled,
      }
    )}
    {...props}
  />
);

export default Input;
