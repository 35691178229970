import cx from "classnames";

export interface TableRowProps {
  className?: string;
  tableCells: { content: React.ReactNode; className?: string }[];
}

export const TableRow = ({ className, tableCells }: TableRowProps) => {

  return (
    <tr className={cx(className, "rounded-lg bg-gray-100 text-white")}>
      {tableCells.map((tableCell, i) => (
        <td
          key={i}
          className={cx(tableCell.className, "pl-3 md:pl-5 py-4 text-black", {
            "rounded-l-lg": i === 0,
            "rounded-r-lg": i === tableCells.length - 1,
          })}
        >
          {tableCell.content}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
