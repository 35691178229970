import React, { useEffect } from "react";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RuleList from "../components/RuleList";
import AdvisorService from "../services/AdvisorService";
import ProductCategory from "../types/tags/ProductCategory";
import Rule from "../types/tags/Rule";
import Property from "../types/tags/Property";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import ClientDetails from "../types/ClientDetails";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  const [userRuleList, setUserRuleList] = useState([] as Rule[]);
  const [generalRuleList, setGeneralRuleList] = useState([] as Rule[]);
  const [productCategories, setProductCategories] = useState(
    [] as ProductCategory[]
  );
  const [categories, setCategories] = useState([] as string[]);
  const [properties, setProperties] = useState([] as Property[]);
  const [assignedUser, setAssignedUser] = useState(
    {} as ClientDetails | undefined
  );
  const [loading, setLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(true);
  const { t } = useTranslation();

  const loadSettings = () => {
    setLoading(true);
    AdvisorService.getSettings()
      .then((res) => {
        setAssignedUser(res.assignedUser);
        setProductCategories(res.productCategories);
        setCategories(res.categories);
        setUserRuleList(res.userRules);
        setGeneralRuleList(res.generalRules);
        setProperties(res.properties);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    const resizeAction = (e: Event) => {
      if (window.innerWidth <= 768 && isMobile === false) {
        setIsMobile(true);
      } else if (window.innerWidth > 768 && isMobile === true) {
        setIsMobile(false);
      }
    };

    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else if (window.innerWidth > 768) {
      setIsMobile(false);
    }

    window.addEventListener("resize", (e) => resizeAction(e));

    loadSettings();

    return window.removeEventListener("resize", (e) => resizeAction(e));
  }, [isMobile]);

  return (
    <div className="w-screen h-full-body max-h-full-body md:pt-16 flex flex-col bg-background">
      <div className="w-screen md:w-3/4 lg:w-4/6 mx-auto flex-grow h-full max-h-full">
        <div className="flex max-h-full h-full">
          <div
            className={`md:w-3/6 shadow rounded-lg h-full w-screen pt-4 bg-white ${
              showMobileMenu ? "block" : "hidden md:block"
            }`}
          >
            <div className="text-3xl py-4 px-4 md:px-8 ">
              <h1>{t('settings')} </h1>
            </div>
            <div className="w-full">
              <div
                className={`${
                  selectedMenuItem === 0 ? "bg-primary text-white" : ""
                } rounded-xl cursor-pointer px-4 mx-4 mb-4 h-14 flex items-center `}
                onClick={() => {
                  setSelectedMenuItem(0);
                  setShowMobileMenu(false);
                }}
              >
                {t('general_rules')}
              </div>
              {assignedUser && assignedUser.id > 0 && (
                <div
                  className={`${
                    selectedMenuItem === 1 ? "bg-primary text-white" : ""
                  } rounded-xl cursor-pointer px-4 mx-4 h-14 flex items-center `}
                  onClick={() => {
                    setSelectedMenuItem(1);
                    setShowMobileMenu(false);
                  }}
                >
                  {t('my_rules')}
                </div>
              )}
            </div>
          </div>
          <div
            className={`w-full h-screen md:h-full flex flex-col justify-start items-center m-0 p-0 shadow rounded-lg md:ml-8 bg-white md:pt-16 ${
              showMobileMenu ? "hidden md:block" : "block"
            }`}
          >
            <div
              className={`flex justify-start items-center w-full ml-2 pt-8 ${
                !showMobileMenu ? "block md:hidden" : "hidden"
              }`}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                size="2x"
                color="#00497b"
                style={{ marginLeft: "1rem", width: "1rem" }}
                onClick={() => setShowMobileMenu(true)}
              />

              {selectedMenuItem === 0 && (
                <h1 className="text-2xl ml-2">{t('general_rules')}</h1>
              )}
              {selectedMenuItem === 1 && (
                <h1 className="text-2xl ml-2">{t('my_rules')}</h1>
              )}
            </div>

            <div className="flex w-full px-4 h-full md:max-h-full overflow-y-auto">
              {!loading ? (
                <>
                  {selectedMenuItem === 1 ? (
                    <RuleList
                      userSpecific={true}
                      ruleList={userRuleList}
                      setRuleList={(v) => setUserRuleList(v)}
                      properties={properties}
                      categories={categories}
                      productCategories={productCategories}
                      userId={assignedUser?.id}
                      onReload={() => loadSettings()}
                    />
                  ) : (
                    <RuleList
                      userSpecific={false}
                      ruleList={generalRuleList}
                      setRuleList={(v) => setGeneralRuleList(v)}
                      properties={properties}
                      categories={categories}
                      productCategories={productCategories}
                      onReload={() => loadSettings()}
                    />
                  )}
                </>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader type="Oval" color="#0F497B" height={50} width={50} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
