import {
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faSync,
  faPen,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import ClientService from "../../services/ClientService";
import TagService from "../../services/TagService";
import TransactionDetails, {
  ProductItem,
  TCategory,
} from "../../types/TransactionDetails";
import Modal from "../../atoms/Modal";
import Table from "../../atoms/Table";
import DetailsCard from "../../atoms/DetailsCard";
import Tag from "../../types/tags/Tag";
import TagWrapper from "../../atoms/TagWrapper";
import Dialog from "../utilities/Dialog";
import Input from "../../atoms/Input";
import cx from "classnames";
import ProductCategory from "../../types/tags/ProductCategory";
import { categoryList } from "../../utils/constants";
import TTransactionImage from "../../types/TTransactionImage";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

type TableDatum = {
  quantity: number;
  product: string;
  category: string;
  tags: Tag[];
  price: number;
};

const TransactionModal = ({
  setSelectedTransaction,
  transactionId,
  isOpen,
  onClose,
}: {
  setSelectedTransaction: (id: number) => void;
  transactionId: number;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [transactionDetails, setTransactionDetails] = useState(
    {} as TransactionDetails
  );
  const [productsToggle, setProductsToggle] = useState(true);
  const [unitToggle, setUnitToggle] = useState(false);
  const [orgToggle, setOrgToggle] = useState(false);
  const [newTag, setNewTag] = useState({ productId: -1, name: "" });
  const [selectedProduct, setSelectedProduct] = useState<ProductItem | null>(
    null
  );
  const [transactionImages, setTransactionImages] = useState<
    TTransactionImage[]
  >([]);
  const selectedUser = useSelector(
    (node: RootState) => node.clients.selectedClient
  );
  const [tableData, setTableData] = useState([] as TableDatum[]);
  const { t } = useTranslation();

  useEffect(() => {
    if (transactionId > 0) {
      setSelectedProduct(null);
      setTransactionDetails({} as TransactionDetails);
      setSelectedTransaction(transactionId);
      loadTransactionDetails();
      loadTransactionImages();
    }

    return () => {
      setSelectedProduct(null);
      setTransactionDetails({} as TransactionDetails);
      setSelectedTransaction(-1);
      setTransactionImages([]);
      setUnitToggle(false);
      setOrgToggle(false);
      setProductsToggle(true);
    };
  }, [transactionId]);

  const reloadCategories = () => {
    ClientService.reloadCategories(transactionDetails.fsReceipt.id)
      .then(() => {
        loadTransactionDetails()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadTransactionDetails = () => {
    ClientService.getTransactionDetails(transactionId)
      .then((res) => {
        setTransactionDetails(res);
        if (selectedProduct) {
          setSelectedProduct(
            (res as TransactionDetails).fsReceipt.productItems.find(
              (pi) => pi.id === selectedProduct.id
            )!
          );
        }
        setTableData(
          res.fsReceipt.productItems.map((t: ProductItem) => ({
            quantity: t.quantity,
            product: t.product.name,
            category: (
              <div
                onClick={() => {
                  setSelectedProduct(t);
                }}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  className="text-xs mr-2 cursor-pointer"
                />
                {t.product.category ? t.product.category : ""}
              </div>
            ),
            tags: (
              <div className="flex max-w-full flex-wrap">
                {" "}
                {[
                  ...t.tags.map((tag: Tag) => (
                    <TagWrapper
                      name={tag.name}
                      className="mr-1 mb-1"
                      onRemove={() => removeTag(tag.id!, t.id)}
                    />
                  )),
                  <TagWrapper
                    onClick={() => {
                      setNewTag({
                        productId: t.id,
                        name: "",
                      });
                    }}
                  />,
                ]}
              </div>
            ),
            price: (
              <div className="w-20">
                {Math.round(t.product.price * t.quantity * 100) / 100 + " €"}
              </div>
            ),
          }))
        );
        setSelectedProduct(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadTransactionImages = () => {
    ClientService.getTransactionImages(transactionId)
      .then((res) => {
        setTransactionImages(res);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const deleteImage = (id: number) => {
    ClientService.deleteImage(id)
      .then((res) => loadTransactionImages())
      .catch((err) => console.log(err));
  };

  const createTag = () => {
    TagService.createTag(newTag.name, newTag.productId, selectedUser.id)
      .then(() => {
        setNewTag({ productId: -1, name: "" });
        loadTransactionDetails();
      })
      .catch((e) => console.log(e));
  };

  const removeTag = (tagId: number, productId: number) => {
    TagService.removeProductTag(tagId, productId)
      .then(() => {
        console.log("removinf " + tagId);
        loadTransactionDetails();
      })
      .catch((e) => console.log(e));
  };

  const updateProductCategory = (category: string) => {
    ClientService.updateProductCategory(selectedProduct?.product.id!!, category)
      .then(() => {
        loadTransactionDetails();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="min-h-50-rem max-h-50-rem md:max-w-5xl px-4 md:px-8"
      header={
        <div className="flex">
          {selectedProduct !== null ? (
            <div className="flex">
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="mr-2 cursor-pointer"
                onClick={() => setSelectedProduct(null)}
              />
              {t("category_for")} {selectedProduct.product.name}
            </div>
          ) : (
            t("transaction") +
            " " +
            (transactionDetails?.splits &&
            transactionDetails?.splits?.length > 0
              ? "(" + t("split") + ")"
              : "")
          )}
        </div>
      }
    >
      <Dialog
        isOpen={newTag.productId > 0}
        type="new tag"
        color="green"
        content={
          <>
            <Input
              className="w-full px-4 py-3 rounded-md"
              placeholder="Tag name"
              value={newTag.name}
              onChange={(e) =>
                setNewTag({
                  productId: newTag.productId,
                  name: e.target.value,
                })
              }
            />
          </>
        }
        onConfirm={() => createTag()}
        onCancel={() =>
          setNewTag({
            productId: -1,
            name: "",
          })
        }
      />
      {transactionDetails.totalPrice > 0 && (
        <AnimatePresence initial={false}>
          {selectedProduct == null ? (
            <motion.div 
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            className="mx-4">
              <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 grid-rows-3 md:grid-rows-1 gap-4">
                <div className="h-32 p-5 rounded-lg shadow-md bg-gray-300 text-gray-700 font-semibold flex flex-col">
                  <div className="h-min font-medium">{t("place")}</div>
                  <div className="text-xl flex-grow flex items-center">
                    {transactionDetails.fsReceipt.organization.name}
                  </div>
                </div>
                <div className="h-32 p-5 rounded-lg shadow-md bg-gray-300 text-gray-700 font-semibold flex flex-col">
                  <div className="var font-medium">{"date_time"}</div>
                  <div className="text-2xl flex-grow flex flex-col justify-center">
                    <div>
                      {transactionDetails.fsReceipt.issueDate.split(" ")[0]}
                    </div>
                    <div>
                      {transactionDetails.fsReceipt.issueDate.split(" ")[1]}
                    </div>
                  </div>
                </div>
                <div className="h-32 p-5 rounded-lg shadow-md bg-gray-300 text-gray-700 font-semibold flex flex-col">
                  <div className="var font-medium">{t("total_price")}</div>
                  <div className="text-3xl flex-grow flex items-center">
                    {`${
                      Math.round(transactionDetails.totalPrice * 100) / 100
                    } €`}
                  </div>
                </div>
              </div>
              <div
                className="flex justify-between cursor-pointer mt-4 items-center"
                onClick={() => setProductsToggle(!productsToggle)}
              >
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={productsToggle ? faChevronUp : faChevronDown}
                    className="mr-2"
                  />
                  <div className="text-lg">{t("products")}</div>
                </div>
                <FontAwesomeIcon icon={faSync} className="mr-2" onClick={() => reloadCategories()}/>
              </div>
              {productsToggle && (
                <Table
                  columns={[
                    { field: "quantity", header: t("quantity") },
                    { field: "product", header: t("product") },
                    { field: "category", header: t("category") },
                    { field: "tags", header: t("tags") },
                    { field: "price", header: t("price") },
                  ]}
                  rows={tableData}
                />
              )}
              {transactionImages.length > 0 && (
                <>
                  <div className="text-lg mb-2">{t("images")}</div>
                  <div className="flex flex-wrap bg-gray-100 py-4 rounded-lg">
                    {transactionImages.map((i) => (
                      <div className="p-3 w-1/3">
                        <div className="w-full flex justify-center items-center relative p-1">
                          <img src={`data:image/jpeg;base64,${i.image}`} />
                          <div
                            onClick={() => deleteImage(i.id)}
                            className="w-4 h-4 absolute top-0 right-0 rounded-full flex justify-center items-center bg-white cursor-pointer"
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="text-xl "
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <div
                className="flex cursor-pointer my-2 items-center"
                onClick={() => setUnitToggle(!unitToggle)}
              >
                <FontAwesomeIcon
                  icon={unitToggle ? faChevronUp : faChevronDown}
                  className="mr-2"
                />
                <div className="text-lg">{t("unit_details")}</div>
              </div>
              {unitToggle && (
                <DetailsCard
                  keys={Object.keys(transactionDetails.fsReceipt.orgUnit)}
                  values={Object.values(
                    transactionDetails.fsReceipt.orgUnit
                  ).map((v) => (v ? v?.toString() : ""))}
                />
              )}
              <div
                className="flex cursor-pointer my-2 items-center"
                onClick={() => setOrgToggle(!orgToggle)}
              >
                <FontAwesomeIcon
                  icon={orgToggle ? faChevronUp : faChevronDown}
                  className="mr-2"
                />
                <div className="text-lg">{t("organization_details")}</div>
              </div>
              {orgToggle && (
                <DetailsCard
                  keys={Object.keys(transactionDetails.fsReceipt.organization)}
                  values={Object.values(
                    transactionDetails.fsReceipt.organization
                  ).map((v) => (v ? v?.toString() : ""))}
                />
              )}
            </motion.div>)
            :
              <motion.div
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -300, opacity: 0 }}
                className={`h-full bg-gray-200 rounded-xl px-4 py-4 mx-4 `}
              >
                <h1 className="text-lg">{t("recommended_categories")}</h1>
                <div className="flex w-full justify-between mt-2">
                  {selectedProduct.product.categories.map((c) => (
                    <div
                      onClick={() => {
                        updateProductCategory(c.category);
                        setSelectedProduct(null);
                      }}
                      className={cx(
                        " w-full md:w-1/3  hover:bg-gray-400 flex justify-center items-center py-3 mx-2 rounded-xl cursor-pointer",
                        selectedProduct.product.category === c.category
                          ? "bg-gray-400"
                          : "bg-gray-300"
                      )}
                    >
                      {c.category}
                    </div>
                  ))}
                </div>
                <h1 className="text-lg mt-6">{t("other_categories")}</h1>
                <div className="flex w-full mt-2 flex-wrap justify-start">
                  {categoryList.map((c) => (
                    <div className="px-2 pb-2 w-full md:w-1/3">
                      <div
                        onClick={() => {
                          updateProductCategory(c);
                          setSelectedProduct(null);
                        }}
                        className={cx(
                          "w-full bg-gray-300  hover:bg-gray-400 flex justify-center items-center py-3 rounded-xl cursor-pointer"
                        )}
                      >
                        {c}
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            })
        </AnimatePresence>
      )}
      
    </Modal>
  );
};

export default TransactionModal;
