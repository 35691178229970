import { useEffect } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import NotesModal from './NotesModal';
import NotesService from '../../services/NotesService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { loadClientNotes } from '../../slices/noteSlice';
import Note from '../../types/Note';
import Dialog from '../utilities/Dialog';
import MasterContainerHeader from '../../atoms/MasterContainerHeader';
import Button from '../../atoms/Button';
import { useTranslation } from 'react-i18next';


const sortByEndDate = (note: Note[]) => {
    var notes = [...note]
    if (notes.length > 0) {
        var sortedNotes = notes.sort((a: Note, b: Note) => {
            var endA = new Date(a.notePeriods[a.notePeriods.length-1].end)
            var endB = new Date(b.notePeriods[b.notePeriods.length-1].end)

            if (endA > endB) {
                return 1
            } else {
                return -1
            }
        })
    } else return notes

    return sortedNotes
}

export const htmlToPlain = (html: string): string => {
    var text = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    text = text.replace(/<script([\s\S]*?)<\/script>/gi, '');
    text = text.replace(/<\/div>/ig, '\n');
    text = text.replace(/<\/li>/ig, '\n');
    text = text.replace(/<li>/ig, '  *  ');
    text = text.replace(/<\/ul>/ig, '\n');
    text = text.replace(/<\/p>/ig, '\n');
    text = text.replace(/<br\s*[/]?>/gi, "\n");
    text = text.replace(/<[^>]+>/ig, '');
    return text
}

const Notes = ({className}: {className? : string}) => {

    const [selectedNote, setSelectedNote] = useState(-2) // undefined
    const dispatch = useDispatch()
    const notes = useSelector((state: RootState) => sortByEndDate(state.note.selectedClientNotes))
    const selectedClient = useSelector((state: RootState) => state.clients.selectedClient)
    const [removalDialog, toggleRemovalDialog] = useState(false)
    const [noteId, setNoteId] = useState(-1)
    const {t} = useTranslation()

    useEffect(() => {
        if (selectedClient.id !== -1) {
            dispatch(loadClientNotes(selectedClient.id))
        }
    },[selectedClient.id, dispatch])

    const handleNewNote = () => {
        setSelectedNote(-1)
    }

    const handleNoteRemoval = (id: number) => {
        NotesService.deleteNote(id)
            .then(() => {
                dispatch(loadClientNotes(selectedClient.id))
            })
    }

    const getColor = (note: Note): string => {
        const now = new Date()
        var lastNoteDate

        if (note.type === "MONTHLY" ) {
            lastNoteDate = new Date(note.notePeriods[note.notePeriods.length-1].end)
            lastNoteDate.setDate(lastNoteDate.getDate()-7)
            if (lastNoteDate > now) {
                return "rgb(40, 128, 0)"
            } else {
                return "rgb(196, 86, 90)"
            }

        } else if (note.type === "WEEKLY") {
            lastNoteDate = new Date(note.notePeriods[note.notePeriods.length-1].end)
            lastNoteDate.setDate(lastNoteDate.getDate()-2)
            if (lastNoteDate >= now) {
                return "rgb(40, 128, 0)"
            } else {
                return "rgb(196, 86, 90)"
            }
        } else {
            lastNoteDate = new Date(note.notePeriods[note.notePeriods.length-1].end)
            lastNoteDate.setDate(lastNoteDate.getDate()-5)
            if (lastNoteDate >= now) {
                return "rgb(40, 128, 0)"
            } else {
                return "rgb(196, 86, 90)"
            }
        }
    }

    return (
        <div className={"shadow-md rounded-xl bg-white min-h-screen md:min-h-full " + className}> 
          <MasterContainerHeader header={t("notes")} menuIcons={
              <Button
                  menuIcon
                  shadow={false}
                  bgColor="bg-primary"
                  textColor="text-white"
                  hoverColor="bg-primary-dark"
                  className="mr-2 w-max"
                  value={t('new_note')}
                  onClick={() => handleNewNote()} 
                />
          }/>
          <div>
            <div>
                <Dialog isOpen={removalDialog} color="red" type="Remove note" content={t("remove_note_question") + ""} onConfirm={() => {handleNoteRemoval(noteId); toggleRemovalDialog(false)}} onCancel={() => toggleRemovalDialog(false)} />
                {
                    notes.map(note => (
                        <div key={note.id} className="flex flex-col md:flex-row justify-between items-center py-3 px-4 border-b cursor-pointer hover:bg-gray-100" onClick={() => setSelectedNote(note.id)}>
                            <div className="w-full md:w-auto font-semibold md:font-normal"><p>{htmlToPlain(note?.text!!).substring(0,35)}</p></div>
                            <div className="flex justify-between md:justify-end w-full md:w-auto items-center">
                                <div className="flex">
                                { !note.appUserVisibility && <FontAwesomeIcon style={{ marginRight: "1rem"}} size="sm" icon={faEyeSlash}/>}
                                <div className="mr-3">{note?.intervalFrom ? (note?.intervalFrom?.split('T')[0].split('-').reverse().join('.') + " - " + (note.intervalTo ? note?.intervalTo?.split('T')[0].split('-').reverse().join('.') : t("end"))):""}</div>
                                <div style={{color: getColor(note) }} className="mr-3">{note?.notePeriods[note!!.notePeriods.length-1] ? note?.notePeriods[note!!.notePeriods.length-1].end.split('T')[0].split('-').reverse().join('.') : ""}</div>
                                </div>
                                <FontAwesomeIcon className="cursor-pointer" size="sm" icon={faTrash} onClick={(e) => {e.stopPropagation(); toggleRemovalDialog(true); setNoteId(note.id)}}/>
                            </div>
                        </div>

                    ))
                }
                {
                    selectedNote === -1 ? (
                        <NotesModal isOpen={selectedNote === -1} setSelectedNote={(i) => setSelectedNote(i)}/>
                    ) : selectedNote > -1 ? (
                        <NotesModal isOpen={selectedNote > -1} note={notes.find(n => n.id === selectedNote)} setSelectedNote={(i) => setSelectedNote(i)}/>
                    ) : ""
                }
            </div>
          </div>
        </div>
    )
}

export default Notes;