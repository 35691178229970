import {combineReducers, createSlice, PayloadAction} from '@reduxjs/toolkit'
import AdvisorService from '../services/AdvisorService'
import ClientService from '../services/ClientService'
import { AppDispatch, AppThunk } from '../store'
import { DashboardStatistics } from '../types/DashboardStatistics'
import UserStatistics from '../types/Statistics'
import graphSlice, { TempStatsDataType } from './graphSlice'

const initialState = {
    isLoading: false,
    error: {message: "An error occured"},
    clientId: -1,
    selectedClientStatistics: {} as UserStatistics,
    dashboardStatistics: {} as DashboardStatistics
}

const statisticsSlice = createSlice({
    name: "catStatistics",
    initialState,
    reducers: {
        receiveClientStatistics(state, action: PayloadAction<UserStatistics>) {
            var newState = {...state, selectedClientStatistics: action.payload}
            return newState
        },
        receiveDashboardStatistics(state, action: PayloadAction<DashboardStatistics>) {
            var newState = {...state, dashboardStatistics: action.payload}
            return newState
        },
        receiveClientId(state, action: PayloadAction<number>) {
            var newState = {...state, clientId: action.payload}
            return newState
        },
        setLoading(state, action: PayloadAction<boolean>) {
            var newState = {...state, isLoading: action.payload}
            return newState
        }
    } 
})

export const loadClientStatistics = (clientId: number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(statisticsSlice.actions.setLoading(true))
    var clientStats = await ClientService.getClientStatistics(clientId)
    dispatch(statisticsSlice.actions.receiveClientStatistics(clientStats))
    dispatch(statisticsSlice.actions.receiveClientId(clientId))
    dispatch(statisticsSlice.actions.setLoading(false))
}

export const loadCustomClientStatistics = (clientId: number, startDate: Date, endDate: Date): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(statisticsSlice.actions.setLoading(true))
    var clientStats = await AdvisorService.getCustomClientStatistics(clientId, startDate, endDate)
    var userStats = {
        monthly: [clientStats]
    } as UserStatistics
    dispatch(statisticsSlice.actions.receiveClientStatistics(userStats))
    dispatch(statisticsSlice.actions.receiveClientId(clientId))
    dispatch(statisticsSlice.actions.setLoading(false))
}

export const setClientStatistics = (clientId: number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(statisticsSlice.actions.setLoading(true))
    var clientStats = await ClientService.getClientStatistics(clientId)
    dispatch(statisticsSlice.actions.receiveClientStatistics(clientStats))
    dispatch(statisticsSlice.actions.receiveClientId(clientId))
    dispatch(statisticsSlice.actions.setLoading(false))
}

export const loadDashboardStatistics = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(statisticsSlice.actions.setLoading(true))
    try {
        var stats = await AdvisorService.getStatistics()
        dispatch(statisticsSlice.actions.receiveDashboardStatistics(stats))
    } catch(e: any) {
        console.log(e)
    }
    dispatch(statisticsSlice.actions.setLoading(false))
}


export default combineReducers({
    stats: statisticsSlice.reducer,
    statsConfig: graphSlice
})