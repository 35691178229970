import React from "react";
import cx from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ButtonProps = {
  value: string;
  onClick?: () => void;
  className?: string;
  bgColor?: string;
  hoverColor?: string;
  textColor?: string;
  focusColor?: string;
  menuIcon?: boolean;
  shadow?: boolean;
  icon?: IconProp
};

const Button = ({
  value,
  onClick,
  className,
  menuIcon,
  icon,
  shadow = true,
  bgColor = "bg-gray-400",
  hoverColor = "bg-gray-500",
  textColor = "text-white",
  focusColor = "ring-gray-300",
}: ButtonProps & React.HTMLProps<HTMLButtonElement>) => {
  return (
    <button
      className={cx(
        `${menuIcon ? "py-1 px-2 " : "py-3 px-4"} ${bgColor} ${textColor} font-semibold rounded-lg ${shadow ? "shadow-md" : ""} hover:${hoverColor} focus:outline-none focus:ring-2 focus:ring-opacity-75 focus:${focusColor} `,
        className
      )}
      onClick={onClick}
    >
      <div className={"flex justify-center"}>
      {value}
      {icon && (
        <div className="w-4 ml-2">
          <FontAwesomeIcon icon={icon}/>
        </div>
      )}
      </div>
    </button>
  );
};

export default Button;
