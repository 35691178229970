import {
  faBell,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { RootState } from "../reducers/rootReducer";
import { logout } from "../slices/advisorSlice";
import { createBrowserHistory } from "history"

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const user = useSelector((state: RootState) => state.advisor);
  const history = createBrowserHistory()

  const dispatch = useDispatch();

  const handleLogout = () => {
    if (localStorage.getItem("token")) {
      dispatch(logout());
    }
  };
  return (
    <nav className="bg-primary">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to={`/`}>
                {" "}
                <img
                  className="h-8"
                  src={`/logo-slsp.svg`}
                  alt="slsp logo"
                />{" "}
              </Link>
            </div>
            <div className={`${!user.isAuth? "md:hidden" : ""} hidden md:block`}>
              <div className="ml-10 flex items-baseline space-x-4">
                <NavLink
                  to={`/`}
                  className={({isActive}) => `text-gray-300 hover:bg-primary-light hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isActive ? " bg-primary-focus text-white hover:bg-gray-900 " : ""}`}
                >
                  {" "}
                  Dashboard{" "}
                </NavLink>
                <NavLink
                  className={({isActive}) => `text-gray-300 hover:bg-primary-light hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isActive ? " bg-primary-focus text-white hover:bg-gray-900 " : ""}`}
                  to={`/clients`}
                >
                  {" "}
                  Clients{" "}
                </NavLink>
              </div>
            </div>
          </div>
          <div className={`${!user.isAuth? "md:hidden" : ""} hidden md:block `}>
            <div className="ml-4 flex items-center md:ml-6">
              <button
                type="button"
                className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span className="sr-only">View notifications</span>
                <div className="h-6 w-6">
                  <FontAwesomeIcon icon={faBell} />
                </div>
              </button>

              <div className="ml-3 relative">
                <div>
                  <button
                    type="button"
                    className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    id="user-menu-button"
                    aria-expanded="false"
                    onClick={() => setToggle(!toggle)}
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open user menu</span>
                    <div className="h-8 w-8 text-xl text-gray-400 bg-white rounded-full">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </button>
                </div>

                <div
                  className={`origin-top-right absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${
                    !toggle ? "hidden" : ""
                  }`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex={-1}
                >
                  <NavLink
                    to="/profile"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={() => {
                      setToggle(false);
                    }}
                    id="user-menu-item-0"
                  >
                    Your Profile
                  </NavLink>

                  <NavLink
                    to="/settings"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={() => {
                      setToggle(false);
                    }}
                    id="user-menu-item-1"
                  >
                    Settings
                  </NavLink>

                  <NavLink
                    to="/"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={() => {
                      handleLogout();
                      setToggle(false);
                    }}
                    id="user-menu-item-2"
                  >
                    Sign out
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              type="button"
              className={`${!user.isAuth? "hidden" : ""} bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setIsMobileOpen(!isMobileOpen)}
            >
              <span className="sr-only">Open main menu</span>

              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div
        className={`md:hidden ${!isMobileOpen || !user.isAuth? "hidden" : ""}`}
        id="mobile-menu"
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <NavLink
            className={({isActive}) => `text-gray-300 hover:bg-primary-light hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isActive ? "bg-primary-focus text-white hover:bg-gray-900" : ""}`}
            to={`/`}
            onClick={(e) => {setIsMobileOpen(false); history.push("/")}}
          >
            {" "}
            Dashboard{" "}
          </NavLink>
          <NavLink
            className={({isActive}) => `text-gray-300 hover:bg-primary-light hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isActive ? "bg-primary-focus text-white hover:bg-gray-900" : ""}`}
            to={`/clients`}
            onClick={() => {setIsMobileOpen(false); history.push("/clients")}}
          >
            {" "}
            Clients{" "}
          </NavLink>
        </div>
        <div className="pt-4 pb-3 border-t border-gray-700">
          <div className="flex items-center px-5">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 text-2xl flex justify-center items-center text-gray-400 bg-gray-800 rounded-full">
                <FontAwesomeIcon icon={faUser} />
              </div>
            </div>
            <div className="ml-3">
              <div className="text-base font-medium leading-none text-white">
                {user.currentUser?.fullName}
              </div>
              <div className="text-sm font-medium leading-none text-gray-400">
              {user.currentUser?.email}
              </div>
            </div>
            <button
              type="button"
              className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              <span className="sr-only">View notifications</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </button>
          </div>
          <div className="mt-3 px-2 space-y-1">

            <NavLink
                    to="/profile"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={() => {
                      setToggle(false);
                      setIsMobileOpen(false);
                      history.push("/profile")
                    }}
                    id="user-menu-item-0"
                  >
                    Your Profile
                </NavLink>

                <NavLink
                    to="/settings"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={() => {
                      setToggle(false);
                      setIsMobileOpen(false);
                      history.push("/settings")
                    }}
                    id="user-menu-item-1"
                  >
                    Settings
                  </NavLink>

                  <NavLink
                    to="/"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={() => {
                      handleLogout();
                      setToggle(false);
                      setIsMobileOpen(false);
                    }}
                    id="user-menu-item-2"
                  >
                    Sign out
                  </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
