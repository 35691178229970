import { faArrowCircleLeft, faCheckCircle, faFolderPlus, faThLarge, faThList, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect} from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import File from './File';
import DocumentData from '../../types/DocumentData';
import DocumentModal from './DocumentModal'
import { useCallback } from 'react';
import { useRef } from 'react';
import useDynamicRefs from 'use-dynamic-refs';
import { createNewFolder, deleteFile, editFile, findCurrFolder, loadRootNode, reload, setCurrentNode } from '../../slices/documentSlice';
import DocumentService from '../../services/DocumentService';
import Dialog from '../utilities/Dialog';
import AnchorMenu from '../../styled_components/AnchorMenu';
import MasterContainerHeader from '../../atoms/MasterContainerHeader';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Documents = ({className}: {className? : string}) => {

  const [folderName, setFolderName] = useState("")
  const [newDocModal, setNewDocModal] = useState(false)
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [showAnchorMenu, setShowAnchorMenu] = useState(false)
  const [showSortAnchorMenu, setShowSortAnchorMenu] = useState(false)
  const [showFileAnchorMenu, setShowFileAnchorMenu] = useState(false)
  const documentRef = useRef(document.createElement('div'))
  const [newFolder, setNewFolder] = useState(false)
  const [getRef, setRef] =  useDynamicRefs()
  const [selectedDocument, setSelectedDocument] = useState(null as DocumentData | null)
  const [newDocModalRef, setNewModalRef] = useState({} as React.RefObject<HTMLDivElement>)
  const [rcDoc, setRcDoc] = useState(null as DocumentData | null)
  const [listView, setListView] = useState(true)
  const [sortBy, setSortBy] = useState("default")
  const [isInShared, setIsInShared] = useState(false)
  const [toBeRenamed, setToBeRenamed] = useState<DocumentData | null>(null)
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false)
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false)

  const profile = useSelector(
    (state: RootState) => state.clients.selectedClient
  )

  const rootFolder = useSelector(
    (state: RootState) => state.documents.rootNode
  )

  const error = useSelector(
    (state: RootState) => state.documents.error
  )

  const currFolder = useSelector(
    (state: RootState) => state.documents.currentNode
  )

  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleContextMenu = useCallback((event) => {
    if (showAnchorMenu || showFileAnchorMenu) {
      setShowAnchorMenu(false) 
    }
    var isFile = false
    currFolder.children!!.forEach((f, i) => {
      var ref = getRef(f.id + "") as any
      if (ref && ref.current && ref.current.contains(event.target)) {
        isFile = true
        event.preventDefault()
        setShowAnchorMenu(false)
        setShowFileAnchorMenu(true)
        setRcDoc(f)
        setAnchorPoint({ x: event.pageX, y: event.pageY })
        return
      }
    })
    
    if (!isFile && documentRef.current && documentRef.current!!.contains(event.target)) {
      event.preventDefault()
      setShowAnchorMenu(true)
      setShowFileAnchorMenu(false)
      setAnchorPoint({ x: event.pageX, y: event.pageY })
    }
  }, [setAnchorPoint, setShowAnchorMenu, getRef, currFolder, showAnchorMenu, showFileAnchorMenu])

  const share = () => {
    if (document !== undefined) {
      dispatch( editFile(profile.id, currFolder, {
        ...rcDoc!!,
        visibleClient: !rcDoc?.visibleClient
      }))
    }
  }

  useEffect(() => {

    function handleClick (e: MouseEvent) {
      var current = newDocModalRef.current as any
      if (current) {
        if (current.contains(e.target)) {
          return 
        } else {
          setNewDocModal(false)
        }
      }
    }

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    }
  }, [newDocModalRef]);

  const handleClick = useCallback(() => {
    if (showAnchorMenu || showFileAnchorMenu) {
      setShowAnchorMenu(false) 
      setShowFileAnchorMenu(false)
      setShowSortAnchorMenu(false)
    }
  }, [showAnchorMenu, showFileAnchorMenu]);

  useEffect(() => {
    if (profile.id > 0) {
      dispatch(loadRootNode(profile.id))
    }
  }, [profile, dispatch])

  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu)
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu)
  }});

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  const makeNewFolder = () => {
    dispatch(createNewFolder(profile.id, currFolder, {
      parentId: currFolder.id,
      name: folderName,
      note: "",
      visibleAdvisor: true,
      visibleClient: false
    } as DocumentData))
  }

  const removeFile = () => {
    setToggleConfirmationModal(true)
  }

  const renameFolder = () => {
    const renamedFolder = {...rcDoc!!}
    renamedFolder.name = folderName
    dispatch(editFile(
     profile.id, currFolder, renamedFolder, null
    ))
  }

  const getPath = () => {
    if (currFolder === null) return <></>
    var folders: DocumentData[] = []
    var cn: DocumentData = currFolder

    while (cn !== rootFolder || cn) {
      folders.push(cn)
      if (cn.parentId) {
        cn = findCurrFolder(rootFolder, cn.parentId)!!
      } else {
        break
      }
    }

    return (<div>{folders.reverse().map((f, i) => (
      <button 
        className="border-0 m-0 w-min p-0"
        onClick={() => {
          dispatch(setCurrentNode(f))
          if (!folders.find(folder => folder.name === "shared")) {
            setIsInShared(false)
          }
        }}
      >{f.name + (i !== 0 ? "/" : "")}</button>
    ))}</div>)

   }

  const getSortedFileList = () : DocumentData[] => {
    var files: DocumentData[] = []
    var copy = [...currFolder.children!!]
    if (sortBy === "default") {
      files = copy.sort((a: DocumentData, b: DocumentData) => a.folder ? -1 : 1)
      files = files.sort((a: DocumentData, b: DocumentData) => a.name === "shared" ? -1 : 1)
    } else if (sortBy === "name") {
      files = copy.sort((a: DocumentData, b: DocumentData) => a.name < b.name ? -1 : 1)
    } else if (sortBy === "modified-date") {
      files = copy.sort((a: DocumentData, b: DocumentData) => a.modifiedAt!! > b.modifiedAt!! ? -1 : 1)
    } else if (sortBy === "created-date") {
      files = copy.sort((a: DocumentData, b: DocumentData) => a.createdAt!! > b.createdAt!! ? -1 : 1)
    }
    return files
  } 

  return (
    <div className={"shadow-md w-full rounded-xl bg-white min-h-screen md:min-h-full md:h-98 " + className}> 
      {
        <Dialog isOpen={toggleConfirmationModal} type="confirmation" content="Do you wish to delete this document?" onConfirm={() => {
          if (rcDoc?.id) {
            dispatch(deleteFile(profile.id, rcDoc.id, currFolder))
            setToggleConfirmationModal(false)
          }
        }} onCancel={() => {
          setToggleConfirmationModal(false)
        }} color={"red"}/>
      }
      <MasterContainerHeader header="Documents" menuIcons={
        <div className="flex text-sm text-primary-dark">
              { !isInShared && <>
              <FontAwesomeIcon 
                icon={faUpload} 
                className="cursor-pointer mr-2"
                onClick={() => setIsDocumentModalOpen(!isDocumentModalOpen) }
              />
              <FontAwesomeIcon 
                icon={faFolderPlus} 
                className="cursor-pointer mr-2"
                onClick={() => setNewFolder(true) }
              /></>
              }
              <FontAwesomeIcon 
                icon={listView ? faThList : faThLarge} 
                className="cursor-pointer"
                onClick={() => setListView(!listView) }
              />
            </div>
      } subHeaderContent={
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center text-primary-dark text-sm">
              <FontAwesomeIcon 
                icon={faArrowCircleLeft} 
                className="mr-2 cursor-pointer"
                onClick={() => {
                  if (currFolder.parentId) {
                    if (currFolder.name === "shared") {
                      setIsInShared(false)
                    }
                    dispatch(setCurrentNode(findCurrFolder(rootFolder, currFolder.parentId)!!))
                  }
                } }
              />
              {getPath()}
            </div>
            
          </div>
      }>
        
      </MasterContainerHeader>
      {error.message.length > 0 ? <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>{error.message}</div> :
      <div ref={documentRef} style={{margin: listView ? "0" : "1rem", width: "100%", height: "100%", display: "flex", flexDirection: listView ? "column" : "row", flexWrap: "wrap", alignContent: "flex-start"}}>
        { newFolder &&
          <File 
            id={1} 
            isNewFile={true}
            isList={listView}
            isFirst
            fileName={
            <div>
              <input style={{width: "75%", justifySelf: "flex-start", border: "1px solid #979797", borderRadius: "3px", height: "1.2em"}} value={folderName} onChange={(e) => setFolderName(e.target.value)}/>
              <FontAwesomeIcon 
                icon={faCheckCircle} 
                style={{marginLeft: ".75rem", color: "#979797"}}
                onClick={() => {makeNewFolder(); setNewFolder(false); dispatch(reload(profile.id, currFolder)); setFolderName("")}}  
              />
            </div>}
          />
        }
        {
          getSortedFileList().map((f, index) => (
            <div key={index} ref={setRef(f.id + "") as React.RefObject<HTMLDivElement>} className={listView ? "w-full":""}>
              { toBeRenamed !== null && toBeRenamed?.id === f.id && toBeRenamed.name !== "shared" ?
              <File 
              id={f.id!!} 
              isList={listView}
              document={rcDoc!!}
              fileName={
                <div>
                  <input style={{width: "75%", justifySelf: "flex-start", border: "1px solid #979797", borderRadius: "3px", height: "1.2em"}} value={folderName} onChange={(e) => setFolderName(e.target.value)}/>
                  <FontAwesomeIcon 
                    icon={faCheckCircle} 
                    style={{marginLeft: ".75rem", color: "#979797"}}
                    onClick={() => {renameFolder(); setToBeRenamed(null); dispatch(reload(profile.id, currFolder)); setFolderName("")}}  
                  />
                </div>}
            />:
              <File 
                key={index}
                id={f.id!!} 
                fileName={f.name} document={f}
                isList={listView}
                //isFirst={!newFolder && index === 0}
                onClick={
                  f.folder ? () => {
                    dispatch(setCurrentNode(f))
                    if (f.name === "shared") {
                      setIsInShared(true)
                    }
                  }: () => {
                    setIsDocumentModalOpen(true)
                    setNewDocModal(true)
                    setSelectedDocument(f)
                  }
                }
              /> 
              }
            </div>
          ))
        }
      </div>
      }
      {
        isDocumentModalOpen ? ( selectedDocument ? 
          <DocumentModal isInShared={isInShared} isOpen={isDocumentModalOpen} documentReload={() => dispatch(reload(profile.id, currFolder))} setRef={setNewModalRef} document={selectedDocument} parentId={currFolder.id} onClose={() => {setSelectedDocument(null);setIsDocumentModalOpen(false)}}/>
          :
          <DocumentModal isInShared={isInShared} isOpen={isDocumentModalOpen} documentReload={() =>  dispatch(reload(profile.id, currFolder))} setRef={setNewModalRef} parentId={currFolder.id} onClose={() => setIsDocumentModalOpen(false)}/>
          ): <></>
      }
      {showFileAnchorMenu ? (
        <AnchorMenu
          style={{
            top: anchorPoint.y,
            left: anchorPoint.x
          }}
        >
          <li onClick={
            rcDoc?.folder ? () =>dispatch(setCurrentNode(rcDoc)): () => {
              setIsDocumentModalOpen(true)
              setSelectedDocument(rcDoc)
            }
          }>Open</li>
          { !isInShared && rcDoc?.name !== "shared" &&
            <>
              <li onClick={
                () => removeFile()
              }>{t("delete")}</li>
              <li onClick={
                () => { setToBeRenamed(rcDoc); setFolderName(rcDoc?.name!!)}
              }>{t("rename")}</li>
            </>
          }
          <li onClick={
            () => DocumentService.retrieveZip(profile.id, rcDoc?.id!!)
          }>{t("download_zip")}</li>
          { !rcDoc?.folder &&
          <li onClick={
            () => {
              share()
            }
          }>{rcDoc?.visibleClient ? "Unshare" : "Share"}</li>}
        </AnchorMenu>
      ) : (
        <> </>
      )}
      {showAnchorMenu ? (
        <AnchorMenu
          style={{
            top: anchorPoint.y,
            left: anchorPoint.x
          }}
        >
          { !isInShared && <>
              <li onMouseEnter={() => setShowSortAnchorMenu(false)} onClick={() => setNewFolder(true)}>{t("new_folder")}</li>
              <li onMouseEnter={() => setShowSortAnchorMenu(false)} onClick={() => setIsDocumentModalOpen(!isDocumentModalOpen)}>{t("new_document")}</li>
            </>
          }
          <li onMouseEnter={() => setShowSortAnchorMenu(true)}>
          {t("sort_by")}
            { showSortAnchorMenu && 
              <ul onMouseLeave={() => setShowSortAnchorMenu(false)} style={{
                position: "absolute",
                top: "4rem",
                left: 150
              }}>
                <li onClick={() => setSortBy("name")}>{t("name")}</li>
                <li onClick={() => setSortBy("created-date")}>{t("created_at")}</li>
                <li onClick={() => setSortBy("modified-date")}>{t("modified_at")}</li>
              </ul>
            }
          </li>
        </AnchorMenu>
      ) : (
        <> </>
      )}

    </div>
  )
}



export default Documents;