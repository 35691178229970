import styled from 'styled-components'

const StyledModal = styled.div`

    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;

    .modal-main {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 45rem;
        max-width: 100%;
        height: 45rem;
        max-height: 100%;
        z-index: 4;
        background-color: white;
        border-radius: 6px 6px 0 0;

        @media only screen and (max-width: 768px) {
            height: calc(100vh);
            overflow-y: auto;
        }
    }
`

const ModalHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 4rem;
    min-height: 64px;
    margin: 0;
    border-radius: 6px 6px 0 0;
    background-color: #bce4fa;
    text-align: left;

    h1 {
        justify-self: flex-start;
        font-size: 1.15rem;
        font-weight: bold;
        margin: auto 0 auto 1rem;
        color: #00497b;
    }
 
    .notes-header {
        margin-left: 5%
    }

    .close-button {
        justify-self: flex-end;
        align-self: center;
        margin-left: auto;
        margin-right: 1rem;
        cursor: pointer;
    }
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    height: calc(100% - 5rem);
    overflow-y: scroll;

    .modal-row {
        display: flex;
        width: calc(100% - 2rem);
        justify-content: space-between;
        padding: 1rem 1rem 0 1rem;

        .arrow {
            transform: rotate(-90deg);
            margin-right: .5vw;
        }

        .toggled {
            transform: rotate(0)
        }
    }

    .modal-info-col {
        background-color: #f3f3f3;
        padding: 1rem;
        margin: 1rem;
        width: 17rem;
        border-radius: 12px;

        @media only screen and (max-width: 768px) {
            width: 25vw;
            margin: .5rem;
            height: 15vh;
            padding: 0.5rem;
        }

        .var {
            font-size: .75em;
            margin-bottom: .5rem;
        }

        .val {
            font-size: 1.5em;
            font-weight: 300;

            @media only screen and (max-width: 768px) {
                font-size: 5vw;
                font-weight: 600;
            }
        }
    }

    .notes-row {
        margin-top: 1rem;
        width: 100%;
        display: flex; 
        justify-content: space-between
    }

    .notes-col {
        align-self: flex-start;
        width: 20rem;
        display: flex; 
        justify-content: space-between
    }

    .details {
        display: block;
        width: calc(100% - 2rem);
        margin: 1rem;
        padding-bottom: 1rem;
        border-radius: 3px;
        border: 1px solid #c4c4c4;
        //overflow-y: auto;
        height: fit-content;
        //min-height: 12rem;
        //max-height: 20rem;
        border-radius: 6px;
        background-color: #f3f3f3;
        border: 0px
        
    }

    .date-picker {
        height: 1.4rem;
        width: 13rem;
    }

    #text {
        resize: vertical;
        width: 83%;
        margin-top: 2rem;
        padding-left: .75vw;
        padding-top: .75vw;
        border: 1px solid #c4c4c4;
        border-radius: 2px;
        font-size: 1.1rem;
        font-family: Open Sans, sans-serif;
    }

    #title {
        width: 83%;
        height: 2rem;
        padding-left: .75vw;
        border: 1px solid #c4c4c4;
        border-radius: 2px;
        font-size: 1.1rem;
    }

    label {
        align-self: flex-start;
        margin-left: 7.3%;
        margin-top: 1rem;
        margin-bottom: .5rem;
        font-size: 0.9em;
    }

    input[type="submit"] {
        width: 6rem;
        height: 2.5rem;
        max-width: 100%;
        border: none;
        margin-bottom: 2vh;
        margin-right: 7.4%;
        margin-top: 1rem;
        padding-left: .25vw;
        border: 1px solid #00497b;
        border-radius: 2px;
        background-color: #00497b;
        color: white;
        font-size: 1.1rem;
        align-self: flex-end;

        @media only screen and (max-width: 768px) {
            width: 92%;
            margin-left: auto;
            margin-right: auto;
            height: 2.5rem
        }

    }

    #rec-date .react-datepicker__current-month {
        display: none !important;
    }

    input[type="submit"]:active {
        background-color: #00365c
    }

    @media only screen and (max-width: 768px) {
        height: fit-content;
        max-height: 80vh

        label {
            margin-left: 4%;
        }

        #text {
            width: 90%;
        }

        #title {
            width: 90%;
        }
    }

`

const ModalTable = styled.table`
    border-collapse: collapse;
    border-radius: 6px;
    width: 100%;
    margin-top: 1rem;
    display: inline-block;
    height: 80%;
    overflow-y: auto;
    border: 2px solid #d0d3d4;


    tr {
        height: 3rem;

        :nth-of-type(even) {
            background-color: #f5f5f5;
        }
        th {
            vertical-align: middle;
            border-bottom: 1px solid #cfcfcf;
            width: 25vw;
        }
        td {
            vertical-align: middle;
            text-align: center;
        }
    }
`

export {StyledModal, ModalHeader, ModalBody, ModalTable}