import { createPopper } from "@popperjs/core";
import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import cx from "classnames";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type DropdownModalProps = {
  icon?: IconProp;
  openIcon?: IconProp;
  title?: string;
  buttonClassName?: string
};

const DropdownModal = ({
  children,
  icon,
  openIcon,
  title,
  buttonClassName
}: React.PropsWithChildren<DropdownModalProps>) => {
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const buttonNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      var current = node.current as any;
      if (current) {
        if (current.contains(e.target)) {
          return;
        } else {
          setOpen(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [node]);

  const openDropdownPopover = () => {
    createPopper(buttonNode.current!!, node.current!!, {
      placement: "bottom-start",
    });
    setOpen(true);
  };
  const closeDropdownPopover = () => {
    setOpen(false);
  };

  return (
    <div className={cx("flex flex-wrap")}>
      <div className="">
        <div className="relative inline-flex align-middle w-full">
          <div ref={buttonNode}>
            <Button
              menuIcon
              shadow={false}
              bgColor="bg-primary"
              textColor="text-white"
              hoverColor="bg-primary-dark"
              className={"mr-2 " + buttonClassName}
              value={title!}
              icon={!icon ? (open ? faChevronUp : faChevronDown) : (open ? openIcon : icon)}
              onClick={() =>
                open ? closeDropdownPopover() : openDropdownPopover()
              }
            />
          </div>
          <div
            ref={node}
            className={
              (open ? "block " : "hidden ") +
              " absolute bg-white text-base z-50 list-none text-left rounded-xl shadow-lg mt-1 top-7 right-0 min-w-max px-8 py-3"
            }
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownModal;
